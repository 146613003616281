import { Node } from "prosemirror-model";
import { Plugin, PluginKey, TextSelection } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

const dragHandlePluginKey = new PluginKey("dragHandlePlugin");

function dragAndDropHandlePlugin() {
    return new Plugin({
        key: dragHandlePluginKey,
        props: {
            decorations(state) {
                return dragHandlePluginKey.getState(state);
            },
        },
        state: {
            init(_, { doc }) {
                return DecorationSet.empty;
            },
            apply(tr, oldDecorationSet, oldState, newState) {
                if (tr.docChanged || tr.getMeta(dragHandlePluginKey)) {
                    return tr.getMeta(dragHandlePluginKey) || oldDecorationSet;
                }
                return oldDecorationSet;
            },
        },
        view(editorView) {
            const { dom } = editorView;

            const handleMouseOver = (event: MouseEvent) => {
                const target = event.target as Element;
                const blockElement = target.closest(".ProseMirror > *");
                const dragHandle = target.closest(".drag-handle");
                if (blockElement) {
                    const pos = editorView.posAtDOM(blockElement, 0);
                    const node = editorView.state.doc.nodeAt(pos - 1);
                    if (node && node.type.spec.attrs?.['id'] && node.attrs?.['id'] && dragHandle?.id != node.attrs?.['id']) {
                        const decoration = Decoration.widget(pos, createDragHandle(node.attrs?.['id']), { side: -1 });
                        const decorations = DecorationSet.create(editorView.state.doc, [decoration]);
                        const transaction = editorView.state.tr.setMeta(dragHandlePluginKey, decorations);
                        editorView.dispatch(transaction);
                    }
                }
            };

            const handleMouseOut = (event: MouseEvent) => {
                const target = event.target as Element;
                const blockElement = target.closest(".ProseMirror > *");
                const dragHandle = target.closest(".drag-handle");
                if (!dragHandle && !blockElement) {
                    const transaction = editorView.state.tr.setMeta(dragHandlePluginKey, DecorationSet.empty);
                    editorView.dispatch(transaction);
                }
            };

            const handleMouseDown = (event: MouseEvent) => {
                const target = event.target as Element;
                const dragHandle = target.closest(".drag-handle");
                if (dragHandle) {
                    const nodeId = dragHandle.id;
                    const foundNode = findNodeById(editorView.state.doc, nodeId);
                    if (foundNode.node) {
                        const pos = foundNode.pos;
                        const transaction = editorView.state.tr.setSelection(TextSelection.create(editorView.state.doc, pos ?? 0, (pos ?? 0) + foundNode.node.nodeSize - 1));
                        editorView.dispatch(transaction);
                    }
                }
            };

            dom.addEventListener("mouseover", handleMouseOver);
            dom.addEventListener("mouseout", handleMouseOut);
            dom.addEventListener("mousedown", handleMouseDown);

            return {
                destroy() {
                    dom.removeEventListener("mouseover", handleMouseOver);
                    dom.removeEventListener("mouseout", handleMouseOut);
                    dom.removeEventListener("mousedown", handleMouseDown);
                },
            };
        },
    });
}

function findNodeById(doc: Node, id: string) {
    let foundNode: { node: Node | null, pos: number | null } = { node: null, pos: null };
    doc.descendants((node, pos) => {
        if (node.attrs?.['id'] === id) {
            foundNode = { node, pos };
            return false
        }
        return true
    });
    return foundNode;
}

function createDragHandle(id: string) {
    const wrapper = document.createElement("div");
    wrapper.className = "drag-handle-wrapper";
    wrapper.style.position = "absolute";
    wrapper.style.left = "20px";
    wrapper.style.display = "inline-block";
    wrapper.style.width = "0";
    wrapper.style.height = "0";

    const handle = document.createElement("span");
    handle.id = id;
    handle.draggable = false;
    handle.className = "drag-handle";
    handle.style.cursor = "grab";
    handle.style.position = "absolute";
    handle.style.transform = "translateY(-30%)";
    handle.style.padding = '10px';
    handle.style.color = "black";
    wrapper.appendChild(handle);
    return wrapper;
}

export default dragAndDropHandlePlugin;
