import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContractInsightsService } from '../../services/contract-insights.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-translate',
  standalone: true,
  imports: [],
  templateUrl: './translate.component.html',
  styleUrl: './translate.component.scss'
})

export class TranslateComponent implements OnInit, OnDestroy {
  @Input() contractId: string = "";

  clauseResponse: any = null;
  subscription: Subscription = new Subscription();
  constructor(private contractInsightsService: ContractInsightsService,) {
  }

  ngOnInit(): void {
    this.getContractClauses(false);
  }

  getContractClauses(generateClauses: boolean) {
    if (this.contractId.length > 0) {
      this.subscription = this.contractInsightsService.generateClauses(this.contractId, generateClauses).subscribe(res => {
        this.clauseResponse = res.data;
        console.log(this.clauseResponse)
      })
    } else {
      console.error("No such contract")
    }
  }

  ngOnDestroy(): void {
    // if (this.subscription) {
    //   this.subscription.unsubscribe();
    // }
    this.clauseResponse = null;
  }

}
