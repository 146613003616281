@if (currentScreenComponent){
<div class="flex flex-row mt-4 gap-2 font-semibold">
    <p-button label="Go Back" icon="pi pi-angle-left" (click)="goBack()" styleClass="text-sm"></p-button>
</div>
}
@switch (currentScreenComponent) {
@case (CURRENT_SCREEN_WINDOW.TRANSALTE) {
<app-translate [contractId]="contractId"></app-translate>
}
@case(CURRENT_SCREEN_WINDOW.CHECKPOINTS) {
<app-check-points [contractId]="contractId"></app-check-points>
}
@case (CURRENT_SCREEN_WINDOW.SUMMARY) {
<app-summary [contractId]="contractId"></app-summary>
}

@default {
<div class="w-full flex flex-col p-1.5">
    <div class="border shadow rounded-lg mt-2 p-2">
        <div class="flex flex-row items-center">
            <div echarts [options]="chartOption" class="guage-chart mr-3"></div>
            <div>
                <h2 class="font-semibold text-base ">Risk Score</h2>
                <p class="text-sm font-medium">This document has {{riskScore}}% of risk as per the analysis</p>
            </div>
        </div>
    </div>

    <div class="rounded-lg bg-arena-orange-100 mt-3 p-4">
        <h2 class="font-semibold text-base">Tags</h2>

        <form class="p-3">
            <div class="grid grid-cols-2 gap-4">
                @for(mapping of contractTagMappings; track mapping.tagTypeId) {
                <div class="mb-1 col-span-2">
                    <div class="flex flex-row justify-between items-center">
                        <div class="flex flex-row items-center">
                            <label for="location"
                                class="block text-sm font-bold text-gray-700 mr-2 whitespace-nowrap">{{mapping.tagTypeName}}
                                -
                            </label>
                            @for(tag of mapping.tags; track tag.tagId){
                            <p-chip [label]="tag.tagName" styleClass="bg-arena-orange-300 mr-2 chip-truncate"
                                [removable]="mapping.tagTypeId == editTagTypeId"
                                (onRemove)="deleteClicked(mapping.tagTypeId, tag.tagId)" [pTooltip]="tag.tagName"
                                tooltipPosition="top"></p-chip>
                            }
                        </div>
                        <i class="pi pi-pencil cursor-pointer small-icon" (click)="editClicked(mapping.tagTypeId)"></i>
                    </div>
                    @if(mapping.tagTypeId == editTagTypeId) {
                    <div class="flex flex-row w-full justify-between items-center mt-2">
                        <p-autoComplete [suggestions]="tagSuggestions" optionLabel="name"
                            (onSelect)="onOptionSelect($event)" (completeMethod)="searchTags($event, mapping.tagTypeId)"
                            class="w-full" placeholder="Type here"></p-autoComplete>
                        <p-button label="Add" icon="pi pi-plus" styleClass="w-100 bg-white p-1 px-3 rounded-xl border-2"
                            [rounded]="true" [disabled]="!selectedTagId.length || mapping.tags?.length != 0"
                            (click)="addTagMapping(mapping.tagTypeId)"></p-button>
                    </div>
                    }
                </div>
                }
            </div>
        </form>
    </div>

    <div class="flex gap-2 cursor-pointer mt-3">
        <div class="card border relative overflow-hidden rounded-lg flex"
            (click)="openComponent(CURRENT_SCREEN_WINDOW.TRANSALTE)">
            <img class="h-[13rem]  w-[9rem] absolute bottom-0 left-[50%] translate-x-[-50%] translate-y-[50%] z-10 object-contain "
                src="images/insights-image.png" alt="image">
            <div class="p-3">
                <h2 class="text-base font-semibold ">Translate</h2>
                <p class="text-sm w-[7rem] "> Translate to simple language</p>
            </div>
        </div>

        <div class="relative w-full">
            <div class="card relative cursor-pointer" (click)="openComponent(CURRENT_SCREEN_WINDOW.CHECKPOINTS)">
                <img class="h-[13rem] w-[9rem]  absolute bottom-0 left-[50%] translate-x-[-50%] translate-y-[50%] z-10 object-contain"
                    src="images/checklists.png" alt="image">
                <div class="card border rounded-lg flex z-10">
                    <div class="p-3">
                        <div class="flex flex-row items-center">
                            <h2 class="font-semibold text-base mr-2">Checklist Items</h2>
                        </div>

                        <p class="text-sm w-[7rem] ">Your Documents Instantly</p>
                    </div>
                </div>
            </div>
            <p-badge *ngIf="checklistCount" class="absolute top-[-0.35rem] right-[-0.35rem]"
                [value]="checklistCount"></p-badge>
        </div>
    </div>

    <div class="summary-card mt-3  relative w-full overflow-hidden">
        <div class="summary-card  cursor-pointer border rounded-lg "
            (click)="openComponent(CURRENT_SCREEN_WINDOW.SUMMARY)">
            <div class="p-3">
                <h2 class="font-semibold text-base ">Summary</h2>
                <p class="text-sm w-[10rem] "> Enter a brief summary of your document</p>
                <img class="h-[13rem] w-[9rem] absolute bottom-4 right-[3%] translate-y-[50%] z-10 object-contain"
                    src="images/summary-image.png" alt="image">
            </div>
        </div>
    </div>
</div>
}
}