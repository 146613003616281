<div class="aspect-auto flex h-screen w-screen">
    <div class="left-section items-center">
        <div class="left-section-content">
            <div class="header text-4xl">
                Forgot Password ?
            </div>
            <div class="my-2 text-base mt-4">No Worries , we'll send you reset instructions</div>
            <div class="my-3">
                <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
                    <div class="p-fluid">
                        <div class="p-field">
                            <label class="font-medium" for="email">Email</label>
                            <input id="email" class="input mt-2" type="email" pInputText formControlName="email"
                                placeholder="name@yourcompany.com">
                            @if(resetForm.controls['email'].errors && resetForm.controls['email'].touched){
                            <div class="text-red-600"><small>*Email is Required</small></div>
                            }
                        </div>
                        <div class="mt-4">
                            <p-button styleClass="bg-arena-orange p-3 rounded-xl text-white" type="submit"
                                label="Reset Password" [disabled]="!resetForm.valid"></p-button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="flex items-center gap-4 justify-center w-[300px] text-slate-600  mt-5 text-justify cursor-pointer hover:text-orange-500"
                [routerLink]="'/login'">
                <i class="pi pi-arrow-left"></i>
                <span>Back to Login</span>
            </div>
        </div>
    </div>
    <div class="right-section">
        <img class="login-image" src="/images/login-page.png" alt="login-iamge">
    </div>
</div>