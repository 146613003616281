import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment.dev";
import { BaseService } from "../../@core/services/base.service";
import { IShareContract, IShareUser, IUser, SHARE_ACCESS } from "../../@core/models/model";

@Injectable({ providedIn: 'root', })
export class ShareContractService extends BaseService {
    constructor(
        protected override http: HttpClient
    ) {
        super(http);
    }

    shareContractUrl = environment.apiUrl + '/share-contract'
    authUrl = environment.apiUrl + '/authentication'

    getShareDetails(contractId: string) {
        return this.get<IShareContract>(this.shareContractUrl + '/', { contract: contractId })
    }

    getPermissionOfUser(contractId: string) {
        return this.get<{
            canView: boolean,
            canEdit: boolean,
            canComment: boolean,
            restricted: boolean,
        }>(this.shareContractUrl + '/permission', { contract: contractId })
    }

    addUserToShare(contract: string, user: Partial<IShareUser>, currentUser: IUser['fullName']) {
        return this.post<IShareContract>(this.shareContractUrl + '/user', { user, contract, currentUser })
    }

    authSharedContract(data: {
        contract: string,
        user: string
    }) {
        return this.post(this.authUrl + '/share-auth', data)
    }

    verifyOtp(data: {
        contract: string, user: string, otp: string
    }) {
        return this.post(this.authUrl + '/share-verify', data)
    }

    removeUserFromShare(contract: string, user: string) {
        return this.delete<IShareContract>(this.shareContractUrl + `/user/${contract}/${user}`)
    }

    updateShareUser(contract: string, user: IShareUser) {
        return this.put<IShareContract>(this.shareContractUrl + `/user`, {
            contract,
            user
        })
    }

    updateAnyoneAccess(contract: string, access: SHARE_ACCESS) {
        return this.put<IShareContract>(this.shareContractUrl + `/anyone-access`, {
            contract,
            access
        })
    }

}