<p-dialog [visible]="visibility" styleClass="shadow-lg border border-gray-300 p-2 rounded-xl " [modal]="true"
    [closeOnEscape]="true" [closable]="false" [style]="{'width':'600px' ,'height' : '450px'}">
    <ng-template pTemplate="header">
        <div class="flex-1 flex justify-between">
            <div class="font-medium text-md">Download Document <span class="font-bold">`{{contract.name}}`</span></div>
            <div class="flex items-center">
                <i class="pi pi-times text-md cursor-pointer" (click)="closeDialog()"></i>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="flex flex-col justify-center items-center gap-2">
            <img src="/images/download_doc.png" alt="contract" class="w-1/2 h-1/2">
            <div class="flex flex-col  gap-2">
                <span class="font-medium">Download as</span>
                <p-dropdown [options]="downloadOptions" [style]="{'width':'450px'}" styleClass="border "
                    [(ngModel)]="selectedType" optionLabel="name" placeholder="Select file type" appendTo="body" />
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="flex justify-end">
            <p-button styleClass="border px-3 py-2 rounded-lg w-full text-black" label="Cancel"
                (onClick)="closeDialog()"></p-button>
            <p-button styleClass="ml-2 bg-arena-orange px-3 py-2 rounded-lg w-full text-white" (onClick)="downloadDoc()"
                [loading]="loading" [disabled]="!selectedType" label="Download"></p-button>
        </div>
    </ng-template>
</p-dialog>