import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CommentService } from '../../../services/comment.service';
import { UserAvatarPipe } from '../../../@shared/pipes/user-avatar-pipe';
import { IContractComment } from '../../../@shared/components/contract-editor/model';

@Component({
  selector: 'app-all-comments',
  standalone: true,
  imports: [CommonModule, UserAvatarPipe],
  templateUrl: './comments.component.html',
  styleUrl: './comments.component.scss'
})
export class CommentsComponent {

  contract!: string;

  @Input() set contractId(id: string) {
    if (id) {
      this.contract = id;
      this.fetchComments();
    }
  }

  _comments: IContractComment[] = [];
  @Input() set comments(data: IContractComment[]) {
    if (data) {
      this._comments = data;
    }
  }


  constructor(private commentService: CommentService) {
  }

  fetchComments() {
    this.commentService.getAllComments({ contractId: this.contract }).subscribe((res) => {
      if (res && res.success) {
        this._comments = res.data;
      }
    })
  }
}
