<div class="comment-dialog">
    <div class="flex gap-2 items-center">
        <p-avatar [image]="currentUser | userAvatar" styleClass="header-avatar" shape="circle" size="normal"
            severity="success" />
        <h4 class="mb-0 text-sm font-semibold">{{currentUser?.firstName + ' ' + currentUser?.lastName}}</h4>
    </div>
    <p-inputGroup class="flex items-center border rounded-xl px-2">
        <input pInputText type="text" placeholder="Reply..." (keyup.enter)="onEnter($event)"
            class="flex-1 outline-none border-none rounded-xl p-2" />
        <p-inputGroupAddon class="cursor-pointer">
            <i class="pi pi-arrow-up text-arena-orange text-lg" (click)="onEnter($event)"></i>
        </p-inputGroupAddon>
    </p-inputGroup>

</div>