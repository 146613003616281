import { Component, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthenticationService } from '../../@core/services/authentication.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ToastService } from '../../@core/services/toast.service';
import { PasswordModule } from 'primeng/password';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [PasswordModule, FormsModule, CommonModule, ReactiveFormsModule, ButtonModule, RouterModule],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent {

  resetPassword!: FormGroup;
  fieldTextType1 = signal(true);
  fieldTextType2 = signal(true);
  private token!: string | null;
  private userId!: string | null;
  isValues: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService
  ) { }

  ngOnInit(): void {
    // Fetch token and UserId from route
    this.token = this.route.snapshot.queryParamMap.get("token");
    this.userId = this.route.snapshot.queryParamMap.get("user");

    if (this.token && this.userId) {
      this.authService.validateToken(this.token, this.userId).subscribe({
        next: () => {
          this.isValues = true
        },
        error: (err) => {
          this.toast.error('Invalid token or user')
        }
      });
    }

    this.resetPassword = this.fb.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }


  private validatePasswords(newPassword: string, confirmPassword: string): boolean {
    if (newPassword === confirmPassword) {
      return true;
    }
    this.toast.error('Passwords do not match')
    return false;
  }

  onSubmit(): void {
    const { newPassword, confirmPassword } = this.resetPassword.value
    if (this.validatePasswords(newPassword, confirmPassword) && this.resetPassword.valid) {
      if (this.userId && this.token) {
        this.authService.resetPassword(this.userId, this.token, confirmPassword).subscribe({
          next: () => {
            this.toast.success('You have successfully reset your password')
            this.router.navigate(['/']);
          },
          error: (err) => {
            this.toast.error('Failed to reset password')
          }
        });
      }
    }
  }


  togglefieldTextType1() {
    this.fieldTextType1.set(!this.fieldTextType1());
  }

  togglefieldTextType2() {
    this.fieldTextType2.set(!this.fieldTextType2());
  }

}
