@if(flowType == FLOW_TYPE.APPROVAL){
<p-timeline [value]="approvalConf.approvers">
    <ng-template pTemplate="content" let-approver>
        <div class="flex flex-row justify-between items-start ">
            <div class="flex flex-row justify-between  gap-2">
                <div>
                    @if(approver.type == USER_TYPE.INTERNAL){
                    <img [src]="approver.user | userAvatar" alt="User Avatar" class="w-8 h-8 rounded-full" />
                    }@else {
                    <div class="relative inline-block">
                        <img [src]="approver.user | userAvatar" alt="User Avatar" class="w-8 h-8 rounded-full" />
                        <span class="absolute top-3 left-2.5 w-6 h-6 ">
                            <img [src]="'/images/external-badge.png'" alt="User Avatar" class="rounded-full"
                                pTooltip="External user" />
                        </span>
                    </div>
                    }
                </div>
                <div class="flex flex-col justify-between items-start">
                    <div class="flex">
                        <span class="font-medium">{{approver.name}}</span>
                    </div>
                    @if(approver.status === 'APPROVED'){
                    <span class="text-gray-400 text-xs">{{approver.modifiedAt | dateFormatter }}</span>
                    }
                    @if(approver.status === 'REJECTED'){
                    <span class="text-gray-400 text-xs">{{approver.modifiedAt | dateFormatter}}</span>
                    }
                </div>
            </div>
            <div class="status">
                <app-badge [approver]="approver"></app-badge>
            </div>
        </div>
    </ng-template>
</p-timeline>
}@else {
<p-timeline [value]="signatories">
    <ng-template pTemplate="content" let-signatory let-i="index">
        <div class="flex flex-row justify-between">
            <div class="flex flex-1 items-start">
                <img [src]="signatory | userAvatar" alt="User Avatar" class="w-8 h-8 mt-1 rounded-full" />
                <div class="flex flex-col ml-4">
                    <div class="flex items-center">
                        <div class="flex flex-col">
                            <div>
                                <span class="font-medium mr-2">{{signatory.name}}</span>
                                <app-badge [approver]="signatory"></app-badge>
                            </div>
                            <span class="text-gray-400 text-xs">{{signatory.email}}</span>
                        </div>

                    </div>
                    @if(signatory.status === 'SIGNED'){
                    <span class="text-gray-400 text-xs mt-0.5 flex items-center">
                        {{signatory.updatedAt|dateFormatter}}
                    </span>
                    }
                    @if(signatory.status === 'REJECTED'){
                    <span class="text-gray-400 text-xs mt-0.5">{{signatory.updatedAt |
                        dateFormatter}}</span>
                    }
                </div>
            </div>
            <div class="items-center flex">
                @if(signatory.status === 'WAITING' && (contractStatus != CONTRACT_STATUS.SIGNATURE_IN_PROGRESS)){
                <p-button text="true" icon="pi pi-trash" severity="danger"
                    (onClick)="deleteSignatory(signatory.email)"></p-button>
                }
                @if(signatory.signedAttachment){
                <p-button icon="pi pi-download text-sm" class="border border-gray-300 py-1.5 rounded-xl"
                    (onClick)="downloadSignedDocument(signatory)" />
                }
            </div>
        </div>
    </ng-template>
</p-timeline>
}