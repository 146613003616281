<div class="bg-white p-2 reply-dialog">
    @if(commentLoading){
    <div>
        <div class="flex justify-between">
            <p-skeleton width="8rem" height="1rem"></p-skeleton>
            <p-skeleton width="1rem" height="1rem"></p-skeleton>
        </div>
        @for(item of [0,0]; track $index) {
        <div class="flex flex-row items-center gap-2 w-100 border border-gray-200 rounded-xl my-3 p-3">
            <p-skeleton shape="circle" size="3rem"></p-skeleton>
            <div class="flex flex-col gap-2">
                <p-skeleton width="14rem" height="1rem"></p-skeleton>
                <p-skeleton width="14rem" height="2rem" class="mt-2"></p-skeleton>
            </div>
        </div>
        }
    </div>
    }
    @else if(_comments && _comments.length > 0) {
    <div class="flex flex-col gap-2">
        <div class="flex justify-between items-center gap-2 mb-3">
            @if(!allCommentsScreen) {
            <button (click)="allCommentsScreen = true">
                <i class="pi pi-arrow-left text-xs"></i>
            </button>
            <div class="flex items-center gap-2">
                <button [disabled]="currentCommentIndex === 0" (click)="updateCurrentComment(currentCommentIndex - 1)">
                    <i class="pi pi-chevron-left text-xs"></i>
                </button>
                <span class="text-sm">{{currentCommentIndex + 1}}/{{_comments.length}}</span>
                <button [disabled]="currentCommentIndex === _comments.length - 1"
                    (click)="updateCurrentComment(currentCommentIndex + 1)">
                    <i class="pi pi-chevron-right text-xs"></i>
                </button>
            </div>
            }
            @else {
            <span class="font-bold text-lg">Comments</span>
            }
            <button (click)="toggleReplyDialogVisibility(false)">
                <i class="pi pi-times text-xs"></i>
            </button>
        </div>
        @if(allCommentsScreen) {
        <div class="comments-list flex flex-col gap-2">
            @for(comment of _comments; track comment._id) {
            <div class="border border-gray-200 p-2 rounded-xl user-border cursor-pointer"
                (click)="changeCurrentCommentId(comment._id)" [style.--user-color]="comment.user.colorCode">
                <div class="flex flex-row items-center gap-2">
                    <p-avatar [image]="comment.user | userAvatar" styleClass="header-avatar" shape="circle"
                        size="normal" severity="danger" />
                    <div>
                        <div class="flex items-stretch">
                            <div class="w-3/5 pr-2 overflow-hidden">
                                <p class="font-medium break-words">
                                    {{comment.user | fullName}}
                                </p>
                            </div>
                            <div class="w-2/5 flex items-center justify-end">
                                <span class="text-xs break-words">{{comment.createdAt | TimeSince}}</span>
                            </div>
                        </div>
                        <div>
                            {{comment.content}}
                        </div>
                        @if(comment.replies.length > 0) {
                        <span class="text-xs">
                            {{comment.replies.length}} {{comment.replies.length > 1 ? 'replies' : 'reply'}}
                        </span>
                        }
                    </div>
                </div>
            </div>
            }
        </div>
        }
        @else if(commentThread.length){
        <p-timeline #timeline [value]="commentThread" class="comment-timeline">
            <ng-template pTemplate="marker" let-comment>
                <div class="flex flex-row items-center w-full">
                    <p-avatar [image]="comment.user | userAvatar" styleClass="header-avatar" shape="circle"
                        size="normal" severity="danger" />

                </div>
            </ng-template>
            <ng-template pTemplate="content" let-comment>
                <div class="flex flex-col gap-2 justify-start">
                    <div class="flex items-stretch">
                        <div class="w-3/5 pr-2 overflow-hidden">
                            <p class="font-semibold text-sm username break-words">
                                {{comment.user | fullName}}
                            </p>
                        </div>
                        <div class="w-2/5 flex items-center">
                            <span class="circle flex-shrink-0"></span>
                            <p class="time-field font-medium text-sm break-words ml-1">
                                {{ comment.createdAt | TimeSince }}
                            </p>
                        </div>
                    </div>
                    <p class="text-base">{{comment.content}}</p>
                </div>
            </ng-template>
        </p-timeline>

        <!-- Reply Box -->

        <div class="flex my-4 gap-3 items-center">
            <p-avatar [image]="currentUser | userAvatar" styleClass="header-avatar" shape="circle" size="normal"
                severity="danger" />
            <p-inputGroup class="flex items-center border rounded-xl px-2">
                <input pInputText type="text" placeholder="Reply..." [(ngModel)]="value"
                    (keyup.enter)="sendMessage(value)" class="flex-1 outline-none border-none rounded-xl p-2" />
                <p-inputGroupAddon class="cursor-pointer">
                    <i class="pi pi-arrow-up text-arena-orange text-lg" (click)="sendMessage(value)"></i>
                </p-inputGroupAddon>
            </p-inputGroup>

        </div>
        }
    </div>
    }
    @else if(_comments && _comments.length === 0) {
    <div class="flex justify-center items-center h-full">
        <p class="text-sm text-gray-500 italic mr-2">No comments yet!</p>
    </div>
    }
    @else {
    <div class="flex justify-center items-center h-full">
        <p class="text-sm text-gray-500 italic mr-2">Something went wrong!</p>
    </div>
    }

</div>