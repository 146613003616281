import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { AuthenticationService } from "../services/authentication.service";
import { IUser } from "../models/model";
import { FingerprintService } from "../../contract-management/services/fingerprintJs.service";
import { Location } from "@angular/common";

@Injectable({ providedIn: 'root' })
export class DocGuard implements CanActivate {
    currentUser: IUser | null | undefined = null;
    constructor(
        private authService: AuthenticationService,
        private fingerprintService: FingerprintService,
        private router: Router,
    ) {
        this.authService.currentUser.subscribe(res => {
            this.currentUser = res
        })
    }

    async getAnonymousId(contract: string) {
        await this.fingerprintService.loadFingerprint(null, contract)
    }

    async canActivate(next: ActivatedRouteSnapshot) {
        if (!this.currentUser) {
            // Creating anonymous session 
            const user = next.queryParams['user']
            const contract = next.queryParams['documentId']
            if (user) {
                // Email authentication session
                this.router.navigate(['/email-auth'], { queryParams: { contract, user } });
                return false
            } else {
                // Anonymous authentication session
                await this.getAnonymousId(contract)
                return true
            }
        }
        return true
    }
}