import { Component, Input, OnInit } from '@angular/core';
import { ContractService } from '../../services/contracts.service';
import { AutoCompleteCompleteEvent, AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { ContractInsightsService } from '../../services/contract-insights.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { IContractInsights } from '../../models/contract-management.model';
import { DateFormatterPipe } from "../../../@shared/pipes/dateFormater.pipe";
import { ContractTagService } from '../../services/contract-tag.service';
import { IGetContractTagMappings } from '../../services/contract-tag.service';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { TranslateComponent } from '../translate/translate.component';
import { CheckPointsComponent } from '../check-points/check-points.component';
import { SummaryComponent } from '../summary/summary.component';
import { ContractChecklistService } from '../../services/contract-checklist-item.service';
import { CommonModule } from '@angular/common';
import * as echarts from 'echarts';
import { NGX_ECHARTS_CONFIG, NgxEchartsModule } from 'ngx-echarts';
import { GAUGE_COLORS, setGuageChart } from '../../utils';
import { ChipModule } from 'primeng/chip';
import { TooltipModule } from 'primeng/tooltip';

enum CURRENT_SCREEN_WINDOW {
  DEFAULT = 'DEFAULT',
  SUMMARY = 'SUMMARY',
  TRANSALTE = 'TRANSLATE',
  CHECKPOINTS = 'CHECKPOINTS',
}

@Component({
  selector: 'app-contract-insights',
  standalone: true,
  imports: [AutoCompleteModule, DropdownModule, AccordionModule, BadgeModule, ProgressSpinnerModule, DateFormatterPipe, TranslateComponent, CheckPointsComponent, SummaryComponent, ButtonModule, FormsModule, ButtonModule, CommonModule, NgxEchartsModule,
    ChipModule, TooltipModule
  ],
  providers: [
    {
      provide: NGX_ECHARTS_CONFIG,
      useValue: {
        echarts: () => import('echarts')
      }
    }
  ],
  templateUrl: './contract-insights.component.html',
  styleUrl: './contract-insights.component.scss'
})

export class ContractInsightsComponent implements OnInit {
  @Input() contractId: string = "";
  contractTypes: any[] | undefined;
  contractInsights: IContractInsights | undefined;
  summaryLoading: boolean = true;
  contractTagMappings: IGetContractTagMappings[] = []
  selectedTagId: string = ''
  tagSuggestions: { name: string, _id: string }[] = []
  editTagTypeId: string = ''
  CURRENT_SCREEN_WINDOW = CURRENT_SCREEN_WINDOW
  currentScreenComponent: string = ''
  checklistCount: number = 0 //Save for later checklists count
  riskScore: number = 0
  chartOption: echarts.EChartsOption = {}


  constructor(private contractService: ContractService, private contractInsightsService: ContractInsightsService,
    private contractTagService: ContractTagService,
    private contractChecklistService: ContractChecklistService) {
  }

  ngOnInit(): void {
    // this.getContractClauses(false)
    this.getTagMappings()
    this.getSaveForLaterChecklistsCount()
    this.getRiskScore()
  }

  getContractClauses(generateClauses: boolean) {
    if (this.contractId.length > 0) {
      this.contractInsightsService.generateClauses(this.contractId, generateClauses).subscribe(res => {
        console.log(res)
      })
    } else {
      console.error("No such contract")
    }
  }

  generateSummary(generateInsights: boolean) {
    if (this.contractId.length > 0) {
      this.summaryLoading = true;
      this.contractInsightsService.generateSummary(this.contractId, generateInsights).subscribe({
        next: (res: any) => {
          if (res.data) {
            this.contractInsights = res.data;
          }
          this.summaryLoading = false;
        },
        error: (error) => {
          this.summaryLoading = false;
        }
      });
    }
  }

  getTagMappings() {
    this.contractTagService.getContractTagMappings(this.contractId).subscribe(res => {
      if (res.success && res.data) {
        this.contractTagMappings = res.data
      }
    })
  }

  searchTags(event: AutoCompleteCompleteEvent, tagTypeId: string) {
    this.contractTagService.getTagsBasedOnSearchValue({ contract: this.contractId, tagType: tagTypeId, searchValue: event.query }).subscribe(res => {
      if (res.success && res.data) {
        this.tagSuggestions = res.data
      }
    })
  }

  onOptionSelect(event: AutoCompleteSelectEvent) {
    this.selectedTagId = event.value._id
  }

  addTagMapping(tagTypeId: string) {
    this.contractTagService.addContractTagMapping({ contract: this.contractId, tagType: tagTypeId, tag: this.selectedTagId }).subscribe(res => {
      if (res.success) {
        this.selectedTagId = ''
        this.editTagTypeId = ''
        this.getTagMappings()
      }
    })
  }

  editClicked(tagTypeId: string) {
    this.selectedTagId = ''
    this.editTagTypeId = this.editTagTypeId == tagTypeId ? '' : tagTypeId
  }

  deleteClicked(tagTypeId: string, tagId: string) {
    this.contractTagService.removeContractTagMapping({ contract: this.contractId, tagType: tagTypeId, tag: tagId }).subscribe(res => {
      if (res.success) {
        this.getTagMappings()
        this.getRiskScore()
      }
    })
  }

  goBack() {
    if (this.currentScreenComponent == CURRENT_SCREEN_WINDOW.CHECKPOINTS) {
      this.getSaveForLaterChecklistsCount()
      this.getRiskScore()
    }
    this.currentScreenComponent = '';
  }

  openComponent(currentScreen: string) {
    this.currentScreenComponent = currentScreen
  }

  getSaveForLaterChecklistsCount() {
    this.contractChecklistService.getSaveForLaterChecklistsCount(this.contractId).subscribe(res => {
      if (res.success) {
        this.checklistCount = res.data
      }
    })
  }

  getRiskScore() {
    this.contractService.getContractRiskScore(this.contractId).subscribe(res => {
      if (res.success) {
        this.riskScore = res.data
        this.chartOption = setGuageChart(this.riskScore, [GAUGE_COLORS.GREEN, GAUGE_COLORS.YELLOW, GAUGE_COLORS.RED])
      }
    })
  }

}
