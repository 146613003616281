import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'formatText'
})
export class FormatTextPipe implements PipeTransform {
    transform(value: string): string {

        let formattedText = value.replace(/\n/g, ' <br> ');

        formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

        formattedText = formattedText.replace(/\*(.*?)\*/g, '<em>$1</em>');

        // Jira ticket id
        formattedText = formattedText.replace(/\b(ARN-\d+)\b/g, '<a href="https://inncircles.atlassian.net/browse/$1" target="_blank">$1</a>');

        // formattedText = formattedText.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');

        formattedText = formattedText.replace(/\[\s*([^\]]+?)\s*\]\(\s*(https?:\/\/[^\s]+?)\s*\)/g, function (match, p1, p2) {
            return `<a href="${p2.trim()}" target="_blank">${p1.trim()}</a>`;
        });

        return formattedText;
    }
}