import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    standalone: true,
    name: 'dateFormatter'
})
export class DateFormatterPipe implements PipeTransform {

    transform(date: string | Date, format: string = "do MMM, yyyy h:mm a"): string | null {
        return date ? moment(date).format(format) : null;
    }

}