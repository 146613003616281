@if(notificationsCount >0){
<i class="pi pi-bell text-xl cursor-pointer mr-4" pBadge [value]="notificationsCount" (click)="op.show($event) "></i>
}
@else {
<i class="pi pi-bell text-xl cursor-pointer mr-4" (click)="op.show($event)"></i>
}
<p-overlayPanel #op [style]="{'width':'28rem' , 'max-height' : '50rem'}" styleClass="rounded-xl border">
    <div class="flex flex-col gap-3 w-25rem">
        <div class="flex flex-row justify-between items-center">
            <span class="font-bold block mb-2 ">Notifications</span>
            <p-button icon="pi pi-times" [text]="true" severity="secondary" (onClick)="op.hide();" />
        </div>
        <hr class="rounded-lg">
        <div class="flex flex-col gap-2 max-h-[40rem]  overflow-y-auto">
            @for (notification of notifications; track $index) {
            <div class="flex flex-row gap-4 p-4 w-full rounded-lg justify-between items-center border cursor-pointer hover:bg-orange-50"
                [ngClass]="{'bg-white': notification.meta.isRead, 'bg-orange-50': !notification.meta.isRead}"
                (click)="action(notification)">
                <div class="flex flex-row gap-4 justify-between items-center">
                    <img [src]="notification.event.triggeredBy | userAvatar" alt="Avatar"
                        class="w-8 h-8 rounded-full" />
                    <div class="flex flex-col">
                        <span class="font-bold">{{notification.event.triggeredBy | fullName}}</span>
                        <div [innerHTML]="notification.meta.message " class="text-sm"></div>
                    </div>
                </div>
                @if(!notification.meta.isRead){
                <div>
                    <span class="inline-block w-2 h-2 bg-orange-500 rounded-full"></span>
                </div>
                }

            </div>
            }

            @if(notifications.length === 0){
            <div class="flex flex-row justify-center items-center gap-4 p-4 rounded-lg">
                <p>No notifications</p>
            </div>
            }
        </div>
    </div>
    <ng-template pTemplate="footer">
        <hr class="mt-2 rounded-lg">
        <div class="flex flex-row justify-center items-center gap-4 p-3 rounded-lg">
            <p-button label="Mark all as read" styleClass="rounded-xl" severity="primary"
                [disabled]="!notificationsCount" icon="pi pi-check" (onClick)="markAllAsRead(); op.hide() " />
            <!-- <p-button label="Clear all" severity="secondary" [text]="true" /> -->
        </div>
    </ng-template>
</p-overlayPanel>