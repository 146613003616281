import { Component } from '@angular/core';

import { OverlayPanelModule } from 'primeng/overlaypanel';
import { IMailMeta, INotification, INotificationMeta, NotificationService } from '../../../contract-management/services/notification.service';
import { ButtonModule } from 'primeng/button';
import { AuthenticationService } from '../../../@core/services/authentication.service';
import { IUser } from '../../../@core/models/model';
import { UserAvatarPipe } from '../../pipes/user-avatar-pipe';
import { BadgeModule } from 'primeng/badge';
import { FullNamePipe } from '../../pipes/fullName.pipe';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [CommonModule, ButtonModule, OverlayPanelModule, UserAvatarPipe, BadgeModule, FullNamePipe],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent {

  notifications: INotification[] = []
  currentUser!: IUser;
  notificationsCount: number = 0;
  constructor(private notificationService: NotificationService,
    private authService: AuthenticationService,
    private router: Router
  ) {
    this.currentUser = this.authService.getUser() ?? {} as IUser;
  }

  ngOnInit() {
    this.fetchNotificationsCount();
    this.fetchNotifications();
  }

  markAllAsRead() {
    this.notificationService.markAllAsRead().subscribe(() => {
      this.fetchNotificationsCount();
      this.fetchNotifications();
    })
  }

  fetchNotifications() {
    this.notificationService.getNotifications().subscribe((res) => {
      this.notifications = res.data
    })
  }

  fetchNotificationsCount() {
    this.notificationService.getNotificationsCount().subscribe((res) => {
      this.notificationsCount = res.data
    })
  }

  isNotificationMeta(meta: INotificationMeta | IMailMeta): meta is INotificationMeta {
    return (meta as INotificationMeta).message !== undefined;
  }

  isMailMeta(meta: INotificationMeta | IMailMeta): meta is IMailMeta {
    return (meta as IMailMeta).subject !== undefined;
  }

  markRead(notification: INotification) {
    this.notificationService.markRead(notification._id).subscribe(() => {
      this.fetchNotificationsCount();
      this.fetchNotifications();
    })
  }

  action(notification: INotification) {
    this.markRead(notification);
    const eventData = notification.event.data as { contractId: string };
    this.router.navigate(['/edit-document'], { queryParams: { documentId: eventData.contractId } });
  }



}
