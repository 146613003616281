import { Component, Input, OnInit } from '@angular/core';
import { ContractChecklistService, IGetContractChecklist } from '../../services/contract-checklist-item.service';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { CONTRACT_CHECKLIST_STATUS } from '../../../@core/models/enums';
import { toFixedNumber } from '../../../common/utils';
import { NGX_ECHARTS_CONFIG, NgxEchartsModule } from 'ngx-echarts';
import { GAUGE_COLORS, setGuageChart } from '../../utils';

@Component({
  selector: 'app-check-points',
  standalone: true,
  imports: [CommonModule, ButtonModule, NgxEchartsModule],
  providers: [
    {
      provide: NGX_ECHARTS_CONFIG,
      useValue: {
        echarts: () => import('echarts')
      }
    }
  ],
  templateUrl: './check-points.component.html',
  styleUrl: './check-points.component.scss'
})
export class CheckPointsComponent implements OnInit {

  @Input() contractId: string = "";
  checklists: IGetContractChecklist[] = []
  contractChecklistStatus = CONTRACT_CHECKLIST_STATUS
  statusCount: Record<CONTRACT_CHECKLIST_STATUS, number> = {
    [CONTRACT_CHECKLIST_STATUS.COMPLIANCE]: 0,
    [CONTRACT_CHECKLIST_STATUS.NOT_IN_COMPLIANCE]: 0,
    [CONTRACT_CHECKLIST_STATUS.SAVE_FOR_LATER]: 0
  }
  complianceRate: number = 0
  chartOption: echarts.EChartsOption = {}

  constructor(private contractChecklistService: ContractChecklistService) {

  }

  ngOnInit(): void {
    this.getContractChecklistMappings()
  }

  getContractChecklistMappings() {
    this.contractChecklistService.getContractChecklistMappings(this.contractId).subscribe(res => {
      if (res.success && res.data) {
        this.checklists = res.data
        let totalMappingsCount = 0
        this.statusCount = {
          [CONTRACT_CHECKLIST_STATUS.COMPLIANCE]: 0,
          [CONTRACT_CHECKLIST_STATUS.NOT_IN_COMPLIANCE]: 0,
          [CONTRACT_CHECKLIST_STATUS.SAVE_FOR_LATER]: 0
        }
        for (const checklist of this.checklists) {
          if (checklist.status) {
            this.statusCount[checklist.status]++;
            totalMappingsCount++;
          }
        }
        this.complianceRate = totalMappingsCount ? toFixedNumber((this.statusCount[CONTRACT_CHECKLIST_STATUS.COMPLIANCE] / totalMappingsCount) * 100, 2) : 0
        this.chartOption = setGuageChart(this.complianceRate, [GAUGE_COLORS.RED, GAUGE_COLORS.YELLOW, GAUGE_COLORS.GREEN])
      }
    })
  }

  mappingChanged(checklist: IGetContractChecklist, status: CONTRACT_CHECKLIST_STATUS) {
    if (checklist.status == status) {
      this.contractChecklistService.deleteContractChecklistMapping({ contract: this.contractId, checklistItem: checklist._id }).subscribe(res => {
        if (res.success) {
          this.getContractChecklistMappings()
        }
      })
    }
    else {
      this.contractChecklistService.saveContractChecklistMapping({ contract: this.contractId, checklistItem: checklist._id, status }).subscribe(res => {
        if (res.success) {
          this.getContractChecklistMappings()
        }
      })
    }
  }
}
