import { Component, Input } from '@angular/core';
import { ChatMessage, CONTENT_TYPE, SENDER_TYPE } from '../../chat.types';
import { TextComponent } from '../content/text/text.component';
import { DateFormatterPipe } from '../../../../../@shared/pipes/dateFormater.pipe';

@Component({
  selector: 'app-ai-chat-bubble',
  standalone: true,
  imports: [TextComponent, DateFormatterPipe],
  templateUrl: './ai-chat-bubble.component.html',
  styleUrl: './ai-chat-bubble.component.scss'
})
export class AiChatBubbleComponent {

  @Input() Chat!: ChatMessage;

  public senderType = SENDER_TYPE;
  public contentTypeEnum = CONTENT_TYPE;

  constructor() { }

  ngOnInit(): void { }
}
