import { MarkType } from 'prosemirror-model';
import { EditorState, Plugin } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { MARKS } from "../model";
import { ToastService } from '../../../../@core/services/toast.service';

export const linkBehaviorPlugin = (toast: ToastService, permissions: {
    canView: boolean,
    canEdit: boolean,
    canComment: boolean,
    restricted: boolean
}) => {
    return new Plugin({
        view(editorView) {
            return new LinkView(editorView, toast, permissions);
        },
        appendTransaction(transactions, oldState, newState) {
            // When text is added before or after link, it must not have the link mark.
            const tr = newState.tr;
            if (transactions.some(transaction => transaction.docChanged)) {
                const { from, to } = newState.selection;
                if (from && from === to) {
                    const nodeBefore = tr.doc.resolve(from - 1).nodeBefore;
                    const markBefore = (nodeBefore?.marks || []).some(mark => mark.type.name === MARKS.LINK)
                    const nodeAfter = tr.doc.resolve(from).nodeAfter;
                    const markAfter = (nodeAfter?.marks || []).some(mark => mark.type.name === MARKS.LINK)
                    if ((markBefore || markAfter) && (from > 0 && from < oldState.tr.doc.nodeSize && from < newState.tr.doc.nodeSize)) {
                        // When the link is edited at first position or link is backspaced, it should not treat it as new character added hence returning here.
                        const oldNode = oldState.tr.doc.resolve(from).nodeBefore
                        const newNode = newState.tr.doc.resolve(from).nodeBefore
                        if (oldNode && newNode && oldNode?.text == newNode?.text)
                            return null
                    }
                    if ((!markBefore && markAfter) || (markBefore && !markAfter)) {
                        tr.removeMark(from - 1, from, newState.schema.marks[MARKS.LINK]);
                        return tr;
                    }
                    return null;
                }
            }
            return tr;
        }
    })
}

class LinkView {
    private view: EditorView
    private menu: HTMLElement | null = null
    private dialog: HTMLElement | null = null
    private prevHref: string = '';
    private presentHref: string = '';
    private stateSelection: { from: number, to: number } = { from: -1, to: -1 }
    constructor(view: EditorView, private toast: ToastService, private permissions: {
        canView: boolean,
        canEdit: boolean,
        canComment: boolean,
        restricted: boolean
    }) {
        this.view = view;
        this.stateSelection = { from: view.state.selection.from, to: view.state.selection.to };
    }

    createMenu() {
        this.menu = document.createElement('div');
        this.menu.className = 'slash-menu overlay-menu overlay';
        this.setURL(this.presentHref);
        this.menu.addEventListener('click', (event) => {
            const inputField = this.menu?.querySelector('input');
            if ((event.target as HTMLElement)?.id === 'submit-button' && !inputField?.disabled) {
                this.applyLink(this.view, 0, inputField?.value ?? '');
                if (inputField) { inputField.disabled = true; }
            }
            else if ((event.target as HTMLElement)?.id === 'edit-icon') {
                if (inputField && this.permissions.canEdit) { inputField.disabled = false; inputField.focus() }
            }
            else if ((event.target as HTMLElement)?.id === 'open-icon') {
                window.open(this.presentHref, '_blank');
            }
            else if ((event.target as HTMLElement)?.id === 'input-field' && inputField?.disabled) {
                this.toast.info('Please click edit button to edit the link.')
            }
        });
        const editorContainer = this.view.dom.closest('.editor-container');
        if (editorContainer) {
            editorContainer.appendChild(this.menu);
        } else {
            document.body.appendChild(this.menu);
        }
    }

    setURL(link: string) {
        if (!this.menu) return;
        this.menu.innerHTML = this.generateLinkView(link);
        const inputField = this.menu.querySelector('input');
        if (inputField) { inputField.disabled = true; }
    }

    generateLinkView(link: string): string {
        const editIcon = this.permissions.canEdit
            ? `<span id="edit-icon" class="material-icons pl-2 cursor-pointer">edit</span>`
            : ``
        return `
            <div class="flex items-center px-2">
                <div id="input-field"><input class="insert-link-input" type="text" value="${link}" placeholder="Enter URL" /></div>
                <span id="open-icon" class="material-icons pl-2 cursor-pointer">open_in_new</span>
                ${editIcon}
                <span id="submit-button" class="pl-2 cursor-pointer">Submit</span>
            </div>
            `;
    }

    isMarkActive(state: EditorState, type: MarkType) {
        const { from, to } = state.selection;
        const tr = state.tr;
        if (from === to) {
            const markBefore = (tr.doc.resolve(from).nodeBefore?.marks || []).some(mark => mark.type.name === MARKS.LINK)
            const markAfter = (tr.doc.resolve(from).nodeAfter?.marks || []).some(mark => mark.type.name === MARKS.LINK)
            if ((!markBefore && markAfter) || (markBefore && !markAfter)) {
                return false
            }
            return markBefore || markAfter
        }
        const markAfter = (tr.doc.resolve(from).nodeAfter?.marks || []).some(mark => mark.type.name === MARKS.LINK)
        const markBefore = (tr.doc.resolve(to).nodeBefore?.marks || []).some(mark => mark.type.name === MARKS.LINK)
        return markAfter && markBefore && state.doc.rangeHasMark(from, to, type);
    }

    update(view: EditorView) {
        if (!this.permissions.canView) return;
        const state: EditorState = view.state;
        const { from, to } = state.selection;
        // Update being called even for mouse movements, so returning if there is no change in selection.
        if (this.stateSelection.from === from && this.stateSelection.to === to) return
        if (this.menu?.style.display != 'none')
            this.stateSelection = { from: from, to: to };
        const linkMark = this.isMarkActive(state, state.schema.marks[MARKS.LINK]);
        let href: string[] = [];
        state.doc.nodesBetween(from, to, (node) => {
            if (node.marks) {
                node.marks.forEach(mark => {
                    if (mark.type === state.schema.marks[MARKS.LINK]) {
                        href.push(mark.attrs['href']);
                    }
                });
            }
        });
        if (linkMark && new Set(href).size <= 1) {
            this.presentHref = href[0] ?? ''
            this.showMenu()
        } else {
            this.hideMenu()
        }
    }

    showMenu() {
        const inputField = this.menu?.querySelector('input');
        if (this.menu && (this.prevHref != this.presentHref || inputField?.value != this.presentHref)) {
            this.prevHref = this.presentHref
            this.setURL(this.presentHref);
        }
        if (!this.menu) {
            this.createMenu();
        }
        this.updateMenuPosition();
    }

    hideMenu() {
        if (this.menu) {
            this.menu.style.display = 'none'
        }
    }

    applyLink(view: EditorView, pos: number, href: string) {
        const { state, dispatch } = view;
        const { from, to } = state.selection;
        const $from = state.doc.resolve(from);
        const $to = state.doc.resolve(to);
        const linkMark = state.schema.marks[MARKS.LINK];
        let start = from;
        let end = to;
        if ($from.nodeBefore && $from.nodeBefore.marks.some(mark => mark.type === linkMark)) {
            start = from - ($from.nodeBefore.text?.length || 0);
        }
        if ($to.nodeAfter && $to.nodeAfter.marks.some(mark => mark.type === linkMark)) {
            end = to + ($to.nodeAfter.text?.length || 0);
        }
        const link = linkMark.create({ href });
        dispatch(
            state.tr.addMark(start, end, link)
        );
        this.toast.success('Link updated succesfully.')
    }

    private updateMenuPosition() {
        requestAnimationFrame(() => {
            this.positionMenu();
        });
    }

    positionMenu() {
        if (!this.menu) return;
        const { from } = this.view.state.selection;
        const start = this.view.coordsAtPos(from);
        const editorContainer = this.view.dom.closest('.editor-container');

        if (editorContainer) {
            const editorRect = editorContainer.getBoundingClientRect();
            const menuRect = this.menu.getBoundingClientRect();
            const scrollTop = editorContainer.scrollTop;
            const scrollLeft = editorContainer.scrollLeft;
            let top = start.top - editorRect.top + 20 + scrollTop;
            let left = start.left - editorRect.left + scrollLeft;
            if (left + menuRect.width > editorRect.width + scrollLeft) {
                left = editorRect.width + scrollLeft - menuRect.width;
            }
            if (top + menuRect.height > editorRect.height + scrollTop) {
                top = start.top - editorRect.top - menuRect.height - 5 + scrollTop;
            }
            if (top < scrollTop) {
                top = start.top - editorRect.top + 20 + scrollTop;
            }
            if (left < scrollLeft) {
                left = scrollLeft;
            }
            this.menu.style.position = 'absolute';
            this.menu.style.top = `${top}px`;
            this.menu.style.left = `${left}px`;
            this.menu.style.display = 'block';
        } else {
            this.menu.style.position = 'fixed';
            this.menu.style.top = `${start.top + 20}px`;
            this.menu.style.left = `${start.left}px`;
            this.menu.style.display = 'block';
        }
    }

    destroy() {
        if (this.menu) {
            this.menu.remove();
        }
        if (this.dialog) {
            this.dialog.remove();
        }
    }

}
