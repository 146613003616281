import { Component, ElementRef, EventEmitter, Input, Output, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UserAvatarPipe } from '../../../../pipes/user-avatar-pipe';
import { AvatarModule } from 'primeng/avatar';
import { IUser } from '../../../../../@core/models/model';
import { AuthenticationService } from '../../../../../@core/services/authentication.service';
import { FormsModule } from '@angular/forms';
import { TimeSince } from '../../../../pipes/time-since.pipe'
import { CommentService } from '../../../../../services/comment.service';
import { SkeletonModule } from 'primeng/skeleton';
import { TimelineModule } from 'primeng/timeline';
import { IContractComment } from '../../model';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { Subscription } from 'rxjs';
import { FullNamePipe } from "../../../../pipes/fullName.pipe";
import { DateFormatterPipe } from "../../../../pipes/dateFormater.pipe";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-reply',
  standalone: true,
  templateUrl: './reply.component.html',
  styleUrl: './reply.component.scss',
  imports: [CommonModule, UserAvatarPipe, AvatarModule, FormsModule, TimeSince, SkeletonModule, TimelineModule, InputGroupModule, InputGroupAddonModule, FullNamePipe, DateFormatterPipe]
})

export class ReplyComponent implements OnInit, OnDestroy {

  _commentId!: string;
  _comments: IContractComment[] = [];
  @Input() set comments(data: IContractComment[]) {
    if (data) {
      this._comments = data;
      this.commentIdChanged()
    }
  }

  currentCommentIndex!: number;
  currentComment!: IContractComment | null;

  currentUser: IUser | null | undefined;
  commentThread: IContractComment['replies'] = []
  value!: string;

  commentLoading = false;
  @ViewChild('timeline') private timelineElement!: ElementRef;

  commentIdSubscription!: Subscription;
  allCommentsScreen = false;

  constructor(
    private commentService: CommentService,
    private authService: AuthenticationService,
  ) {
    this.authService.currentUser.subscribe(res => this.currentUser = res)
    this._commentId = this.commentService.commentId;
    this.commentIdChanged();
  }

  ngOnInit() {
    this.commentIdSubscription = this.commentService.commentIdObservable$.subscribe((id) => {
      this._commentId = this.commentService.commentId;
      this.allCommentsScreen = false;
      this.commentIdChanged()
    });
  }

  ngOnDestroy(): void {
    if (this.commentIdSubscription) {
      this.commentIdSubscription.unsubscribe();
    }
  }

  commentIdChanged() {
    this.commentLoading = true;
    if (this._comments.length > 0) {
      this.currentCommentIndex = this._comments.findIndex(comment => comment._id === this._commentId);
    }
    if (this.currentCommentIndex !== -1) {
      this.updateCurrentComment(this.currentCommentIndex)
    } else {
      this.allCommentsScreen = true;
      this.commentLoading = false;
    }
  }

  updateCurrentComment(index: number) {
    this.commentLoading = true;
    this.currentCommentIndex = index;
    this.currentComment = this._comments[index] || null;
    if (this.currentComment) {
      this.formatThread()
    } else {
      this.commentThread = [];
    }
    this.commentLoading = false;
  }

  formatThread() {
    if (this.currentComment) {
      this.commentThread = [{ content: this.currentComment.content, user: this.currentComment.user, createdAt: this.currentComment.createdAt }, ...this.currentComment.replies];
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    }
  }

  sendMessage(reply: string) {
    const payload: { commentId: string, content: string } = {
      commentId: this._commentId,
      content: reply.trim(),
    }
    this.commentService.replyToAComment(payload).subscribe((res) => {
      if (res.success) {
        this.comments = res.data;
        this.formatThread();
        this.value = ''
        this.commentService.triggerUpdate();
      } else {
        this.value = ''
      }
    })
  }

  private scrollToBottom(): void {
    try {
      const timelineEvents = (this.timelineElement as any)?.el?.nativeElement.querySelectorAll('.p-timeline-event');
      if (timelineEvents.length > 0) {
        const lastEvent = timelineEvents[timelineEvents.length - 1];
        lastEvent.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } catch (err) {
    }
  }

  toggleReplyDialogVisibility(event: boolean) {
    this.commentService.toggleReplyDialogVisibility(event);
  }

  changeCurrentCommentId(id: string) {
    this.commentService.setCommentId(id);
  }
}


