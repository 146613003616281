import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { MessageService } from 'primeng/api';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { JwtInterceptor } from './@core/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './@core/interceptors/error.interceptor';
import { provideToastr, ToastrService } from 'ngx-toastr';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideToastr({
      maxOpened: 1,
      countDuplicates: true,
    }),
    MessageService,

    provideHttpClient(
      withFetch(),
      withInterceptors([
        JwtInterceptor,
        ErrorInterceptor,
      ])
    )
  ]
};
