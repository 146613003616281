import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../../@core/services/base.service";
import { environment } from "../../../environments/environment.dev";

export interface IGetContractTagMappings {
    tagTypeId: string,
    tagTypeName: string,
    tags?: {
        tagId: string,
        tagName: string
    }[]
}

export interface IContractSingleTag {
    contract: string,
    tagType: string,
    tag: string
}

@Injectable({ providedIn: 'root' })
export class ContractTagService extends BaseService {

    constructor(protected override http: HttpClient) {
        super(http);
    }

    apiUrl = environment.apiUrl + '/contract-tag';

    getContractTagMappings(contractId: string) {
        return this.get<IGetContractTagMappings[]>(this.apiUrl + `/${contractId}`)
    }

    getTagsBasedOnSearchValue(data: { contract: string, tagType: string, searchValue: string }) {
        return this.get<{ _id: string, name: string }[]>(this.apiUrl + '/tags/search', data)
    }

    addContractTagMapping(data: IContractSingleTag) {
        return this.post(this.apiUrl + '/add', data)
    }

    removeContractTagMapping(data: IContractSingleTag) {
        return this.post(this.apiUrl + '/remove', data)
    }

}