import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { IUser } from '../models/model';
import { AuthenticationService } from '../services/authentication.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    currentUser!: IUser | null | undefined

    constructor(
        private router: Router,
        private authService: AuthenticationService
    ) {
        this.authService.currentUser.subscribe(res => {
            this.currentUser = res
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.currentUser?._id && !this.currentUser.anonymous) {
            return true
        } else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false
        }
    }
}
