import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseService } from '../@core/services/base.service';
import { environment } from '../../environments/environment.dev';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommentService extends BaseService {
  apiUrl = environment.apiUrl + '/comments/'
  public isHighlight: boolean = false;
  public tabActiveIndex: number = 0;

  private commentsSubject = new Subject<void>();
  // Observable that components can subscribe to
  commentsObservable$ = this.commentsSubject.asObservable();

  replyDialogVisible: boolean = false;

  private commentIdSubject = new Subject<string>();
  commentIdObservable$ = this.commentIdSubject.asObservable();
  commentId: string = '';

  constructor(
    protected override http: HttpClient,
    private router: Router
  ) {
    super(http);
  }

  // Method to trigger an update
  triggerUpdate() {
    this.commentsSubject.next();
  }

  toggleIsHighlight() {
    this.isHighlight = !this.isHighlight;
  }

  setCommentId(id: string) {
    this.commentId = id;
    this.commentIdSubject.next(id);
  }


  toggleReplyDialogVisibility(event: boolean | null) {
    if (event != null) {
      this.replyDialogVisible = event;
    } else {
      this.replyDialogVisible = !this.replyDialogVisible;
    }
  }


  getComments(id: string) {
    return this.get(this.apiUrl + 'comment', { id });
  }

  getAllComments(data: { contractId: string }) {
    return this.get<any>(this.apiUrl + 'all-comments', data);
  }

  getCommentById(id: string) {
    return this.get<any>(this.apiUrl + 'comment', { id });
  }

  addComment(data: { content: string, contract: string }) {
    return this.post<any>(this.apiUrl + 'create-comment', { commentData: data });
  }

  replyToAComment(payload: { commentId: string, content: string }) {
    return this.post<any>(this.apiUrl + 'reply-comment', payload);
  }
}
