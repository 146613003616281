import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router, RouterModule } from '@angular/router';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { UserAvatarPipe } from '../../../@shared/pipes/user-avatar-pipe';
import { IUser, USER_TYPES } from '../../../@core/models/model';
import { USER_ROLE } from '../../../@core/models/enums';
import { AuthenticationService } from '../../../@core/services/authentication.service';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { ToastService } from '../../../@core/services/toast.service';
import { NotificationsComponent } from "../../../@shared/components/notifications/notifications.component";

@Component({
  selector: 'app-top-bar',
  standalone: true,
  imports: [AvatarModule, SidebarModule, ButtonModule, BadgeModule, RouterModule, UserAvatarPipe, MenuModule, NotificationsComponent],
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.scss'
})
export class TopBarComponent {
  items: MenuItem[] | undefined;
  sidebarVisible: boolean = false;
  currentUser!: IUser | null | undefined;
  constructor(private authService: AuthenticationService, private router: Router, private toastService: ToastService,) {
    this.authService.currentUser.subscribe(res => this.currentUser = res)
  }

  ngOnInit(): void {
    this.items = [
      {
        items: [
          {
            label: 'Profile',
            icon: 'pi pi-user',
            route: '/home/profile'
          },
          {
            label: 'Log Out',
            icon: 'pi pi-sign-in',
            route: '',
          }
        ]
      }
    ];
  }
  handleClick(item: { label: string, icon: string, route: string, extraRouteArgs: NavigationExtras }) {
    if (item.label === 'Log Out') {
      this.authService.logout();
    } else {
      this.router.navigate([item.route]);
    }
  }

}
