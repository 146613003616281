@if(!checklists.length) {
<div class="flex flex-col items-center">
    <img class="my-5 h-[6rem] w-[8.75rem]" src="images/checklist-mappings-empty.png">
    <span class="text-lg font-semibold mt-5">Checklist Items</span>
    <p class="text-center text-sm text-gray-500">Checklist items will be shown here when they are added to the document
    </p>
</div>
}
@else {
<div class="flex flex-col">
    <span class="font-semibold text-lg mt-3">Checklist Items</span>
    <div class="border shadow rounded-lg p-2 my-3">
        <div class="flex flex-row items-center">
            <div echarts [options]="chartOption" class="gauge-chart mr-3"></div>
            <div>
                <h2 class="font-semibold text-base ">Compliance Rate</h2>
                <p class="text-sm font-medium">This document complies with {{complianceRate}}% of the required
                    checklists</p>
            </div>
        </div>
    </div>
    <p class="text-sm text-gray-500">Following are the brief checklist items that can be checked
        within the
        document</p>
    <div class="flex flex-row justify-between items-center mt-5">
        <div class="flex flex-row items-center">
            <i class="pi pi-list mr-2"></i>
            <span class="font-semibold">List of contents
                ({{statusCount[contractChecklistStatus.COMPLIANCE]}}/{{checklists.length}})</span>
        </div>
        <div class="flex flex-row items-center">
            <div class="status-button check-button mr-2">
                <i class="pi pi-check mr-1 small-icon"></i>{{statusCount[contractChecklistStatus.COMPLIANCE]}}
            </div>
            <div class="status-button cancel-button mr-2">
                <i class="pi pi-times mr-1 small-icon"></i>{{statusCount[contractChecklistStatus.NOT_IN_COMPLIANCE]}}
            </div>
            <div class="status-button bookmark-button">
                <i class="pi pi-bookmark mr-1 small-icon"></i>{{statusCount[contractChecklistStatus.SAVE_FOR_LATER]}}
            </div>
        </div>
    </div>

    <div class="flex flex-col mt-3">
        @for(checklist of checklists; track checklist._id) {
        <div class="flex flex-row ml-3 mb-3 justify-between">
            <span class="text-sm mr-4">{{checklist.name}}</span>
            <div class="flex flex-row items-center">
                <div class="status-button check-button mx-2 cursor-pointer"
                    [ngClass]="{'active': checklist.status == contractChecklistStatus.COMPLIANCE }"
                    (click)="mappingChanged(checklist, contractChecklistStatus.COMPLIANCE)"><i
                        class="pi pi-check small-icon"></i></div>
                <div class="status-button cancel-button mr-2 cursor-pointer"
                    [ngClass]="{'active': checklist.status == contractChecklistStatus.NOT_IN_COMPLIANCE }"
                    (click)="mappingChanged(checklist, contractChecklistStatus.NOT_IN_COMPLIANCE)"><i
                        class="pi pi-times small-icon"></i></div>
                <div class="status-button bookmark-button cursor-pointer"
                    [ngClass]="{'active': checklist.status == contractChecklistStatus.SAVE_FOR_LATER }"
                    (click)="mappingChanged(checklist, contractChecklistStatus.SAVE_FOR_LATER)"><i
                        [ngClass]="checklist.status == contractChecklistStatus.SAVE_FOR_LATER ? 'pi pi-bookmark-fill' : 'pi pi-bookmark'"
                        class="small-icon"></i>
                </div>
            </div>
        </div>
        }
    </div>

</div>
}