import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ShareContractService } from '../../services/share-contract.service';
import { ToastService } from '../../../@core/services/toast.service';
import { CommonModule, Location } from '@angular/common';
import { InputOtpModule } from 'primeng/inputotp';
import { FormsModule } from '@angular/forms';
import { FingerprintService } from '../../services/fingerprintJs.service';
import { DividerModule } from 'primeng/divider';

@Component({
  selector: 'app-email-authentication',
  standalone: true,
  imports: [ButtonModule, CommonModule, InputOtpModule, FormsModule, DividerModule],
  templateUrl: './email-authentication.component.html',
})
export class EmailAuthenticationComponent {
  sendMailLabel: string = 'Send Verification Email';
  user: string = ""
  contract: string = ""
  otp: string = ""
  otpVerified: boolean = false
  mailSent: boolean = false
  constructor(
    private route: ActivatedRoute,
    private shareContractService: ShareContractService,
    private toast: ToastService,
    private fingerprintService: FingerprintService,
    private router: Router,
    private location: Location
  ) {
    this.route.queryParams.subscribe(params => {
      this.user = params['user'];
      this.contract = params['contract'];
    })
  }

  sendVerificationEmail() {
    const data = {
      contract: this.contract,
      user: this.user
    }
    this.shareContractService.authSharedContract(data).subscribe((res) => {
      if (res.success) {
        this.toast.success('Verification Email Sent Successfully')
        this.mailSent = true
      }
    })
  }

  async getAnonymousId() {
    await this.fingerprintService.loadFingerprint(this.user, this.contract)
    // navigate to the contract
    this.location.back()
  }

  verifyOTP() {
    const data = {
      contract: this.contract,
      user: this.user,
      otp: this.otp
    }
    this.shareContractService.verifyOtp(data).subscribe((res) => {
      if (res.success) {
        this.toast.success('OTP Verified Successfully')
        this.otpVerified = true
        this.getAnonymousId()
      }
    })
  }
}
