import { Component, Input } from '@angular/core';
import { CONTRACT_USER_ROLE, IShareContract, IShareUser, IUser, SHARE_ACCESS } from '../../../../../@core/models/model';
import { ContractService } from '../../../../services/contracts.service';
import { UserService } from '../../../../services/user.service';
import { AuthenticationService } from '../../../../../@core/services/authentication.service';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { UserAvatarPipe } from '../../../../../@shared/pipes/user-avatar-pipe';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { FullNamePipe } from '../../../../../@shared/pipes/fullName.pipe';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ToastService } from '../../../../../@core/services/toast.service';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { IconFieldModule } from 'primeng/iconfield';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { validateEmail } from '../../../../../@shared/components/contract-editor/plugins/utils';
import { TooltipModule } from 'primeng/tooltip';
import { CommonModule } from '@angular/common';
import { ShareContractService } from '../../../../services/share-contract.service';
import { USER_TYPE } from '../../../../../@core/models/enums';
import { IContract } from '../../../../models/contract-management.model';


@Component({
  selector: 'app-share-doc',
  standalone: true,
  imports: [FormsModule, ButtonModule, DropdownModule, OverlayPanelModule, UserAvatarPipe, CheckboxModule, FormsModule, FullNamePipe, MultiSelectModule, DialogModule, IconFieldModule, AutoCompleteModule, TooltipModule, CommonModule],
  templateUrl: './share-doc.component.html',
  styleUrl: './share-doc.component.scss'
})
export class ShareDocComponent {

  @Input() contract!: IContract
  shareDialog: boolean = false
  sharedUsers: any[] = []
  shareDetails: Partial<IShareContract> = {
    anyone: SHARE_ACCESS.RESTRICTED
  }
  users: IUser[] = []
  selectedShareUser: { user: IUser, role: string }[] = [];
  connectedUsers$!: any
  currentUser: IUser | null | undefined
  SHARE_ACCESS = SHARE_ACCESS
  docsShareOptions: {
    label: string,
    value: SHARE_ACCESS,
    separator?: boolean
  }[] = [];
  roles: { [key: string]: string } = {
    'View': 'Viewer',
    'Edit': 'Editor',
    'Comment': 'Commenter',
    'Restricted': 'Restricted',
    'Owner': 'Owner'
  }
  shareUrl = ''

  constructor(
    private contractService: ContractService,
    private userService: UserService,
    private authService: AuthenticationService,
    private toast: ToastService,
    private shareService: ShareContractService
  ) {
    this.connectedUsers$ = this.contractService.connectedUsers$
    this.authService.currentUser.subscribe(res => {
      this.currentUser = res
    });
  }

  ngOnInit() {
    this.getDocOptions();
    this.getUsers()
    this.getShareUrl();
  }

  getDocOptions() {
    Object.values(SHARE_ACCESS).map((value) => {
      if (value === SHARE_ACCESS.OWNER) return
      this.docsShareOptions.push({ label: this.roles[value], value: value })
    })
  }

  getAnyoneLabel(shareDetails: Partial<IShareContract>) {
    let role = shareDetails?.anyone ?? SHARE_ACCESS.RESTRICTED
    return this.roles[role]
  }

  getRoleLabel(role: SHARE_ACCESS) {
    return this.roles[role]
  }

  getShareUrl() {
    this.shareUrl = `${window.location.origin}/edit-document/share?documentId=${this.contract._id}`
  }

  getUsers() {
    this.userService.getUsers().subscribe(res => {
      this.users = res.data
    })
  }

  getShareDetails() {
    this.sharedUsers = []
    this.shareService.getShareDetails(this.contract._id).subscribe(res => {
      if (res.success) {
        this.sharedUsers = res.data.users
        this.shareDetails = res.data
      }
    })
  }


  shareToUser($event: any) {
    this.selectedShareUser = []
    const users = $event.value
    users.map((user: any) => {
      this.selectedShareUser.push({ user: user, role: CONTRACT_USER_ROLE.VIEWER })
    })
  }

  sendInvite() {
    this.contractService.shareContract(this.contract._id, this.selectedShareUser).subscribe((res) => {
      if (res.data) {
        this.getShareDetails()
        this.toast.success("Sent Invite Successfully");
        this.selectedShareUser = []
      }
    })
  }

  Cancel(op: any) {
    op.hide();
  }

  openShareDialog() {
    this.getShareDetails()
    this.shareDialog = true
  }

  onCloseShareDialog() {
    this.shareDialog = false
  }

  toggleShareDialog() {
    this.shareDialog = !this.shareDialog
  }

  copyShareUrlToClipboard() {
    navigator.clipboard.writeText(this.shareUrl).then(() => {
      this.toast.success('Copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  }

  autoCompleteUsers: any[] = []
  autoCompleteValue: string = '';

  autoCompleteUsersMethod(event: any) {
    this.autoCompleteUsers = this.users.filter((user: any) => {
      const fullName = user?.fullName ?? (user.firstName + ' ' + user.lastName)
      return (fullName.toLowerCase().includes(event.query.toLowerCase()) || user.email.toLowerCase().includes(event.query.toLowerCase()))
    })
  }

  addShareUser(event: { value: IUser } | null) {
    const user = event?.value
    let shareUser: Partial<IShareUser> = {
      email: user?.email ?? this.autoCompleteValue,
      role: SHARE_ACCESS.VIEW,
    }
    this.addUserToShare(shareUser)
    this.autoCompleteValue = ''
  }

  addInternalUser(event: any) {
    const user = event.value
    const findUser = this.sharedUsers.find((approver: any) => approver.email === user.email)
    if (findUser) {
      this.toast.warn('User already added')
      this.autoCompleteValue = ''
      return
    }
    let shareUser: Partial<IShareUser> = {
      name: user.fullName,
      user: user._id,
      email: user.email,
      type: USER_TYPE.INTERNAL,
      role: SHARE_ACCESS.VIEW,
    }
    this.addUserToShare(shareUser)
    this.autoCompleteValue = ''
  }

  addExternalUser() {
    const email = this.autoCompleteValue
    let shareUser: Partial<IShareUser> = {
      name: email.split('@')[0],
      email,
      role: SHARE_ACCESS.VIEW,
      type: USER_TYPE.EXTERNAL
    }
    this.addUserToShare(shareUser)
    this.autoCompleteValue = ''
  }

  addUserToShare(shareUser: Partial<IShareUser>) {
    this.shareService.addUserToShare(this.contract._id, shareUser, this.currentUser?.fullName).subscribe(res => {
      if (res.success) {
        this.sharedUsers = res.data.users
        this.shareDetails = res.data
        this.toast.success('Member added successfully')
      } else {
        this.toast.info(res.message)
      }
    })
  }

  validApproverEmail() {
    return validateEmail(this.autoCompleteValue)
  }

  draggedApproverIdx: any;

  dragStart(index: number) {
    this.draggedApproverIdx = index;
  }

  drop(dropIndex: number) {
    if (this.draggedApproverIdx !== null && this.draggedApproverIdx !== dropIndex) {
      const draggedApprover = this.sharedUsers[this.draggedApproverIdx];
      this.sharedUsers.splice(this.draggedApproverIdx, 1);
      this.sharedUsers.splice(dropIndex, 0, draggedApprover);
      this.draggedApproverIdx = null;
    }
  }

  allowDrop(event: any) {
    event.preventDefault();
  }

  removeSharedUser(index: number) {
    this.shareService.removeUserFromShare(this.contract._id, this.sharedUsers[index]._id).subscribe(res => {
      if (res.success) {
        this.sharedUsers = res.data.users
        this.shareDetails = res.data
        this.toast.success('Member removed successfully')
      }
    })
  }

  updateSharedUser(option: any, index: number) {
    const user = this.sharedUsers[index]
    user['role'] = option.value
    this.shareService.updateShareUser(this.contract._id, user).subscribe(res => {
      if (res.success) {
        this.sharedUsers = res.data.users
        this.shareDetails = res.data
        this.toast.success('Member role updated successfully')
      }
    })
  }

  updateAnyoneAccess(anyoneAccess: SHARE_ACCESS) {
    this.shareService.updateAnyoneAccess(this.contract._id, anyoneAccess).subscribe(res => {
      if (res.success) {
        this.sharedUsers = res.data.users
        this.shareDetails = res.data
        this.toast.success('Anyone access updated successfully')
      }
    })
  }
}










