
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'TimeSince',
})
export class TimeSince implements PipeTransform {

  transform(value: Date | string | number): string {
    if (!value) return '';

    const now = new Date();
    const then = new Date(value);
    const timeDifference = Math.abs(now.getTime() - then.getTime());
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else {
      return `${days} days ago`;
    }
  }

}