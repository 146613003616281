<div class="header h-[64px] drop-shadow p-2">
    <div class="logo cursor-pointer">
        <!-- <i (onClick)="sidebarVisible = !sidebarVisible" class="pi pi-bars"></i> -->
        <img width="200px" style="margin-left: 8px;" src="images/logos/colored_logo.png" alt="Inncircles Arena">
    </div>

    <div class="actions">
        <app-notifications></app-notifications>
        <p-menu class="mt-5" #menu [model]="items" [popup]="true">
            <ng-template pTemplate="item" let-item>
                <a class="d-flex align-items-center py-2 px-3 menu-item" (click)="handleClick(item)">
                    <span [class]="item.icon"></span>
                    <span class="ml-2 options">{{ item.label }}</span>
                </a>
            </ng-template>
        </p-menu>
        <div class="user" (click)="menu.toggle($event)">
            <p-avatar [image]="currentUser | userAvatar" styleClass="header-avatar" shape="circle" size="normal"
                severity="success" />
            <i class="pi pi-angle-down arrow-right"></i>
        </div>
    </div>
</div>