<div class="bg-white">
    <div class="header bg-white mt-3">
        <div class="import-text">
            Upload document
            <!-- <p class="font-normal font-bricolage text-base ">.pdf , .docs formats accepted</p> -->
        </div>

        <p-button [text]="true" size="small" severity="secondary" icon="pi pi-times" mat-dialog-close />
    </div>
    <div class="body p-3">
        <app-file-drop [fileTypes]="allowedFileTypes" (uploadData)="onFileUploaded($event)" />
    </div>
    <div class="footer">
        <p-button styleClass="bg-arena-orange p-2 px-4 m-3 text-white" (onClick)="createContract()"
            [disabled]="!isFileAdded" [loading]="loading" label="Save" severity="help" />
    </div>
</div>