import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { ContractService } from '../../../../services/contracts.service';
import { IContract } from '../../../../models/contract-management.model';

@Component({
  selector: 'app-download-doc',
  standalone: true,
  imports: [DialogModule, IconFieldModule, AutoCompleteModule, ButtonModule, DropdownModule, FormsModule],
  templateUrl: './download-doc.component.html',
  styleUrl: './download-doc.component.scss'
})
export class DownloadDocComponent {
  @Input() visibility: boolean = false;
  @Input() contract!: IContract;
  @Output() visibilityChange = new EventEmitter<boolean>();

  downloadOptions: { name: string, value: string }[] = [
    { name: 'Pdf Document (.pdf)', value: 'pdf' },
    { name: 'Microsoft Word (.docx)', value: 'docx' },
  ];

  selectedType!: { name: string, value: string };
  loading: boolean = false;

  constructor(
    private contractService: ContractService,
  ) { }

  ngOnInit() { }

  closeDialog() {
    this.visibility = false;
    this.visibilityChange.emit(this.visibility);
  }


  downloadDocument(uint8Array: Uint8Array, fileName: string, type: string) {
    const link = document.createElement('a');
    let blob: Blob;
    switch (type) {
      case 'pdf':
        blob = new Blob([new Uint8Array(uint8Array)], {
          type: 'application/pdf'
        });
        link.href = URL.createObjectURL(blob);
        link.download = `${fileName}.pdf`;
        break
      case 'docx':
        blob = new Blob([new Uint8Array(uint8Array)], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        });
        link.href = URL.createObjectURL(blob);
        link.download = `${fileName}.docx`;
        break
      default:
        break
    }
    document.body.append(link);
    link.click();
    link.remove();
    this.loading = false
  }

  downloadDoc() {
    switch (this.selectedType.value) {
      case 'pdf':
        this.loading = true
        this.contractService.downloadContract(this.contract._id, 'pdf').subscribe(res => {
          if (res.success) {
            this.downloadDocument(res.data.data, this.contract.name, 'pdf')
          }
        })
        break
      case 'docx':
        this.loading = true
        this.contractService.downloadContract(this.contract._id, 'docx').subscribe(res => {
          if (res.success) {
            this.downloadDocument(res.data.data, this.contract.name, 'docx')
          }
        })
        break
    }
  }
} 
