import { Injectable, inject } from '@angular/core';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { AuthenticationService } from '../../@core/services/authentication.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FingerprintService {
    private fingerprintId: string | null = null;
    private authService = inject(AuthenticationService);
    constructor() {
    }

    async loadFingerprint(shareUserId: string | null, contractId: string): Promise<string> {
        if (!this.fingerprintId) {
            const fp = await FingerprintJS.load();
            const result = await fp.get();
            this.fingerprintId = result.visitorId;
            await this.authService.generateAnonymousId(this.fingerprintId, shareUserId, contractId);
        }
        const token = localStorage.getItem('token');
        return token ?? "";
    }

    getFingerprintSync(): string | null {
        return this.fingerprintId;
    }
}
