import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment.dev';
import { BaseService } from "../../@core/services/base.service";
import { IContract, IContractApprovalConf } from "../models/contract-management.model";

@Injectable({ providedIn: 'root' })
export class ApprovalConfService extends BaseService {

    constructor(
        protected override http: HttpClient
    ) {
        super(http);
    }


    apiUrl = environment.apiUrl + '/approval-conf';

    saveApprovalWorkflow(contractId: string, approvers: IContractApprovalConf['approvers']) {
        return this.post<IContract>(this.apiUrl + '/save-approval-workflow/' + contractId, { approvers })
    }


    approveContract(contractId: string) {
        return this.post<IContractApprovalConf>(this.apiUrl + '/approve-contract/' + contractId)
    }

    rejectContract(contractId: string) {
        return this.post<IContractApprovalConf>(this.apiUrl + '/reject-contract/' + contractId)
    }

    resetApprovalWorkflow(contractId: string) {
        return this.post<IContractApprovalConf>(this.apiUrl + '/reset-approval-workflow/' + contractId)
    }

    fetchContractApprovers(contractId: string) {
        return this.get<IContractApprovalConf>(this.apiUrl + '/fetch-contract-approvers/' + contractId)
    }

    sendForApproval(contractId: string) {
        return this.post<IContract>(this.apiUrl + '/send-for-approval/' + contractId)
    }

    deleteApprover(contractId: string, approverId: string) {
        return this.delete<string>(this.apiUrl + '/remove-approver/' + contractId + '/' + approverId)
    }

    skipApprovalFlow(contractId: string) {
        return this.post<IContract>(this.apiUrl + '/skip-approval/' + contractId)
    }

}