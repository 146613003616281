import { Routes } from '@angular/router';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { EditContractComponent } from './contract-management/components/edit-contract/edit-contract.component';
import { AuthGuard } from './@core/guards/auth.guard';
import { ContractListComponent } from './contract-management/components/contract-list/contract-list.component';
import { LogInComponent } from './auth/login/login.component';
import { PageNotFoundComponent } from './auth/page-not-found/page-not-found.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { OnboardOrgComponent } from './auth/sign-up/components/onboard-org/onboard-org.component';
import { DocGuard } from './@core/guards/doc.guard';
import { EmailAuthenticationComponent } from './contract-management/components/email-authentication/email-authentication.component';
import { LoggedInGuard } from './@core/guards/logged-in.guard';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';


export const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'login',
        canActivate: [LoggedInGuard],
        component: LogInComponent
    },
    {
        path: 'verify/:email',
        loadComponent: () => import('../app/auth/sign-up/components/verify/verify.component').then(m => m.VerifyComponent)
    },
    {
        path: 'signup',
        component: SignUpComponent
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent
    },
    {
        path: 'onboard/:email',
        canActivate: [LoggedInGuard],
        component: OnboardOrgComponent
    },
    {
        path: 'profile',
        loadComponent: () => import('./auth/profile/profile.component').then(m => m.ProfileComponent),
    },
    {
        path: 'onboard',
        component: OnboardOrgComponent
    },
    {
        path: 'home',
        component: AppLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('./contract-management/contract-management.routes').then(mod => mod.ContractManagementLayoutRoutes)
    },
    {
        path: 'edit-document',
        component: EditContractComponent,
        children: [
            {
                path: 'share',
                canActivate: [DocGuard],
                component: EditContractComponent
            }
        ]
    },
    {
        path: 'email-auth',
        component: EmailAuthenticationComponent
    },
    {
        path: '**',
        component: PageNotFoundComponent
    },
];
