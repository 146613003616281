@if(currentStep === 1){
<div class="aspect-auto flex min-h-screen" #step1>
    <div class="left-section">
        <div class="flex  flex-col">
            <div class="flex flex-row items-start px-32 py-6 text-sm gap-3 ">
                <img src="/images/arena_ai_logo.png" alt="logo" class="w-10 h-10">
                <div class="flex flex-col gap-2">
                    <span class="text-sm font-medium">{{currentStep}} of {{totalSteps}} steps</span>
                    <div class="relative w-full h-2 bg-blue-100 rounded-full">
                        <div class="absolute top-0 h-2 bg-orange-500 rounded-full" [ngStyle]="{ width: progress }">
                        </div>
                    </div>
                </div>
            </div>
            <div class="profile-setup">
                <div class="mb-5 text-left">
                    <h2 class="heading">Setup your profile</h2>
                    <p class="text-gray-400 mb-2">Let's setup your profile , tell us more about you</p>
                </div>
                <form [formGroup]="profileForm">
                    <div class="flex flex-row gap-2" (ngSubmit)="onSubmitProfile()">
                        <div class="profile-picture-container">
                            <div class="profile-image">
                                <img class="h-[5rem] w-[5rem] rounded-full" [src]="profileForm.value.profilePicture"
                                    alt="Profile">
                            </div>

                            <div class="upload-section">
                                <div class="title">Profile Picture</div>

                                <label for="file-upload" class="upload-btn">
                                    <input id="file-upload" type="file" (change)="onFileSelect($event)" hidden />
                                    <i class="pi pi-upload mr-2"></i> Upload Picture
                                </label>
                                <p class="description">You can setup your profile picture later in settings</p>
                            </div>
                        </div>
                    </div>

                    <div class="flex gap-4 mt-4">
                        <div class="form-field">
                            <label for="firstName">First Name</label>
                            <input id="firstName" formControlName="firstName"
                                class="input-field w-full border-gray-300 focus:border-orange-500 focus:ring-orange-500 focus:outline-none"
                                placeholder="John" />

                            @if( profileForm.controls['firstName'].errors &&
                            profileForm.controls['firstName'].touched){
                            <div class="text-red-600"><small>*First Name is Required</small></div>
                            }
                        </div>

                        <div class="form-field">
                            <label for="lastName">Last Name</label>
                            <input id="lastName" formControlName="lastName"
                                class="input-field w-full border-gray-300 focus:border-orange-500 focus:ring-orange-500 focus:outline-none"
                                placeholder="Smith" />
                            @if( profileForm.controls['lastName'].errors &&
                            profileForm.controls['lastName'].touched){
                            <div class="text-red-600"><small>*Last Name is Required</small></div>
                            }
                        </div>

                    </div>
                    <div class="flex gap-4 mt-4">
                        <div class="form-field">
                            <label for="username">Username</label>
                            <input id="username" formControlName="username" type="text"
                                class="input-field w-full border-gray-300 focus:border-orange-500 focus:ring-orange-500 focus:outline-none"
                                placeholder="Enter a username" />
                            @if( profileForm.controls['username'].errors &&
                            profileForm.controls['username'].touched){
                            <div class="text-red-600"><small>Username is Required</small></div>
                            }
                        </div>
                        <div class="form-field">
                            <label for="gender">Gender</label>
                            <div class="flex gap-3 items-center justify-items-center mt-4 ">
                                <div class="flex items-center">
                                    <p-radioButton name="gender" value="MALE" formControlName="gender" inputId="male"
                                        (ngModelChange)="avatarValidator()" />
                                    <span for="male" class="ml-2 text-sm">
                                        Male
                                    </span>
                                </div>
                                <div class="flex items-center">
                                    <p-radioButton name="gender" value="FEMALE" formControlName="gender"
                                        (ngModelChange)="avatarValidator()" inputId="female" />
                                    <span for="female" class="ml-2 text-sm">
                                        Female
                                    </span>
                                </div>
                            </div>
                            @if( profileForm.controls['gender'].errors &&
                            profileForm.controls['gender'].touched){
                            <div class="text-red-600"><small>*Gender is Required</small></div>
                            }

                        </div>
                    </div>

                    <div class="form-field">
                        <label for="lastName">New Password</label>
                        <p-password class="border-1" styleClass="w-full" formControlName="password"
                            [toggleMask]="true" />
                        @if( profileForm.controls['password'].errors &&
                        profileForm.controls['password'].touched){
                        <div class="text-red-600"><small>*New Password is Required</small></div>
                        }
                    </div>

                    <div class="form-field">
                        <label for="lastName">Confirm New Password</label>
                        <p-password class="border-1 " styleClass="w-full" formControlName="confirmPassword"
                            [toggleMask]="true" />
                        @if(!profileForm.controls['confirmPassword'].valid &&
                        profileForm.controls['confirmPassword'].touched){
                        <div class="text-red-600"><small>*Confirm New Password is Required</small></div>
                        }

                        @if(!passwordMatched){
                        <div class="text-red-600">
                            <small>*Passwords Do Not Match.</small>
                        </div>
                        }
                    </div>

                    <button type="submit" class="submit-button w-[23rem]" (click)="onSubmitProfile()">
                        Continue
                    </button>

                </form>
            </div>
        </div>
    </div>
    <div class="right-section">
        <img class="login-image" src="/images/user_info.png" alt="login-image">
    </div>
</div>
}
@if(currentStep === 2){
<div class="aspect-auto flex h-screen w-screen" #step2>
    <div class="left-section">
        <div class="flex flex-col ">
            <div class="flex flex-row items-start px-32 py-6 text-sm gap-3 ">
                <img src="/images/arena_ai_logo.png" alt="logo" class="w-10 h-10">
                <div class="flex flex-col gap-2">
                    <span class="text-sm font-medium">{{currentStep}} of {{totalSteps}} steps</span>
                    <div class="relative w-full h-2 bg-blue-100 rounded-full">
                        <div class="absolute top-0 h-2 bg-orange-500 rounded-full" [ngStyle]="{ width: progress }">
                        </div>
                    </div>
                </div>
            </div>

            <div class="question-section left-section-content ">
                <h2 class="heading">Looking Good, {{name}}</h2>
                <p class="role-description">What role are you working on? Choose one. <span class="required">*</span>
                </p>
                <div class="role-buttons">
                    @for(role of roles; track $index){
                    <button type="button" [disabled]="!role" (click)="selectRole(role)"
                        [class.selected]="role === selectedRole">
                        {{ role }}
                    </button>
                    }
                </div>

                @if(roleError){
                <p class="text-red-600">*Role is required.</p>
                }

                <p class="role-description">What is your domain expertise? Choose one. <span class="required">*</span>
                </p>
                <div class="expertise-buttons">
                    @for(expertise of domainExpertise; track $index){
                    <button type="button" (click)="selectExpertise(expertise)"
                        [class.selected]="expertise === selectedExpertise">
                        {{ expertise }}
                    </button>
                    }
                </div>
                @if(expertiseError){
                <p class="text-red-600">*Domain expertise is required.</p>
                }

                <div class="button-group">
                    <button type="submit" class="submit-button" (click)="onDomainSubmit()">Continue</button>
                    <button type="button" class="previous-button" (click)="previousStep()">Previous</button>
                </div>
            </div>
        </div>
    </div>
    <div class="right-section">
        <img class="login-image" src="/images/role_info.png" alt="login-iamge">
    </div>
</div>
}

@if(currentStep === 3){
<div class="aspect-auto flex min-h-screen" #step3>
    <div class="left-section">
        <div class="flex flex-col">
            <div class="flex flex-row items-start px-32 py-6 text-sm gap-3 ">
                <img src="/images/arena_ai_logo.png" alt="logo" class="w-10 h-10">
                <div class="flex flex-col gap-2">
                    <span class="text-sm font-medium">{{currentStep}} of {{totalSteps}} steps</span>
                    <div class="relative w-full h-2 bg-blue-100 rounded-full">
                        <div class="absolute top-0 h-2 bg-orange-500 rounded-full" [ngStyle]="{ width: progress }">
                        </div>
                    </div>
                </div>
            </div>
            <div class="profile-setup">
                <div class="mb-5 text-left">
                    <h2 class="heading">Organization Setup</h2>
                    <p class="text-gray-400">To start using Inncircles.ai you need to create organization</p>
                </div>
                <form [formGroup]="organizationForm" (ngSubmit)="onSubmitOrganization()">
                    <!-- Form Fields -->
                    <div class="form-field ">
                        <label for="companyName ">Company Name</label>
                        <input id="companyName" formControlName="companyName" (input)="onCompanyNameChange($event)"
                            class="input-field w-full border-gray-300 focus:border-orange-500 focus:ring-orange-500 focus:outline-none"
                            placeholder="Your Company" />
                        @if( organizationForm.controls['companyName'].errors &&
                        organizationForm.controls['companyName'].touched){
                        <div class="text-red-600 ml-1"><small>*Company Name is Required</small></div>
                        }
                    </div>

                    <div class="form-field">
                        <label for="workspaceurl" class="font-bold mb-2">Workspace URL</label>
                        <div class="flex">
                            <input id="workspaceurl" [value]="workspacePrefix+workspaceInputValue"
                                (input)="onUrlInput($event)"
                                class="input-field w-full  border-gray-300 focus:border-orange-500 focus:ring-orange-500 focus:outline-none rounded-md p-2"
                                placeholder="your-workspace" />
                            @if( organizationForm.controls['workspaceUrl'].errors &&
                            organizationForm.controls['workspaceUrl'].touched){
                            <div class="text-red-600 ml-1"><small>workspace name already taken</small></div>
                            }
                        </div>
                    </div>

                    <div class="flex gap-2">
                        <div class="form-field ">
                            <label for="companySize">Company Size</label>
                            <p-dropdown [options]="noOfEmployees" formControlName="companySize"
                                [style]="{'width':'10.5rem'}" styleClass=" border h-12 rounded-lg"
                                placeholder="Company Size" />
                            @if( organizationForm.controls['companySize'].errors &&
                            organizationForm.controls['companySize'].touched){
                            <div class="text-red-600 ml-1"><small>*Company Size is required</small></div>
                            }
                        </div>

                        <div class="form-field ">
                            <label for="contact">Phone</label>
                            <arn-phone-input (phoneNumberChange)="onPhoneNumberChange($event)"
                                formControlName="contact"></arn-phone-input>
                            @if( organizationForm.controls['contact'].errors &&
                            organizationForm.controls['contact'].touched){
                            <div class="text-red-600 ml-1"><small>*Valid Phone is required</small></div>
                            }
                        </div>
                    </div>

                    <div class="form-field ">
                        <label for="address ">Address</label>
                        <textarea rows="4" cols="50" id="address" formControlName="address"
                            class="p-2 rounded-lg border border-gray-300 focus:border-orange-500 focus:ring-orange-500 focus:outline-none w-full"
                            placeholder="Address"></textarea>
                        @if( organizationForm.controls['address'].errors &&
                        organizationForm.controls['address'].touched){
                        <div class="text-red-600 ml-1"><small>*Address is Required</small></div>
                        }
                    </div>

                    <div class="form-field">
                        <label for="companyEmail">Company Email</label>
                        <input type="email" formControlName="companyEmail"
                            class="input-field border-gray-300 focus:border-orange-500 focus:ring-orange-500 focus:outline-none"
                            placeholder="Info@yourcompanyemail.com" />

                        @if( organizationForm.controls['companyEmail'].errors &&
                        organizationForm.controls['companyEmail'].touched){
                        <div class="text-red-600 ml-1"><small>*Company Email is Required</small></div>
                        }
                    </div>

                    <div class="button-group mt-[-1rem]">
                        <button type="submit" (click)="onContinue()" class="submit-button">Continue</button>
                        <button type="button" class="previous-button" (click)="previousStep()">Previous</button>

                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="right-section">
        <img class="login-image object-cover" src="/images/org_setup.png" alt="login-iamge">
    </div>
</div>
}

@if(currentStep === 4){
<div class="aspect-auto flex min-h-screen" #step5>
    <div class="left-section">
        <div class="flex flex-col">
            <div class="flex flex-row items-start px-32 py-6 text-sm gap-3 ">
                <img src="/images/arena_ai_logo.png" alt="logo" class="w-10 h-10">
                <div class="flex flex-col gap-2">
                    <span class="text-sm font-medium">{{currentStep}} of {{totalSteps}} steps</span>
                    <div class="relative w-full h-2 bg-blue-100 rounded-full">
                        <div class="absolute top-0 h-2 bg-orange-500 rounded-full" [ngStyle]="{ width: progress }">
                        </div>
                    </div>
                </div>
            </div>
            <div class="left-section-content">
                <div class="mb-5 text-left">
                    <h2 class="heading">Invite your team members</h2>
                    <p class="text-gray-400"> Invite People to your workspace </p>
                </div>

                <div class="flex justify-end">
                    <p-button label="Add Member" icon="pi pi-plus" styleClass="px-4 py-2 rounded-xl" [outlined]="true"
                        severity="secondary" (click)="addMember()" [disabled]="members.length >= 6" />
                </div>
                <div>
                    <div class="flex flex-row gap-6 mb-5">
                        <p class="font-semibold w-1/2">Email</p>
                        <p class="font-semibold">Role</p>
                    </div>
                    @for (item of members; track $index) {
                    <div class="flex gap-6">
                        <div class="form-field">
                            <input type="email" [(ngModel)]="item.email"
                                class="border-gray-300  focus:border-orange-500 rounded-lg focus:ring-orange-500 focus:outline-none"
                                placeholder="somename@yourcompany.com" style="width: 18rem;" />

                        </div>
                        <p-dropdown [options]="userRoles" [(ngModel)]="item.role" appendTo="body"
                            [style]="{'width':'200px', 'border':'1px solid lightgrey'}" placeholder="Select a Role" />
                        <div class="min-w-10">

                            @if(members.length > 1) {
                            <p-button severity="danger" [outlined]="true" icon="pi pi-trash"
                                (click)="removeMember($event, $index)" />
                            }
                        </div>
                    </div>
                    }
                    <p-confirmPopup />
                </div>
                <div class="button-group items-center">
                    <button type="submit" class="submit-button " (click)="onSubmitTeam()"
                        [disabled]="members.length === 0" style="width: 17rem;">Send Over
                        Mail</button>
                    <p class="text-center text-gray-500 cursor-pointer" (click)="skip()">I'll do this later</p>
                </div>
            </div>
        </div>
    </div>
    <div class="right-section">
        <img class="login-image object-cover" src="/images/invite_team.png" alt="login-iamge">
    </div>
</div>
}