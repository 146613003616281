<div class="aspect-auto flex h-screen w-screen">
    <div class="left-section items-center">
        <div class="left-section-content">
            <div class="header text-4xl">
                Reset Password
            </div>
            <div class="my-2 text-base mt-4">
                Please choose your new password
            </div>
            <div class="my-3">
                <form [formGroup]="resetPassword" (ngSubmit)="onSubmit()">
                    <div class="p-fluid">
                        <div class="flex flex-col p-fluid gap-2">
                            <div class="input-group">
                                <label for="newPassword">Enter New Password</label>
                                <div class="input-password">
                                    <p-password formControlName="newPassword" [toggleMask]="true"
                                        promptLabel="Choose a password" weakLabel="Too simple"
                                        mediumLabel="Average complexity" strongLabel="Complex password" />
                                </div>
                            </div>
                            <div class="input-group">
                                <label for="confirmPassword">Confirm New Password</label>
                                <div class="input-password">
                                    <p-password formControlName="confirmPassword" toggleMask="true" autofocuss />
                                </div>
                            </div>
                        </div>
                        <div class="mt-4">
                            <p-button styleClass="bg-arena-orange p-3 rounded-xl text-white" type="submit"
                                label="Reset Password" [disabled]="!resetPassword"></p-button>
                        </div>

                        <div class="flex items-center gap-4 justify-center w-[300px] text-slate-600  mt-5 text-justify cursor-pointer hover:text-orange-500"
                            [routerLink]="'/login'">
                            <i class="pi pi-arrow-left"></i>
                            <span>Back to Login</span>
                        </div>
                    </div>
                </form>
            </div>
            <!-- <div class="flex items-center gap-4 justify-center w-[300px] text-slate-600  mt-5 text-justify cursor-pointer hover:text-orange-500"
                [routerLink]="'/login'">
                <i class="pi pi-arrow-left"></i>
                <span>Back to Login</span>
            </div> -->
        </div>
    </div>
    <div class="right-section">
        <img class="login-image" src="/images/login-page.png" alt="login-iamge">
    </div>
</div>