export enum FileDropTypes {
    CSV = '.csv',
    EXCEL = '.xlsx',
    VIDEO = 'video/*',
    IMAGE = 'image/*',
    PDF = ".pdf",
    DOC = ".docx",
}


export enum FileTypes {
    CSV = "CSV",
    EXCEL = "EXCEL",
    VIDEO = "VIDEO",
    IMAGE = "IMAGE",
    PDF = "PDF",
    DOC = "DOC"
}
