import { AsyncPipe, CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { BehaviorSubject, Subscription, take } from 'rxjs';
import { USER_TYPE } from '../../../@core/models/enums';
import { CONTRACT_USER_ROLE, IShareContract, IShareUser, IUser } from '../../../@core/models/model';
import { AuthenticationService } from '../../../@core/services/authentication.service';
import { ToastService } from '../../../@core/services/toast.service';
import { ApprovalWorkflowComponent, FLOW_TYPE } from "../../../@shared/components/approval-workflow/approval-workflow.component";
import { BadgeComponent } from "../../../@shared/components/badge/badge.component";
import { CONTRACT_SIGNATORY_STATUS, CONTRACT_STATUS } from '../../../@shared/components/badge/badge.enums';
import { ContractEditorComponent } from "../../../@shared/components/contract-editor/contract-editor.component";
import { IContractComment } from '../../../@shared/components/contract-editor/model';
import { validateEmail } from '../../../@shared/components/contract-editor/plugins/utils';
import { CONTRACT_APPROVAL_STATUS, UserChipsComponent } from "../../../@shared/components/user-chips/user-chips.component";
import { ScrollResetDirective } from '../../../@shared/directives/scroll-reset.directive';
import { FullNamePipe } from '../../../@shared/pipes/fullName.pipe';
import { UserAvatarPipe } from '../../../@shared/pipes/user-avatar-pipe';
import { CommentService } from '../../../services/comment.service';
import { IContract, IContractApprovalConf, IContractSignatoryConf, SIGNATORY_TYPE } from '../../models/contract-management.model';
import { ApprovalConfService } from '../../services/approval-conf.service';
import { ContractSignatoryService } from '../../services/contract-signatory.service';
import { ContractService } from '../../services/contracts.service';
import { FingerprintService } from '../../services/fingerprintJs.service';
import { ShareContractService } from '../../services/share-contract.service';
import { UserService } from '../../services/user.service';
import { ChatComponent } from "../chat/chat.component";
import { CommentsComponent } from '../comments/comments.component';
import { ContractInsightsComponent } from "../contract-insights/contract-insights.component";
import { CreateContractComponent } from '../create-contract/create-contract.component';
import { DownloadDocComponent } from "./components/download-doc/download-doc.component";
import { ShareDocComponent } from "./components/share-doc/share-doc.component";

@Component({
  selector: 'app-edit-contract',
  standalone: true,
  imports: [AvatarGroupModule, AvatarModule, ButtonModule, ContractEditorComponent, CommentsComponent, TabMenuModule, AsyncPipe, UserAvatarPipe, TabViewModule, UserChipsComponent, DropdownModule, MenuModule, PanelModule,
    OverlayPanelModule, UserAvatarPipe, CheckboxModule, FormsModule, ConfirmPopupModule, ApprovalWorkflowComponent, FullNamePipe, ContractInsightsComponent, ShareDocComponent, MenubarModule, AutoCompleteModule, DragDropModule,
    CreateContractComponent, MatDialogModule, ConfirmDialogModule, CommonModule, BadgeComponent, InputIconModule, IconFieldModule, InputTextModule, ChatComponent, DialogModule, TieredMenuModule, ScrollResetDirective, DownloadDocComponent],
  templateUrl: './edit-contract.component.html',
  styleUrl: './edit-contract.component.scss',
  providers: [ConfirmationService]
})
export class EditContractComponent implements AfterViewInit, OnInit, OnDestroy {
  htmlContent: string = ""
  contractId: string = "";
  userId: string = ""
  items: MenuItem[] | undefined;
  labelText: string = 'Send for Approval';
  actionButton: boolean = false;

  @ViewChild('contractEditor') contractEditor!: ContractEditorComponent;
  @ViewChild('editableContent', { static: false }) editableContent!: ElementRef;
  contract !: IContract;
  connectedUsers$!: BehaviorSubject<IUser[]>
  CONTRACT_STATUS = CONTRACT_STATUS


  users: any[] = []
  approvers: IShareUser[] = []

  selectedApprovers: IUser[] = []
  signatoryEmail: string = ""
  signatoryName: string = ""
  signatoryEmailValidation: boolean = false
  approversDialog: boolean = false

  approvalConf!: IContractApprovalConf
  signatoryConf!: IContractSignatoryConf
  disableAddSignatories: boolean = false
  signatories: { email: string, name: string, status: string }[] = []
  signatoryType = SIGNATORY_TYPE.DOCU_SIGN
  CONTRACT_SIGNATORY_TYPE = SIGNATORY_TYPE
  CONTRACT_APPROVAL_STATUS = CONTRACT_APPROVAL_STATUS
  approver: boolean = false
  currentApprover: any
  currentUser!: IUser | null | undefined
  createdBy: IUser | null | undefined
  flowType = FLOW_TYPE

  role: string = ''
  fetchingRole: boolean = true
  isOwner: boolean = false
  isEditor: boolean = false
  lastModified: string = ''
  loading: boolean = true
  markdownContent: string | null = null

  options: MenuItem[] = []
  permissions: {
    canView: boolean,
    canEdit: boolean,
    canComment: boolean,
    restricted: boolean
  } = {
      canView: false,
      canEdit: false,
      canComment: false,
      restricted: true
    }

  dialog = inject(MatDialog)
  menuItems: MenuItem[] = [
    {
      label: 'File',
      items: [{
        label: 'New',
        icon: 'pi pi-file-plus',
        command: () => { this.createNewDocument() }
      }, {
        label: 'Open',
        icon: 'pi pi-folder-open',
        command: () => { this.openCreateDialog() }
      }, {
        label: 'Download',
        icon: 'pi pi-download',
        items: [{
          label: 'PDF',
          icon: 'pi pi-file-pdf',
        },
        {
          label: 'Word',
          icon: 'pi pi-file-word',
        }]
      }]
    }
  ]

  rightSidebarVisible: boolean = true;
  comments: IContractComment[] = []
  isAnonymousUser: boolean = false

  autoCompleteValue: string = '';
  openDownloadContract: boolean = false;

  constructor(private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef, private router: Router, private messageService: MessageService, private contractService: ContractService, private contractSignatoryService: ContractSignatoryService,
    private fingerprintService: FingerprintService,
    private confirmationService: ConfirmationService,
    private userService: UserService,
    private authService: AuthenticationService,
    public approvalService: ApprovalConfService,
    public commentService: CommentService,
    private toast: ToastService,
    private shareService: ShareContractService
  ) {
    this.connectedUsers$ = this.contractService.connectedUsers$
    this.authService.currentUser.subscribe(res => {
      this.currentUser = res
      this.isAnonymousUser = res?.anonymous ?? false;
    });
    this.route.queryParamMap.subscribe(params => {
      this.htmlContent = params.get('htmlContent') as string ?? ""
      this.contractId = params.get('documentId') as string ?? ""
      this.userId = params.get('user') as string ?? ""
    });
    this.contractService.getAiGeneratedContent().pipe(take(1)).subscribe(res => {
      this.markdownContent = res
      this.contractService.setAiGeneratedContent('')
    })


    this.items = [
      {
        label: 'Insights',
        icon: 'pi pi-chart-line',
        command: () => {
          this.messageService.add({ severity: 'success', summary: 'Selected', detail: 'Dashboard', life: 3000 });
        }
      },
      {
        label: 'Approvers',
        icon: 'pi pi-check-square',
        command: () => {
          this.messageService.add({ severity: 'success', summary: 'Selected', detail: 'Transactions', life: 3000 });
        }
      },
      {
        label: 'Signatories',
        icon: 'pi pi-list',
        command: () => {
          this.messageService.add({ severity: 'success', summary: 'Selected', detail: 'Products', life: 3000 });
        }
      },
      {
        label: 'Messages',
        icon: 'pi pi-inbox',
        command: () => {
          this.messageService.add({ severity: 'success', summary: 'Selected', detail: 'Messages', life: 3000 });
        }
      }
    ];

  }

  private commentsSubscription!: Subscription;
  visitorId!: string;

  isSameOrganization: boolean = false;

  async ngOnInit() {
    await this.getSharedDetails();
    this.fetchContract()
    this.getLastModified()
    this.getContractApprovers()
    this.getApprovers()
    this.fetchContractSignatories()
    this.fetchAllComments()
    this.upadteOptions()

    this.commentsSubscription = this.commentService.commentsObservable$.subscribe(() => {
      this.fetchAllComments();
    });

  }

  ngOnDestroy(): void {
    if (this.commentsSubscription) {
      this.commentsSubscription.unsubscribe();
    }
  }

  upadteOptions() {
    this.options = [
      // { label: 'Preview', icon: 'pi pi-eye', command: () => { this.action() } },
      // { label: 'See History', icon: 'pi pi-history', command: () => { this.action() } },
      { label: 'Upload', icon: 'pi pi-upload', visible: this.isOwner || this.isEditor, command: () => { this.openCreateDialog() } },
      {
        label: 'Download', icon: 'pi pi-download', command: () => { this.openDownloadDailog(true) },

      },
    ]
  }

  fetchContract() {
    if (this.contractId.length > 0) {
      this.contractService.fetchContract(this.contractId).subscribe((res: { data: IContract; }) => {
        this.contract = res.data
        this.signatoryType = res.data?.defaultSignature
        if (!res.data.defaultSignature) {
          this.fetchSignatorySetting()
        }
        this.checkContractStatus()

        this.isSameOrganization = this.currentUser?.organization === this.contract.organization
      })
    } else {
      this.toast.error('No contract found')
    }
  }

  openDownloadDailog(status: boolean) {
    this.openDownloadContract = status
  }
  getLastModified() {
    this.loading = true;
    this.fetchContract()
    setTimeout(() => {
      this.loading = false
    }, 1000)
  }

  fetchSignatorySetting() {
    this.contractSignatoryService.getDefaultSignatory().subscribe(res => {
      if (res.success) {
        this.signatoryType = res.data?.defaultSignatory
      }
    })
  }

  sharedUsers: IShareUser[] = []
  shareDetails!: IShareContract

  async getSharedDetails() {
    return new Promise((resolve, reject) => {
      this.shareService.getShareDetails(this.contractId).subscribe(async (res) => {
        if (res.data) {
          this.shareDetails = res.data;
          this.sharedUsers = res.data.users;
          await this.getPermissionOfUser();
          this.fetchingRole = false;
          resolve(true);
        } else {
          this.fetchingRole = false;
          reject('Failed to fetch share details');
        }
      },
        err => reject(err)
      );
    });
  }

  currentRole: string = ''

  getPermissionOfUser() {
    return new Promise((resolve, reject) => {
      this.shareService.getPermissionOfUser(this.contractId).subscribe(res => {
        if (res.success) {
          this.permissions = res.data
          if (this.permissions.canEdit) {
            this.isEditor = true
            this.currentRole = CONTRACT_USER_ROLE.EDITOR
          } else if (this.permissions.canComment) {
            this.currentRole = CONTRACT_USER_ROLE.COMMENTER
          } else if (this.permissions.canView) {
            this.currentRole = CONTRACT_USER_ROLE.VIEWER
          }
          if (this.contract.createdBy == this.currentUser?._id) {
            this.isOwner = true
            this.currentRole = CONTRACT_USER_ROLE.OWNER
          }
          resolve(true)
        } else {
          reject('Failed to fetch role');
        }
      })
    })

  }

  blurSpan(event: Event) {
    const target = event.target as HTMLElement;
    target.blur();
  }

  updateContractName(event: Event) {
    event.preventDefault()
    const target = event.target as HTMLElement;
    const newName = target.textContent?.trim() || 'Untitled';
    target.innerHTML = newName
    if (this.contract && this.contract.name !== newName) {
      this.contract.name = newName;
      this.updateContract();
    }
  }

  createNewDocument() {
    const form: FormData = new FormData();
    form.append('name', 'Untitled');

    this.contractService.createContract(form).subscribe((res) => {
      if (res.success && res.data) {
        const url = this.router.createUrlTree(['edit-document'], {
          queryParams: { 'documentId': res.data._id }
        }).toString();


        window.open(url, '_blank');
      }
    });
  }

  openCreateDialog() {
    const _dialogRef = this.dialog.open(CreateContractComponent, {
      disableClose: true,
      minWidth: '40rem',
      data: { contractId: this.contractId }
    });

    _dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.contractEditor.insertHTML(response)
      }
    })
  }

  updateContract() {
    if (this.contractId && this.contract) {
      this.contractService.updateContract(this.contractId, this.contract).subscribe(
        res => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Contract updated successfully', life: 3000 });
        },
        error => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update contract', life: 3000 });
        }
      );
    } else {
      console.error("No contract to update");
    }
  }


  getApprovers() {

  }

  getUsers() {
    this.userService.getUsers().subscribe(res => {
      this.users = res.data
      this.users = this.users.filter((user: any) =>
        !this.approvalConf?.approvers.some((approver: any) => approver.user._id === user._id)
      );
      this.users = this.users
    })
  }

  autoCompleteUsers: any[] = []

  autoCompleteUsersMethod(event: any) {
    this.autoCompleteUsers = this.users.filter((user: any) => {
      return (user.fullName.toLowerCase().includes(event.query.toLowerCase()) || user.email.toLowerCase().includes(event.query.toLowerCase()))
    })
  }

  ngAfterViewInit(): void {
    if (this.htmlContent) {
      this.contractEditor.insertHTML(this.htmlContent)
    }
    this.approvalChange()
  }

  getIconPath(): string {
    return this.labelText === 'Publish' ? '/icons/rocket.svg' : '/icons/user-check.svg';
  }


  selectApprover(user: any) {
    user.selected = !user.selected;
    if (user.selected) {
      this.selectedApprovers.push(user);
    } else {
      this.selectedApprovers = this.selectedApprovers.filter(u => u !== user);
    }
  }

  getContractApprovers() {
    this.approvalService.fetchContractApprovers(this.contractId).subscribe(res => {
      if (res.data) {
        this.approvalConf = res.data
        this.approvers = [...this.approvalConf.approvers]
      }
    });
  }

  saveApprovalWorkflow() {
    const approvers: IContractApprovalConf['approvers'] = this.approvers.map((approver) => {
      return {
        user: approver.type == USER_TYPE.INTERNAL ? (approver.user as IUser)?._id ?? approver.user : undefined,
        email: approver.email,
        name: approver.name,
        status: CONTRACT_APPROVAL_STATUS.WAITING,
        type: approver.type
      }
    })
    this.approvalService.saveApprovalWorkflow(this.contractId, approvers).subscribe(res => {
      if (res.success) {
        this.toast.success('Approval Workflow Saved Successfully');
        this.approversDialog = false
        this.approvalChange()
      }
    });
  }


  fetchContractSignatories() {
    this.contractSignatoryService.fetchContractSignatories(this.contractId).subscribe(res => {
      if (res.data) {
        this.signatoryConf = res.data
        switch (this.signatoryConf.status) {
          case CONTRACT_SIGNATORY_STATUS.NOT_YET_STARTED:
          case CONTRACT_SIGNATORY_STATUS.REJECTED:
            this.disableAddSignatories = false
            break;
          default:
            this.disableAddSignatories = true
        }
        this.signatories = this.signatoryConf.signatories.map((signatory) => {
          return {
            email: signatory.email,
            name: signatory.name,
            status: signatory.status,
            signedAttachment: signatory.signedAttachment,
            updatedAt: signatory.updatedAt
          }
        })
        this.checkContractStatus()
      }
    })
  }

  addSignatory() {
    this.signatories.push({
      email: this.signatoryEmail,
      name: this.signatoryName,
      status: CONTRACT_APPROVAL_STATUS.WAITING
    })
    this.signatoryEmail = ""
    this.signatoryName = ""
    this.updateSignatories()
  }

  updateSignatories() {
    this.contractSignatoryService.updateSignatories(this.contractId, this.signatories).subscribe(res => {
      if (res.success) {
        this.fetchContractSignatories()
      }
    })
  }

  updateName() {
    if (validateEmail(this.signatoryEmail)) this.signatoryEmailValidation = true
    else this.signatoryEmailValidation = false
    this.signatoryName = this.signatoryEmail.substring(0, this.signatoryEmail.lastIndexOf('@') == -1 ? this.signatoryEmail.length : this.signatoryEmail.lastIndexOf('@'));
  }

  goBack() {
    this.router.navigate(['/home']);
  }

  async approvalChange() {
    this.checkContractStatus()
    this.getContractApprovers()
    this.getApprovers()
    this.getUsers()
  }

  async signatoryChange(email: string) {
    this.confirmationService.confirm({
      header: 'Are you sure?',
      message: `This action will remove the signatory 
      <span class="bg-gray-100 px-1.5 py-1 rounded-md">${email}</span>
       from the document. Do you want to proceed?`,
      accept: () => {
        this.signatories = this.signatories.filter((signatory) => signatory.email !== email)
        this.updateSignatories()
      },
      reject: () => { }
    });
  }

  downloadDocument(uint8Array: Uint8Array, fileName: string) {
    const blob: Blob = new Blob([new Uint8Array(uint8Array)], {
      type: 'application/pdf'
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.pdf`;
    document.body.append(link);
    link.click();
    link.remove();
  }

  async downloadSignedDocument(event: { email: string, name: string, status: string, signedAttachment?: string }) {
    this.contractSignatoryService.downloadDocument(event.signedAttachment as string).subscribe(res => {
      if (res.success) {
        this.downloadDocument(res.data.data, `${event.name} - Signed Document - ${this.contract.name}`)
      }
    })
  }

  action() {
    if (this.contract) {
      switch (this.contract.status) {
        case CONTRACT_STATUS.READY_FOR_APPROVAL:
          if (this.approvalConf?.approvers && this.approvalConf.approvers.length > 0) {
            this.approvalService.sendForApproval(this.contractId).subscribe((res) => {
              this.toast.success('Document Sent for Approval Successfully');
              this.approvalChange()
            });
          }
          break;

        case CONTRACT_STATUS.APPROVED:
          // this.contractService.sendForSign(this.contractId).subscribe((res) => {
          //   this.checkContractStatus()
          // });
          break;

        case CONTRACT_STATUS.READY_FOR_SIGNATURE:
          if (this.signatoryConf.signatories.length > 0) {
            this.startSignatoryFlow()
            this.actionButton = true;
          }
          break;
      }
    }
  }

  checkContractStatus() {
    if (this.contractId.length > 0) {
      this.contractService.fetchContract(this.contractId).subscribe((res: { data: IContract; }) => {
        this.contract = res.data
        switch (this.contract?.status) {
          case CONTRACT_STATUS.DRAFT:
            this.labelText = 'Send for Approval';
            this.actionButton = true;
            break;

          case CONTRACT_STATUS.READY_FOR_APPROVAL:
            this.labelText = 'Send for Approval';
            this.actionButton = false;
            break;

          case CONTRACT_STATUS.SIGNATURE_IN_PROGRESS:
          case CONTRACT_STATUS.PENDING_APPROVAL:
            this.labelText = 'Publish';
            this.actionButton = true;
            break;

          case CONTRACT_STATUS.APPROVED:
            this.labelText = 'Publish';
            this.actionButton = true;
            break;
          case CONTRACT_STATUS.READY_FOR_SIGNATURE:
          case CONTRACT_STATUS.SIGNATORY_REJECTED:
            this.labelText = 'Publish';
            if ((this.signatoryConf?.signatories ?? []).length > 0) this.actionButton = false;
            else this.actionButton = true;
            break;
        }
      });
    }

  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const text = event.clipboardData?.getData('text/plain');
  }

  startSignatoryFlow() {
    this.toast.info('Document Sent for Signature');
    this.contractSignatoryService.startSignatoryFlow(this.contractId, this.signatoryType).subscribe(res => {
      if (res.success) {
        this.toast.success('Signatory Flow Started Successfully');
        this.fetchContractSignatories()
        this.actionButton = true
      } else {
        this.actionButton = false
        this.toast.warn(res.message)
      }
    })
  }

  resetSignatoryFlow() {
    this.confirmationService.confirm({
      header: 'Are you sure?',
      message: `This action will stop the current ongoing signatory flow for this document. Do you want to proceed?`,
      accept: () => {
        this.contractSignatoryService.resetSignatoryFlow(this.signatoryConf._id).subscribe(res => {
          if (res.success) {
            this.fetchContractSignatories()
          }
        })
      },
      reject: () => { }
    });
  }

  resetApprovalFlow() {
    this.confirmationService.confirm({
      header: 'Are you sure?',
      message: `This action will reset the approval flow for this document. Do you want to proceed?`,
      accept: () => {
        this.approvalService.resetApprovalWorkflow(this.contractId).subscribe(res => {
          if (res.success) {
            this.toast.success('Reset Document Successfully ');
            this.getContractApprovers();
            this.checkContractStatus()
          }
        });
      },
      reject: () => { }
    });
  }

  skipApprovalFlow() {
    this.confirmationService.confirm({
      header: 'Are you sure?',
      message: `This action will skip the approval flow for this document. Do you want to proceed?`,
      accept: () => {
        this.approvalService.skipApprovalFlow(this.contractId).subscribe(res => {
          if (res.success) {
            this.getContractApprovers();
            this.checkContractStatus()
          }
        })
      },
      reject: () => { }
    });
  }

  toggleApproveDialog() {
    this.approversDialog = !this.approversDialog
    this.getContractApprovers()
  }

  addInternalApprover(event: any) {
    const user = event.value
    const findUser = this.approvers.find((approver: any) => approver.email === user.email)
    if (findUser) {
      this.toast.warn('User already added')
      this.autoCompleteValue = ''
      return
    }
    this.approvers.push({
      name: user.fullName,
      user: user._id,
      email: user.email,
      type: USER_TYPE.INTERNAL
    })
    this.autoCompleteValue = ''
  }

  addExternalApprover() {
    const email = this.autoCompleteValue
    const findUser = this.approvers.find((approver: any) => approver.email === email)
    if (findUser) {
      this.toast.warn('User already added')
      this.autoCompleteValue = ''
      return
    }
    this.approvers.push({
      name: email.split('@')[0],
      email,
      type: USER_TYPE.EXTERNAL
    })
    this.autoCompleteValue = ''
  }

  removeApprover(index: number) {
    this.approvers.splice(index, 1)
  }

  validApproverEmail() {
    return !validateEmail(this.autoCompleteValue)
  }

  draggedApproverIdx: any;

  dragStart(index: number) {
    this.draggedApproverIdx = index;
  }

  drop(dropIndex: number) {
    if (this.draggedApproverIdx !== null && this.draggedApproverIdx !== dropIndex) {
      const draggedApprover = this.approvers[this.draggedApproverIdx];
      this.approvers.splice(this.draggedApproverIdx, 1);
      this.approvers.splice(dropIndex, 0, draggedApprover);
      this.draggedApproverIdx = null;
    }
  }

  allowDrop(event: any) {
    event.preventDefault();
  }

  onCloseApproverDialog() {
    this.approversDialog = false
    this.approvers = []
  }

  onApprovalChange(approvalConf: IContractApprovalConf) {
    this.approvers = approvalConf.approvers
    this.approvalConf = approvalConf
    this.checkContractStatus()
  }

  fetchAllComments() {
    this.commentService.getAllComments({ contractId: this.contractId }).subscribe(res => {
      if (res.success) {
        this.comments = res.data
      }
    })
  }

}
