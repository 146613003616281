import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-app-layout',
  standalone: true,
  imports: [RouterModule, RouterModule, ButtonModule, TopBarComponent],
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.scss'
})
export class AppLayoutComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit(): void {

  }

}
