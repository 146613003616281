import { Document } from "../../../@core/models/model";
import { IOrganization, IUser } from "../../../@core/models/model";
import { IContract } from "../../../contract-management/models/contract-management.model";

export enum MARKS {
    LINK = 'link',
    ITALIC = 'em',
    UNDERLINE = 'underline',
    BOLD = 'strong',
    CODE = 'code',
    STRIKETHROUGH = 'strikethrough'
}

export interface IContractComment extends Document {
    builder: string | IOrganization,
    category: string | IContract,
    user: IUser
    content: string
    replies: {
        user: IUser
        content: string,
        createdAt: Date
    }[],
    createdAt: Date,
    updatedAt: Date
}