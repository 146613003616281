import { Component, Input, OnInit } from '@angular/core';
import { ContractInsightsService } from '../../services/contract-insights.service';
import { IContractInsights } from '../../models/contract-management.model';
import { DateFormatterPipe } from "../../../@shared/pipes/dateFormater.pipe";
import { ProgressSpinnerModule } from 'primeng/progressspinner';


@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [DateFormatterPipe, ProgressSpinnerModule],
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.scss'
})
export class SummaryComponent implements OnInit {
  @Input() contractId: string = "";

  summaryLoading: boolean = true;
  contractInsights: IContractInsights | undefined;

  constructor(private contractInsightsService: ContractInsightsService,) {
  }

  ngOnInit(): void {
    this.generateSummary(false);
  }

  generateSummary(generateInsights: boolean) {
    if (this.contractId.length > 0) {
      this.summaryLoading = true;
      this.contractInsightsService.generateSummary(this.contractId, generateInsights).subscribe({
        next: (res: any) => {
          if (res.data) {
            this.contractInsights = res.data;
          }
          this.summaryLoading = false;
        },
        error: (error) => {
          this.summaryLoading = false;
        }
      });
    }
  }
}
