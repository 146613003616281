import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[scrollReset]',
    standalone: true
})
export class ScrollResetDirective {
    constructor(private el: ElementRef) { }

    @HostListener('blur')
    onBlur() {
        // Reset scroll position to start
        this.el.nativeElement.scrollLeft = 0;
    }

    @HostListener('keydown.enter', ['$event'])
    onEnterKey(event: KeyboardEvent) {
        event.preventDefault();
    }
}