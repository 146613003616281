import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { PasswordModule } from "primeng/password";

import { catchError } from "rxjs";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { AuthenticationService } from "../../@core/services/authentication.service";
import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'app-login',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        InputTextModule,
        PasswordModule,
        ButtonModule,
        RouterModule
    ],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LogInComponent implements OnInit {

    loginForm!: FormGroup;

    defaultUrl: string = '/home'
    returnUrl: string = this.defaultUrl;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private toastService: ToastrService,
        private authenticationService: AuthenticationService
    ) {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.defaultUrl;
    }

    ngOnInit() {
        this.loginForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    onSubmit() {
        if (this.loginForm.valid) {
            const credentials = this.loginForm.value;
            this.authenticationService.login(credentials).pipe(catchError((error) => {
                this.toastService.error('Invalid Email or Password');
                return error;
            })).subscribe((userLoggedIn) => {

                if (userLoggedIn) {
                    this.router.navigate([this.returnUrl])
                }
                else {
                    this.toastService.error('Login Failed', 'Error!');
                }
            })

        }
    }

    redirectToSignup() {
        this.router.navigate(['/signup'])
    }

}