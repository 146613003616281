import { Plugin, PluginKey } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { debounce } from 'lodash';
import { SlashMenuItem } from './utils';

export const slashMenuPlugin = (menuItems: SlashMenuItem[]) => new Plugin({
    key: new PluginKey('slashMenu'),
    view(editorView) {
        return new SlashMenuView(editorView, menuItems)
    },
    props: {
        handleKeyDown(view, event) {
            if (event.key === '/' && this.spec?.key) {
                const slashMenuView = this.spec.key.get(view.state) as SlashMenuView | undefined;
                if (slashMenuView && 'handleSlashInput' in slashMenuView) {
                    slashMenuView.handleSlashInput();
                    return true;
                }
            }
            return false;
        }
    }
})

class SlashMenuView {
    private view: EditorView
    private menu: HTMLElement | null = null
    private dialog: HTMLElement | null = null
    private menuCharacter: string | null = null;
    private menuItems: SlashMenuItem[] = []
    private memoizedMenuHTML: string | null = null;
    private clickOutsideHandler: (event: MouseEvent) => void
    private removeSlashDebounced: () => void;

    constructor(view: EditorView, menuItems: SlashMenuItem[]) {
        this.view = view;
        this.menuItems = menuItems;
        this.removeSlashDebounced = debounce(this.removeSlash, 100);

        this.clickOutsideHandler = this.handleClickOutside.bind(this);
    }

    createMenu() {
        this.menu = document.createElement('div');
        this.menu.className = 'slash-menu overlay-menu overlay';
        this.menu.innerHTML = this.generateSlashMenu(this.menuItems);
        const editorContainer = this.view.dom.closest('.editor-container');
        if (editorContainer) {
            editorContainer.appendChild(this.menu);
        } else {
            document.body.appendChild(this.menu);
        }
        this.menu.addEventListener('click', this.handleMenuClick.bind(this));
    }

    handleSlashInput() {
        this.menuCharacter = '/';
        this.showMenu();
    }

    generateSlashMenu(menuItems: SlashMenuItem[]): string {
        if (this.memoizedMenuHTML === null) {
            this.memoizedMenuHTML = menuItems.map(section => `
                <div class="slash-menu-header flex items-center p-2 ${section.customClass ? section.customClass : ''}" data-key="${section.key}">
                    ${section.icon ? `<span class="m-1 material-icons-outlined ${section.customClassIcon ? section.customClassIcon : ''}">${section.icon}</span>` : ''}
                    <span class="ml-2 ${section.customClassLabel ? section.customClassLabel : ''}">${section.label}</span>
                </div>
            `).join('');
        }
        return this.memoizedMenuHTML;
    }

    update(view: EditorView) {
        const { state } = view
        const { selection } = state
        const { $from } = selection
        const textBefore = $from.nodeBefore?.text
        if (textBefore && (textBefore === '/' || textBefore.endsWith(' /'))) {
            this.menuCharacter = '/'
            this.showMenu()
        } else {
            this.hideMenu()
        }
    }

    showMenu() {
        if (!this.menu) {
            this.createMenu();
        }
        document.addEventListener('click', this.clickOutsideHandler);
        // Position the menu
        this.updateMenuPosition();
    }

    hideMenu() {
        if (this.menu) {
            this.menu.style.display = 'none'
            document.removeEventListener('click', this.clickOutsideHandler)

            if (this.menuCharacter) {
                this.removeSlashDebounced();
            }
        }
    }

    private updateMenuPosition() {
        requestAnimationFrame(() => {
            this.positionMenu();
        });
    }

    positionMenu() {
        if (!this.menu) return;
        const { from } = this.view.state.selection;
        const start = this.view.coordsAtPos(from);
        const editorContainer = this.view.dom.closest('.editor-container');

        if (editorContainer) {
            const editorRect = editorContainer.getBoundingClientRect();
            const menuRect = this.menu.getBoundingClientRect();

            // Get the scroll position of the editor container
            const scrollTop = editorContainer.scrollTop;
            const scrollLeft = editorContainer.scrollLeft;

            // Calculate position relative to the editor container, accounting for scroll
            let top = start.top - editorRect.top + 20 + scrollTop; // 20px offset to position below the cursor
            let left = start.left - editorRect.left + scrollLeft;

            // Ensure the menu doesn't go off the right edge of the container
            if (left + menuRect.width > editorRect.width + scrollLeft) {
                left = editorRect.width + scrollLeft - menuRect.width;
            }

            // Ensure the menu doesn't go off the bottom of the container
            if (top + menuRect.height > editorRect.height + scrollTop) {
                top = start.top - editorRect.top - menuRect.height - 5 + scrollTop; // Position above the cursor
            }

            // Ensure the menu doesn't go off the top of the container
            if (top < scrollTop) {
                top = start.top - editorRect.top + 20 + scrollTop; // Reposition below the cursor
            }

            // Ensure the menu doesn't go off the left edge of the container
            if (left < scrollLeft) {
                left = scrollLeft;
            }

            this.menu.style.position = 'absolute';
            this.menu.style.top = `${top}px`;
            this.menu.style.left = `${left}px`;
            this.menu.style.display = 'block';
        } else {
            this.menu.style.position = 'fixed';
            this.menu.style.top = `${start.top + 20}px`;
            this.menu.style.left = `${start.left}px`;
            this.menu.style.display = 'block';
        }
    }

    handleMenuClick(event: MouseEvent) {
        event.stopPropagation();
        const target = event.target as HTMLElement;
        const menuItem = target.closest('.slash-menu-header') as HTMLElement;
        if (menuItem) {
            const key = menuItem.dataset['key'];
            const item = this.menuItems.find(i => i.key === key);
            if (item) {
                if (item.action) {
                    item.action();
                }
                this.hideMenu();
            }
        } else {
            this.hideMenu();
        }
    }

    handleClickOutside(event: MouseEvent) {
        if (this.menu && !this.menu.contains(event.target as Node)) {
            this.hideMenu()
        }
    }

    destroy() {
        if (this.menu) {
            this.menu.remove();
            document.removeEventListener('click', this.clickOutsideHandler)
        }
        if (this.dialog) {
            this.dialog.remove();
        }
    }

    private removeSlash = () => {
        const { state, dispatch } = this.view;
        const { selection } = state;
        const { $from } = selection;

        // Get the text before the cursor
        const textBefore = state.doc.textBetween(Math.max(0, $from.pos - 100), $from.pos);

        // Check if there's only a single slash or a space followed by a slash
        if (textBefore.endsWith('/') || textBefore.endsWith(' /')) {
            const slashPos = $from.pos - 1;
            const tr = state.tr.delete(slashPos, slashPos + 1);
            dispatch(tr);
        }

        this.menuCharacter = null;
    }
}
