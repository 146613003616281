@if (clauseResponse) {

@for (clause of clauseResponse.clauses; track clause) {
<div class="group p-2">
    <p class="font-bold text-lg mt-2 group-hover:text-arena-orange-500 transition-colors duration-300">{{clause.title}}
    </p>
    <p class="text-sm mt-1 text-gray-700 text-justify">{{clause.summary}}</p>
</div>
}
} @else {
<div>
    <p>No clauses found</p>
</div>
}