import { Component, OnDestroy, ElementRef, ViewChild, AfterViewInit, NgZone, signal, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { baseKeymap, setBlockType, toggleMark } from 'prosemirror-commands';
import { history, redo, undo } from 'prosemirror-history';
import { keymap } from "prosemirror-keymap";
import { DOMSerializer, Mark, MarkType, NodeType, DOMParser as ProseDOMParser } from 'prosemirror-model';
import { EditorState, NodeSelection, Plugin, TextSelection, Transaction } from 'prosemirror-state';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';
import { IContractComment, MARKS } from './model';
import { defaultMarkdownParser, MarkdownParser } from "prosemirror-markdown"
import { alignCenter, alignJustify, alignLeft, alignRight } from './plugins/justify-plugin';
import { schema } from './plugins/schema';
import { CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
//Yjs
import { dropCursor } from 'prosemirror-dropcursor';
import { addPageBreak, appltHeadingStyle, listKeymap, SLASH_MENU_ITEMS, SLASH_MENU_KEYS, SlashMenuItem, toggleBlockquote, toggleList, customTokens } from './plugins/utils';

//Yjs
import * as Y from 'yjs';
import { AddCommentComponent } from "./components/add-comment/add-comment.component";


import { WebrtcProvider } from 'y-webrtc'; //!TODO : peer-to-peer connection
import { WebsocketProvider } from 'y-websocket'
import { ySyncPlugin, yCursorPlugin, yUndoPlugin, ySyncPluginKey, initProseMirrorDoc, yXmlFragmentToProseMirrorRootNode } from 'y-prosemirror';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { ReplyComponent } from "./components/reply/reply.component";

import { IUser } from '../../../@core/models/model';
import { AuthenticationService } from '../../../@core/services/authentication.service';
import { FullNamePipe } from '../../pipes/fullName.pipe';
import { ContractService } from '../../../contract-management/services/contracts.service';
import { dragElementPlugin } from './plugins/drag-plugin';
import { CommentsComponent } from '../../../contract-management/components/comments/comments.component';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { IContractApprovalConf } from '../../../contract-management/models/contract-management.model';
import { CONTRACT_APPROVAL_STATUS } from '../user-chips/user-chips.component';
import { ToastService } from '../../../@core/services/toast.service';
import { addColumnAfter, addColumnBefore, addRowAfter, addRowBefore, deleteColumn, deleteRow, deleteTable, mergeCells, setCellAttr, splitCell, tableEditing, toggleHeaderCell, toggleHeaderColumn, toggleHeaderRow } from 'prosemirror-tables';
import { MenubarModule } from 'primeng/menubar';
import { CommentService } from '../../../services/comment.service';
import { ApprovalConfService } from '../../../contract-management/services/approval-conf.service';
import { idPlugin } from './plugins/id-plugin';
import { environment } from '../../../../environments/environment.dev';
import { slashMenuPlugin } from './plugins/slash-plugin';
import { ActivatedRoute } from '@angular/router';
import { ShareContractService } from '../../../contract-management/services/share-contract.service';
import dragAndDropHandlePlugin from './plugins/drag-n-drop-plugin';
import { DialogModule } from 'primeng/dialog';
import { ContractEditorService } from './contract-editor.service';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { linkBehaviorPlugin } from './plugins/link-plugin';

@Component({
  selector: 'app-contract-editor',
  standalone: true,
  imports: [CommonModule, ButtonModule, FormsModule, DropdownModule, AddCommentComponent, ClickOutsideDirective, CommentsComponent, ReplyComponent, CdkDropList, CdkDrag, FullNamePipe, ConfirmDialogModule,
    MenubarModule, CommentsComponent, DialogModule, DialogModule, OverlayPanelModule
  ],
  templateUrl: './contract-editor.component.html',
  styleUrls: ['./contract-editor.component.scss'],
  providers: [ConfirmationService]
})
export class ContractEditorComponent implements AfterViewInit, OnDestroy {

  @ViewChild('editorContainer') editorContainer!: ElementRef;
  @ViewChild('overlayMenu') private overlayMenuRef!: ElementRef;
  @ViewChild('addCommentDialog') private addCommentRef!: AddCommentComponent;
  @ViewChild('dragHand') private dragHand!: ElementRef;
  @Input() contractId: string = ''
  @Input() roomName: string = 'contract-management'
  @Input() needApproval: boolean = false
  @Output() needApprovalChange = new EventEmitter<boolean>();
  @Input() approvers!: IContractApprovalConf
  @Input() canEdit: boolean = true
  @Input() canComment: boolean = true
  @Input() canView: boolean = true
  _comments: IContractComment[] = [];
  @Input() set comments(data: IContractComment[]) {
    if (data) {
      this._comments = data;
    }
  }
  @Output() onApprovalChange = new EventEmitter<IContractApprovalConf>();
  public _approvalConf!: IContractApprovalConf;
  public currentApprover!: any
  @Input()
  set approvalConf(value: IContractApprovalConf) {
    this._approvalConf = value;
    this.currentApprover = (this._approvalConf?.approvers ?? []).find(approver => approver.email === this.currentUser?.email)
  }

  @Input() isOwner: boolean = true
  @Input() permissions: {
    canView: boolean,
    canEdit: boolean,
    canComment: boolean,
    restricted: boolean
  } = {
      canView: false,
      canEdit: false,
      canComment: false,
      restricted: true
    }
  @Input() markdownContent: string | null = null

  otpDialog: boolean = false;
  sendMailLabel: string = 'Send OTP';
  currentStep: number = 1;
  otp: string = '';
  MARKS = MARKS
  customSchema = schema;
  showOverlay = false;
  overlayTop = 0;
  overlayLeft = 0;
  dragHandTop = 0;
  dragHandLeft = 0;

  dragFocus: boolean = false;
  editorFocus: boolean = false;
  view !: EditorView;


  // Yjs related properties
  private yDoc!: Y.Doc;
  private provider!: WebsocketProvider;
  private type!: Y.XmlFragment;

  // Approval Related Properties
  approvalStatus: boolean = false;
  APPROVAL_STATUS = CONTRACT_APPROVAL_STATUS;

  headingOptions = [
    { name: 'Heading 1', code: 1 },
    { name: 'Heading 2', code: 2 },
    { name: 'Heading 3', code: 3 },
    { name: 'Heading 4', code: 4 },
    { name: 'Heading 5', code: 5 },
    { name: 'Heading 6', code: 6 },
    { name: 'Normal Text', code: 'Paragraph' }
  ]
  activeHeading: Record<string, string | number> = { name: 'Normal Text', code: 'Paragraph' }
  headings: any[] = []
  approvalStatusVisible = false;

  tableMenu: MenuItem[] = [
    {
      label: 'Table',
      items: [
        {
          label: 'Insert table',
          command: () => { this.insertTable() }
        },
        {
          label: 'Insert column before',
          command: () => { addColumnBefore(this.view.state, this.view.dispatch) }
        },
        {
          label: 'Insert column after',
          command: () => { addColumnAfter(this.view.state, this.view.dispatch) }
        },
        {
          label: 'Delete column',
          command: () => { deleteColumn(this.view.state, this.view.dispatch) }
        },
        {
          label: 'Insert row before',
          command: () => { addRowBefore(this.view.state, this.view.dispatch) }
        },
        {
          label: 'Insert row after',
          command: () => { addRowAfter(this.view.state, this.view.dispatch) }
        },
        {
          label: 'Delete row',
          command: () => { deleteRow(this.view.state, this.view.dispatch) }
        },
        {
          label: 'Delete table',
          command: () => { deleteTable(this.view.state, this.view.dispatch) }
        },
        {
          label: 'Merge cells',
          command: () => { mergeCells(this.view.state, this.view.dispatch) }
        },
        {
          label: 'Split cell',
          command: () => { splitCell(this.view.state, this.view.dispatch) }
        },
        {
          label: 'Toggle header column',
          command: () => { toggleHeaderColumn(this.view.state, this.view.dispatch) }
        },
        {
          label: 'Toggle header row',
          command: () => { toggleHeaderRow(this.view.state, this.view.dispatch) }
        },
        {
          label: 'Toggle header cells',
          command: () => { toggleHeaderCell(this.view.state, this.view.dispatch) }
        },
        {
          label: 'Make cell green',
          command: () => { setCellAttr("background", "#dfd")(this.view.state, this.view.dispatch) }
        },
        {
          label: 'Make cell not-green',
          command: () => { setCellAttr("background", null)(this.view.state, this.view.dispatch) }
        }
      ]
    }
  ];



  dummyDocument = schema.node("doc", null, [
    schema.node("heading", { level: 1 }, [schema.text("[NDA] American Express Document")]),
    schema.node("paragraph", { align: 'justify' }, [schema.text("Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum. Perspiciatis minima nesciunt dolorem! Officiis iure rerum voluptates a cumque velit quibusdam sed amet tempora. Sit laborum ab, eius fugit doloribus tenetur fugiat, temporibus enim commodi iusto libero magni deleniti quod quam consequuntur! Commodi minima excepturi repudiandae velit hic maxime doloremque. Quaerat provident commodi consectetur veniam similique ad earum omnis ipsum saepe, voluptas, hic voluptates pariatur est explicabo fugiat, dolorum eligendi quam cupiditate excepturi mollitia maiores labore suscipit quas? Nulla, placeat. Voluptatem quaerat non architecto ab laudantium modi minima sunt esse temporibus sint culpa, recusandae aliquam numquam totam ratione voluptas quod exercitationem fuga. Possimus quis earum veniam quasi aliquam eligendi, placeat qui corporis!Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,")]),
    schema.node("paragraph", { align: 'justify' }, [schema.text("Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum. Perspiciatis minima nesciunt dolorem! Officiis iure rerum voluptates a cumque velit quibusdam sed amet tempora. Sit laborum ab, eius fugit doloribus tenetur fugiat, temporibus enim commodi iusto libero magni deleniti quod quam consequuntur! Commodi minima excepturi repudiandae velit hic maxime doloremque. Quaerat provident commodi consectetur veniam similique ad earum omnis ipsum saepe, voluptas, hic voluptates pariatur est explicabo fugiat, dolorum eligendi quam cupiditate excepturi mollitia maiores labore suscipit quas? Nulla, placeat. Voluptatem quaerat non architecto ab laudantium modi minima sunt esse temporibus sint culpa, recusandae aliquam numquam totam ratione voluptas quod exercitationem fuga. Possimus quis earum veniam quasi aliquam eligendi, placeat qui corporis!Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,")]),
    schema.node("paragraph", { align: 'justify' }, [schema.text("Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum. Perspiciatis minima nesciunt dolorem! Officiis iure rerum voluptates a cumque velit quibusdam sed amet tempora. Sit laborum ab, eius fugit doloribus tenetur fugiat, temporibus enim commodi iusto libero magni deleniti quod quam consequuntur! Commodi minima excepturi repudiandae velit hic maxime doloremque. Quaerat provident commodi consectetur veniam similique ad earum omnis ipsum saepe, voluptas, hic voluptates pariatur est explicabo fugiat, dolorum eligendi quam cupiditate excepturi mollitia maiores labore suscipit quas? Nulla, placeat. Voluptatem quaerat non architecto ab laudantium modi minima sunt esse temporibus sint culpa, recusandae aliquam numquam totam ratione voluptas quod exercitationem fuga. Possimus quis earum veniam quasi aliquam eligendi, placeat qui corporis!Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,")]),
    schema.node("paragraph", { align: 'justify' }, [schema.text("Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum. Perspiciatis minima nesciunt dolorem! Officiis iure rerum voluptates a cumque velit quibusdam sed amet tempora. Sit laborum ab, eius fugit doloribus tenetur fugiat, temporibus enim commodi iusto libero magni deleniti quod quam consequuntur! Commodi minima excepturi repudiandae velit hic maxime doloremque. Quaerat provident commodi consectetur veniam similique ad earum omnis ipsum saepe, voluptas, hic voluptates pariatur est explicabo fugiat, dolorum eligendi quam cupiditate excepturi mollitia maiores labore suscipit quas? Nulla, placeat. Voluptatem quaerat non architecto ab laudantium modi minima sunt esse temporibus sint culpa, recusandae aliquam numquam totam ratione voluptas quod exercitationem fuga. Possimus quis earum veniam quasi aliquam eligendi, placeat qui corporis!Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,")]),
  ])


  activeMarks = signal<{ [key: string]: boolean }>({
    strong: false,
    em: false,
    strikethrough: false,
    underline: false,
    link: false,
    align_left: false,
    align_center: false,
    align_right: false,
    align_justify: false,
  });

  public versions = signal<{ id: number, timestamp: number }[]>([])
  toggleTOC: boolean = true;


  currentUser: IUser | null | undefined

  suggestionMenuOptions = [
    { label: 'Summarize', key: 'SUMMARIZE' },
    { label: 'Continue', key: 'CONTINUE' }
  ]

  RESPONSE_POSITION: { [key: string]: string } = {
    ABOVE: 'ABOVE',
    BELOW: 'BELOW',
    BESIDE: 'BESIDE'
  };
  aiAssistant: {
    open: boolean,
    content: string,
    response: string,
    loading: boolean
  } = { open: false, content: '', response: '', loading: false };
  slashMenuStructure: SlashMenuItem[] = [
    {
      ...SLASH_MENU_ITEMS[SLASH_MENU_KEYS.AI_ASSISTANT],
      action: () => this.openAIAssistant(),
      customClass: 'theme-text'
    },
    {
      ...SLASH_MENU_ITEMS[SLASH_MENU_KEYS.HEADING_1],
      action: () => this.applyHeading(1)
    },
    {
      ...SLASH_MENU_ITEMS[SLASH_MENU_KEYS.HEADING_2],
      action: () => this.applyHeading(2)
    },
    {
      ...SLASH_MENU_ITEMS[SLASH_MENU_KEYS.HEADING_3],
      action: () => this.applyHeading(3)
    },
    {
      ...SLASH_MENU_ITEMS[SLASH_MENU_KEYS.QUOTE],
      action: () => this.applyStyle('blockquote')
    },
    {
      ...SLASH_MENU_ITEMS[SLASH_MENU_KEYS.BULLET_LIST],
      action: () => this.applyStyle('bullet-list')
    },
    {
      ...SLASH_MENU_ITEMS[SLASH_MENU_KEYS.ORDERED_LIST],
      action: () => this.applyStyle('ordered-list')
    },
    {
      ...SLASH_MENU_ITEMS[SLASH_MENU_KEYS.TABLE],
      action: () => this.insertTable()
    },
    {
      ...SLASH_MENU_ITEMS[SLASH_MENU_KEYS.PARAGRAPH],
      action: () => this.applyHeading('Paragraph')
    },
  ];

  constructor(
    private ngZone: NgZone,
    private authService: AuthenticationService,
    private contractService: ContractService,
    private confirmationService: ConfirmationService,
    public commentService: CommentService,
    private toast: ToastService,
    private approvalService: ApprovalConfService,
    private route: ActivatedRoute,
    private contractEditorService: ContractEditorService
  ) {
    this.currentUser = this.authService.currentUserSubject.value
    this.route.queryParamMap.subscribe(params => {
      this.contractId = params.get('contractId') as string;
    });
  }

  ngAfterViewInit() {
    // Initialize Yjs document
    this.yDoc = new Y.Doc();

    // Initialize WebRTC provider
    this.provider = new WebsocketProvider(
      environment.wsSocketUrl,
      this.contractId,
      this.yDoc,
    )

    // Get the shared type
    // this.type = this.yDoc.getXmlFragment('contract');

    const yXmlFragment = this.yDoc.getXmlFragment(this.contractId)
    let { doc, mapping } = initProseMirrorDoc(yXmlFragment, schema)

    this.getAllConnectedUsers()

    // Create the editor state
    let state = EditorState.create({
      doc,
      plugins: [
        dropCursor(),
        dragAndDropHandlePlugin(),
        history(),
        listKeymap,
        keymap({ "Mod-z": undo, "Mod-y": redo, 'Mod-Shift-z': redo }),
        keymap(baseKeymap),
        tableEditing(),
        this.createOverlayPlugin(),
        this.commentPlugin(),
        linkBehaviorPlugin(this.toast, this.permissions),
        // this.dragHandlePlugin(),
        new Plugin({
          props: {
            handleClickOn(view, _pos, node, nodePos, _event, direct) {
              const { state } = view;
              const { schema } = state;
              if (
                node.type.spec.selectable &&
                direct &&
                node.type === schema.nodes['pageBreak']
              ) {
                const tr = state.tr.setSelection(
                  new NodeSelection(state.doc.resolve(nodePos))
                );
                view.dispatch(tr);
                return true;
              } else {
                return false;
              }
            }
          }
        }),
        new Plugin({
          props: {
            handleDOMEvents: {
              beforeinput: (view, event) => {
                if (!this.isOwner && !this.permissions.canEdit) {
                  event.preventDefault();
                  return true;
                }
                return false;
              },
              paste: (view, event) => {
                if (!this.isOwner && !this.permissions.canEdit) {
                  event.preventDefault();
                  return true;
                }
                return false;
              }
            }
          }
        }),
        ySyncPlugin(yXmlFragment, { mapping }),
        yCursorPlugin(this.provider.awareness),
        yUndoPlugin(),
        idPlugin,
        slashMenuPlugin(this.slashMenuStructure),
        // dragElementPlugin()
      ]
    });
    // Create the editor view
    this.view = new EditorView(this.editorContainer.nativeElement, {
      state,
      editable: () => this.isOwner || this.permissions.canComment ? true : this.permissions.canEdit,
      dispatchTransaction: (transaction: Transaction) => {
        const newState = this.view!.state.apply(transaction);
        this.updateActiveMarks(newState);
        this.handleTOC(newState)
        this.view!.updateState(newState);
      },
      handleDrop: (_view, event, _slice) => {
        // const dropEvent = event;
        // const clipboardData = dropEvent.dataTransfer;
        // clipboardData?.types
        //   .map(type => ({
        //     type: type,
        //     data: clipboardData?.getData(type)
        //   }))
        //   .forEach(result => {
        //   });
        return false;
      },
      handleDOMEvents: {
        mouseup: (view, event) => {
          setTimeout(() => {
            this.updateOverlay(view);
          }, 10);
          return false;
        },

        focus: (view: EditorView, event): boolean => {
          this.editorFocus = true
          this.dragFocus = true
          return false;
        },
        blur: (view: EditorView, event): boolean => {
          this.editorFocus = false
          return false;
        },

      }
    })
    this.listVersions()
    this.view.focus()

    if (this.markdownContent) {
      const parser = new MarkdownParser(schema, defaultMarkdownParser.tokenizer, customTokens)
      const tr = this.view.state.tr.insert(0, parser.parse(this.markdownContent as string))
      this.view.dispatch(tr)
    }

  }

  @HostListener('click', ['$event.target'])
  onClick(elem: any) {
    const className = elem.className;
    if (className === '' || className == 'editor-container' || className === 'ProseMirror ProseMirror-focused') {
      this.showComment = false;
    }
  }

  public get state(): EditorState {
    return this.view.state
  }

  getAllConnectedUsers() {
    const awareness = this.provider.awareness
    const fullNamePipe = new FullNamePipe()
    awareness.setLocalStateField('user', {
      ...this.currentUser,
      name: fullNamePipe.transform(this.currentUser)
    })
    awareness.on('change', () => {
      const allUsers = Array.from(
        new Map(
          Array.from(awareness.getStates().entries())
            .map(([_, user]) => [user['user']._id, user['user']])
        ).values()
      );
      this.contractService.connectedUsers$.next(allUsers)
    })

  }


  undo() {
    undo(this.view?.state, this.view?.dispatch)
  }

  redo() {
    redo(this.view?.state, this.view?.dispatch)
  }

  insertTable() {
    const { schema } = this.view?.state

    // Create a 3x3 table as an example
    const rowCount = 3
    const colCount = 3

    const cells: any[] = []
    for (let i = 0; i < colCount; i++) {
      cells.push(schema.nodes['table_cell'].createAndFill())
    }

    const rows = []
    for (let i = 0; i < rowCount; i++) {
      rows.push(schema.nodes['table_row'].create(null, cells))
    }

    const table = schema.nodes['table'].create(null, rows)

    if (this.view?.dispatch) {
      this.view?.dispatch(this.view?.state.tr.replaceSelectionWith(table))
    }

    return true
  }

  handleTOC(state: EditorState) {
    this.headings = []
    state.doc.descendants((node, pos) => {
      if (node.type.name === 'heading') {
        const domNode = this.view.domAtPos(pos + 1).node
        this.headings.push({ label: node.textContent?.trim(), level: node.attrs['level'], domNode: domNode })
      }
    })
  }


  showComment = false
  dragAndDrop() {

  }

  commentPlugin(): Plugin {
    return new Plugin({
      props: {
        handleDOMEvents: {
          click: (view, event) => {
            const target = event.target as HTMLElement;
            if (target && target.hasAttribute('data-comment-id')) {
              const id = target.getAttribute('data-comment-id')
              if (id) {
                this.onCommentIdChange(id);
              }
            }
            // Handle click events if needed
          },
        },
      },
      appendTransaction(transactions, oldState: EditorState, newState: EditorState): any {
        if (transactions.some(tr => tr.docChanged)) {
          // Capture and process comments
          const comments: any[] = [];
          newState.doc.descendants((node: any, pos: number) => {
            if (node.marks) {
              node.marks.forEach((mark: any) => {
                if (mark.type.name === 'comment') {
                  comments.push({ node, pos, mark });
                }
              })
            }
          });
        }

        // return transactions[0]
      },
    });
  }

  showCommentBox() {
    this.showComment = true;
    this.showComment = true
  }

  listVersions() {
    const versions = this.yDoc.getArray('versions')
    let data = versions.toArray().map((version: any, index) => ({
      id: index,
      timestamp: version.timestamp,
    }))
    this.versions.set(data)
  }


  createVersion() {
    const versions = this.yDoc.getArray('versions')
    versions.push([{
      timestamp: new Date().getTime(),
      state: Y.encodeStateAsUpdate(this.yDoc)
    }])
    this.listVersions()
  }


  insertHTML(html: string) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    const slice = ProseDOMParser.fromSchema(this.customSchema).parse(tempElement);
    const tr = this.view.state.tr.insert(0, slice.content);
    this.view.dispatch(tr);
  }

  private createOverlayPlugin(): Plugin {
    return new Plugin({
      view: () => ({
        update: (view, prevState) => {
          this.ngZone.run(() => {
            // this.updateOverlay(view);
          });
        }
      })
    });
  }


  private dragHandlePlugin() {
    const dragHandle = this.dragHand;
    return new Plugin({
      props: {
        decorations(state) {
          const { doc, selection } = state;
          const decorations: Decoration[] = [];
          doc.descendants((node, pos) => {
            if (node.isBlock) {
              // Add decoration with the drag handle for each block
              const deco = Decoration.widget(pos + 1, () => dragHandle.nativeElement, {
                side: -1
              });
              decorations.push(deco);
            }
          });

          return DecorationSet.create(doc, decorations);
        }
      }
    });
  }

  private updateOverlay(view: EditorView) {
    let state: EditorState = view.state;
    const { from, to } = state.selection;

    // If there's no selection, hide the overlay
    if (from === to) {
      this.showOverlay = false;
      return;
    }

    // Get the coordinates of the start and end of the selection
    const start = view.coordsAtPos(from);
    const end = view.coordsAtPos(to);

    // Get the bounding rectangles of the editor and overlay
    const editorRect = this.editorContainer?.nativeElement?.getBoundingClientRect();
    const overlayRect = this.overlayMenuRef?.nativeElement?.getBoundingClientRect();

    // Get the scroll offset of the editor container (if it is scrollable)
    const editorScrollY = this.editorContainer.nativeElement.scrollTop;
    const editorScrollX = this.editorContainer.nativeElement.scrollLeft;

    // Get the scroll offset of the page
    const pageScrollY = window.scrollY || document.documentElement.scrollTop;
    const pageScrollX = window.scrollX || document.documentElement.scrollLeft;

    // Calculate the vertical (top) position of the overlay
    // We account for the page scroll and the scroll inside the editor container
    this.overlayTop = start.top - editorRect.top + pageScrollY + editorScrollY - overlayRect?.height - 40;
    // Center the overlay horizontally with respect to the selection
    // Account for both the page scroll and editor container scroll
    const selectionMidpoint = (start.left + end.left) / 2;
    this.overlayLeft = selectionMidpoint - editorRect.left + pageScrollX + editorScrollX - (overlayRect?.width / 2);

    // Limit overlay positioning to the bounds of the editor
    if (this.overlayLeft < 0) {
      this.overlayLeft = 0; // Prevent overflow on the left
    } else if (this.overlayLeft + overlayRect?.width > editorRect.width) {
      this.overlayLeft = editorRect.width - overlayRect?.width; // Prevent overflow on the right
    }

    if (this.overlayLeft > 520) {
      this.overlayLeft = 520
    }
    // Set overlay visibility
    this.showOverlay = true;
  }

  ngOnDestroy() {
    if (this.view) {
      this.view.destroy();
    }

    this.yDoc.destroy();
    this.provider.destroy();
  }

  updateActiveMarks(state: EditorState) {
    let newActiveMarks = {
      strong: this.isMarkActive(state, state.schema.marks['strong']) as boolean,
      em: this.isMarkActive(state, state.schema.marks['em']) as boolean,
      underline: this.isMarkActive(state, state.schema.marks['underline']) as boolean,
      link: this.isMarkActive(state, state.schema.marks['link']) as boolean,
      strikethrough: this.isMarkActive(state, state.schema.marks['strikethrough']) as boolean,
      align_left: this.isAlignmentActive(state, 'left') as boolean,
      align_center: this.isAlignmentActive(state, 'center') as boolean,
      align_right: this.isAlignmentActive(state, 'right') as boolean,
      align_justify: this.isAlignmentActive(state, 'justify') as boolean,
      bullet_list: this.isNodeActive(state, state.schema.nodes['bullet_list']) as boolean,
      ordered_list: this.isNodeActive(state, state.schema.nodes['ordered_list']) as boolean,
      blockquote: this.isNodeActive(state, state.schema.nodes['blockquote']) as boolean,
    };

    const heading = this.isNodeActive(state, state.schema.nodes['heading']) as boolean;
    if (heading) {
      const level = appltHeadingStyle(state, this.view.dispatch, state.schema.nodes['heading'])
      if (level) {
        this.activeHeading = this.headingOptions.find(heading => heading.code === level) ?? { name: 'Normal Text', code: 'Paragraph' }
      }
    }
    else {
      this.activeHeading = { name: 'Normal Text', code: 'Paragraph' }
    }

    this.activeMarks.set(newActiveMarks);
  }

  isMarkActive(state: EditorState, type: MarkType) {
    const { from, $from, to, empty } = state.selection;
    if (empty) {
      return type.isInSet(state.storedMarks as Mark[] || $from.marks());
    }
    return state.doc.rangeHasMark(from, to, type);
  }

  isAlignmentActive(state: EditorState, alignment: string): boolean {
    const { from, to } = state.selection;
    let isActive = false;

    state.doc.nodesBetween(from, to, (node) => {
      if (node.type.name === 'paragraph' && node.attrs['align'] === alignment) {
        isActive = true;
      }
    });

    return isActive;
  }

  applyStyle(style: string) {
    if (!this.view) return;
    const { state, dispatch } = this.view;
    let markType !: MarkType;
    let nodeType !: NodeType;
    switch (style) {
      case MARKS.BOLD:
        markType = state.schema.marks[MARKS.BOLD];
        break;
      case MARKS.ITALIC:
        markType = state.schema.marks[MARKS.ITALIC];
        break;
      case MARKS.UNDERLINE:
        markType = state.schema.marks[MARKS.UNDERLINE];
        break;
      case MARKS.STRIKETHROUGH:
        markType = state.schema.marks[MARKS.STRIKETHROUGH];
        break
      case 'align-left':
        alignLeft(state, dispatch);
        break;
      case 'align-center':
        alignCenter(state, dispatch);
        break;
      case 'align-right':
        alignRight(state, dispatch);
        break;
      case 'align-justify':
        alignJustify(state, dispatch);
        break;
      case 'bullet-list':
        nodeType = state.schema.nodes['bullet_list'];
        toggleList(nodeType)(state, dispatch);
        break;
      case 'ordered-list':
        nodeType = state.schema.nodes['ordered_list'];
        toggleList(nodeType)(state, dispatch);
        break;
      case 'blockquote':
        nodeType = state.schema.nodes['blockquote'];
        toggleBlockquote(nodeType)(state, dispatch);
        break;
      case MARKS.LINK:
        markType = state.schema.marks[MARKS.LINK];
        break
      case 'page_break':
        const pageBreak = state.schema.nodes['hard_break'];
        addPageBreak(state, dispatch);
        this.view.focus();
        break;

    }

    if (markType) {
      toggleMark(markType)(state, dispatch);
      // this.overlayMenuRef.hide();
      // this.updateActiveMarks(this.view.state);
    }
  }

  applyHeading(level: number | string, id: string = 'a9hs9') {
    if (!this.view) return;

    if (level === 'Paragraph') {
      return this.applyParagraph()
    }

    const { state, dispatch } = this.view;
    const headingType = state.schema.nodes['heading'];
    if (!headingType) return;
    setBlockType(headingType, { level, id })(state, dispatch);
  }

  applyParagraph() {
    const { state, dispatch } = this.view;
    const paragraphType = state.schema.nodes['paragraph'];
    if (!paragraphType) return;
    const command = setBlockType(paragraphType);
    command(state, dispatch);
  }

  isNodeActive(state: EditorState, nodeType: NodeType): boolean {
    const { from, to } = state.selection;
    let isActive = false;
    state.doc.nodesBetween(from, to, (node) => {
      if (node.type === nodeType) {
        isActive = true;
        return false;
      }
      return true;
    });

    return isActive;
  }

  // Method to get editor content
  getContent() {
    if (!this.view) return '';
    return DOMSerializer.fromSchema(schema).serializeFragment(this.view.state.doc.content);
  }

  scrollTO(heading: any) {
    heading.domNode.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }

  approveDoc(action?: string) {
    this.confirmationService.confirm({
      header: 'Are you sure?',
      message: `Want to ${action} this document?`,
      accept: () => {
        if (action == 'approve') {
          this.approvalService.approveContract(this.contractId).subscribe(res => {
            if (res.success) {
              this.toast.success('Approved document successfully');
              this.onApprovalChange.emit(res.data)
            }
          })
        } else if (action == 'reject') {
          this.approvalService.rejectContract(this.contractId).subscribe(res => {
            if (res.success) {
              this.toast.success('Rejected document successfully ');
              this.needApproval = false
              this.onApprovalChange.emit(res.data)
            }
          });
        }
      },
      reject: () => {

      }
    });
  }

  reset() {
    this.approvalService.resetApprovalWorkflow(this.contractId).subscribe(res => {
      if (res.success) {
        this.toast.success('Reset Document Successfully ');
        this.needApproval = false
        this.onApprovalChange.emit(res.data)
      }
    });
  }

  viewMode() {
    //TODO : View mode
  }

  onSuggestionMenuOptionClick(key: string) {
    if (key === 'SUMMARIZE') {
      this.aiAssistant = {
        open: true,
        content: 'Ask AI Assistant to summarize the document',
        response: '',
        loading: false
      }
    }
    else if (key === 'CONTINUE') {
      this.aiAssistant = {
        open: true,
        content: 'Ask AI Assistant to continue the document',
        response: '',
        loading: false
      }
    }

    let state: EditorState = this.view.state;
    const { from, to } = state.selection;
    const selectedText = this.view.state.doc.textBetween(from, to, ' ');
    let payload = {
      prompt: `${key} the text`,
      prefix: selectedText ?? '',
      suffix: ''
    }
    this.getAISuggestion(payload);
  }


  //#region AI Assistant


  openAIAssistant() {
    this.aiAssistant.open = true;
    this.aiAssistant.response = '';
    this.aiAssistant.content = '';
  }

  getContextAroundCursor(totalWordCount: number = 200) {
    const { state } = this.view
    const { $from } = state.selection as TextSelection

    let before = ''
    let after = ''

    // Get text before cursor
    let pos = $from.pos
    let wordsBefore = 0
    while (pos > 0 && wordsBefore < totalWordCount) {
      const textBefore = state.doc.textBetween(Math.max(0, pos - 500), pos)
      const words = textBefore.split(/\s+/)
      before = words.slice(-totalWordCount).join(' ') + ' ' + before
      wordsBefore += words.length
      pos -= 500
    }

    // Get text after cursor
    pos = $from.pos
    let wordsAfter = 0
    while (pos < state.doc.content.size && wordsAfter < totalWordCount) {
      const textAfter = state.doc.textBetween(pos, Math.min(state.doc.content.size, pos + 500))
      const words = textAfter.split(/\s+/)
      after += ' ' + words.slice(0, totalWordCount - wordsAfter).join(' ')
      wordsAfter += words.length
      pos += 500
    }

    // Adjust word count distribution
    const totalWordsCollected = wordsBefore + wordsAfter
    if (totalWordsCollected > totalWordCount) {
      if (wordsBefore > wordsAfter) {
        const wordsToKeepBefore = totalWordCount - wordsAfter
        before = before.split(/\s+/).slice(-wordsToKeepBefore).join(' ')
      } else {
        const wordsToKeepAfter = totalWordCount - wordsBefore
        after = after.split(/\s+/).slice(0, wordsToKeepAfter).join(' ')
      }
    }

    return { before: before.trim(), after: after.trim() }
  }

  callAIAssistant() {
    this.aiAssistant.response = ''
    const { before, after } = this.getContextAroundCursor()
    const payload = {
      prefix: before ?? '',
      prompt: this.aiAssistant.content,
      suffix: after ?? '',
    }
    this.getAISuggestion(payload)
  }

  getAISuggestion(payload: { prefix?: string, prompt: string, suffix?: string, content?: string }) {
    this.aiAssistant.loading = true;
    this.contractEditorService.getAISuggestions(payload).subscribe({
      next: (res: any) => {
        const { status, content } = res;
        if (status.includes('[DONE]')) {
          this.aiAssistant.response = content.message;
          this.aiAssistant.loading = false;
        } else if (status.includes('[ERR]')) {
          this.aiAssistant.loading = false;
          this.toast.error('Something went wrong, please try again');
        } else {
          this.aiAssistant.response = content.message;
          this.aiAssistant.loading = true;
        }
      },
      error: (err) => {
        this.aiAssistant.loading = false;
        this.toast.error('Something went wrong, please try again');
      },
    });
  }

  insertResponse(pos: string) {
    const { state, dispatch } = this.view;
    const { tr } = state;
    if (pos === this.RESPONSE_POSITION['BESIDE']) {
      tr.insertText(this.aiAssistant.response, state.selection.from);
    } else if (pos === this.RESPONSE_POSITION['BELOW']) {
      const schema = state.schema;
      const paragraph = schema.nodes['paragraph'].create(null, schema.text(this.aiAssistant.response));
      tr.insert(state.selection.$from.after(), paragraph);
    }
    dispatch(tr);
    this.resetAiAssistant();
  }

  copyToClipboard() {
    const text = this.aiAssistant.response;
    navigator.clipboard.writeText(text);
    this.toast.success('Copied to clipboard');
  }

  resetAiAssistant() {
    this.aiAssistant = {
      open: false,
      content: '',
      response: '',
      loading: false
    }
  }
  //#endregion

  //#region reply / comments
  addComment(comment: Partial<IContractComment>) {
    const { state, dispatch } = this.view;
    const { schema, tr } = state;

    let { from, to } = state.selection;

    // Use the current user's color code
    const userColor = this.currentUser?.colorCode;  // Assuming currentUser is accessible

    // Create the comment mark with the color attribute
    const mark = schema.marks['comment'].create({
      id: comment._id,
      color: userColor,  // Store the user's color in the mark
    });

    tr.addMark(from, to, mark);
    dispatch(tr);
  }

  highlightComments() {
    const editorContainer = this.editorContainer.nativeElement;
    const isHidden = editorContainer.classList.contains('hide-comments');

    if (isHidden) {
      editorContainer.classList.remove('hide-comments');
    } else {
      editorContainer.classList.add('hide-comments');
    }

    // Force a re-render of the editor view
    this.commentService.setCommentId('')
    this.commentService.toggleReplyDialogVisibility(null);
    this.commentService.toggleIsHighlight()
    this.view.updateState(this.view.state);
    if (this.commentService.isHighlight) {
      this.commentService.tabActiveIndex = 4
    }
  }

  handleCommentId(comment: Partial<IContractComment>) {
    this.showComment = false;
    this.onCommentIdChange(comment._id ?? '')
    this.addComment(comment)
  }

  onCommentIdChange(id: string) {
    this.commentService.setCommentId(id)
    this.commentService.toggleReplyDialogVisibility(true);
  }


  toggleApprovalStatusDialog() {
    this.approvalStatusVisible = !this.approvalStatusVisible;
  }

  //endregion
}
