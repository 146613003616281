import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { AuthenticationService } from '../../../../../@core/services/authentication.service';
import { IUser } from '../../../../../@core/models/model';
import { UserAvatarPipe } from '../../../../pipes/user-avatar-pipe';
import { CommentService } from '../../../../../services/comment.service';
import { IContractComment } from '../../model';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-add-comment',
  standalone: true,
  imports: [AvatarModule, UserAvatarPipe, InputTextModule, InputGroupModule, InputGroupAddonModule],
  templateUrl: './add-comment.component.html',
  styleUrl: './add-comment.component.scss'
})
export class AddCommentComponent {
  currentUser!: IUser | null | undefined;

  @Input() contractId!: string;
  @Output() id = new EventEmitter<Partial<IContractComment>>();

  constructor(
    private authService: AuthenticationService,
    private commentService: CommentService
  ) {
    this.authService.currentUser.subscribe(res => this.currentUser = res)
  }

  onEnter(event: Event) {
    if (!this.contractId) return;
    event.preventDefault();
    if (event.target instanceof HTMLInputElement) {
      const enteredComment = event.target.value;
      if (enteredComment) {
        this.commentService.addComment({ content: enteredComment, contract: this.contractId }).subscribe(res => {
          if (res && res.success) {
            this.id.emit(res.data);
            this.commentService.triggerUpdate();
          }
        })
      }
    }
  }
}
