import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[clickOutside]'
})
export class ClickOutsideDirective {
    @Output()
    clickOutside: EventEmitter<Event> = new EventEmitter<Event>();

    constructor(private elemRef: ElementRef) { }

    @HostListener('document:click', ['$event'])
    onClick(event: Event) {
        if (!this.elemRef.nativeElement.contains(event.target)) {
            // Clicked Outside
            this.clickOutside.emit(event);
        }
    }
}