export interface ChatMessage {
    sender: SENDER_TYPE;
    isLoading: boolean;
    timestamp: string;
    content: string;
    contentType: CONTENT_TYPE;
}

export enum CONTENT_TYPE {
    TEXT = 'TEXT',
    IMAGE = 'IMAGE',
    TICKET = 'TICKET'
}

export enum SENDER_TYPE {
    USER = 'USER',
    AI = 'AI'
}