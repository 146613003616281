<div class="flex justify-center items-center h-screen">
    <div>
        <div class="text-4xl font-medium text-gray-700">You are not authorized!</div>
        <div class="text-xl mt-4">Please verify your email to continue.</div>
        <div class="flex items-center space-x-4 mt-4">
            <p-button styleClass="bg-arena-orange p-3 rounded-xl text-white" [label]="sendMailLabel"
                (onClick)="sendVerificationEmail()" [disabled]="mailSent">
            </p-button>
            <div *ngIf="mailSent" class="text-xl flex animate-pulse align-baseline items-center">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-green-600">Mail sent</span>
            </div>
        </div>
        @if(mailSent){
        <div class="flex flex-col items-start gap-4 mt-4 animate-fade-in-up">
            <div>Not received mail? <span class="text-arena-orange cursor-pointer"
                    (click)="sendVerificationEmail()">Resend</span>
            </div>
            <p-divider class="w-full h-0.5 bg-slate-300" type="solid" />
            <span class="text-lg font-medium">Enter the OTP sent to your email</span>
            <p-inputOtp [integerOnly]="true" [length]="6" [(ngModel)]="otp" />
            <p-button styleClass="bg-arena-orange p-3 rounded-xl text-white w-40" label="Verify" (onClick)="verifyOTP()"
                [disabled]="otp.length !== 6">
            </p-button>
            <div *ngIf="otpVerified" class="text-xl flex animate-fade-in-up align-baseline items-center">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-green-600">Otp verified</span>
            </div>
        </div>
        }
    </div>
</div>