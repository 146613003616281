<p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message>
        <div class="flex flex-col align-items-center p-5 surface-overlay border-round w-[30rem]">
            <span class="font-bold text-2xl block mb-2 mt-4">
                {{ message.header }}
            </span>

            <p class="mb-0" [innerHTML]="message.message"></p>

            <div class="flex justify-end items-end float-left gap-2 mt-4">
                <p-button label="Yes" (onClick)="cd.accept()"
                    styleClass="bg-white rounded-xl border border-green-500 text-green-500 hover:bg-green-100 hover:text-green-500 px-4 py-2 "
                    icon="pi pi-check"></p-button>

                <p-button label="No" (onClick)="cd.hide()"
                    styleClass="bg-white rounded-xl border border-red-500 text-red-500 hover:bg-red-100 hover:text-red-500 px-4 py-2 "
                    icon="pi pi-times"></p-button>
            </div>
        </div>
    </ng-template>
</p-confirmDialog>

@if(fetchingRole){
<div class="flex justify-center items-center  h-screen">
    <div class="loader"></div>
</div>
}@else {
@if(permissions.restricted){
<!-- Cannot view contract -->
<div>
    <div class="flex flex-col items-center justify-center h-screen">
        <div class="flex flex-col items-center">
            <img src="/images/access_denied.png" alt="404">
            <h1 class="text-2xl font-semibold text-center">You do not have permission to view this document</h1>
            <p class="text-gray-500 text-center">Please contact the owner of the document for access</p>
        </div>
    </div>
</div>
}@else {
@if(contract){
<app-download-doc [contract]="contract" [visibility]="openDownloadContract"
    (visibilityChange)="openDownloadContract=$event"></app-download-doc>
<section class="aspect-auto h-screen flex flex-col overflow-x-hidden">
    <div class="flex bg-white justify-between items-center p-2 pr-5 drop-shadow relative z-10">
        <div class="flex items-center" #left>
            @if(!isAnonymousUser){
            <i class="pi pi-angle-left cursor-pointer m-2 text-primary" (click)="goBack()"></i>
            }
            <div>
                <div class="flex items-center">
                    <span #editableContent scrollReset
                        class="text-xl mx-3 max-w-[400px] whitespace-nowrap overflow-x-auto inline-block no-scrollbar"
                        [attr.contenteditable]="isOwner || (isSameOrganization && permissions.canEdit)"
                        (keydown)="$event.key === 'Enter' ? blurSpan($event) : null"
                        (blur)="updateContractName($event)">
                        {{contract.name}}
                    </span>
                    <app-badge [contract]="contract"></app-badge>
                </div>
            </div>
        </div>

        <div #right class="flex gap-3 items-center">
            <app-badge [permission]="currentRole"></app-badge>
            <i class="pi pi-refresh last-edit" [ngClass]="{'pi-spin': loading}" style="cursor: pointer;"
                (click)="getLastModified()">
            </i>
            <p class="last-edit">Last Edit on {{contract.updatedAt| date:'mediumDate'}} at {{contract.updatedAt |
                date:'shortTime'}}</p>
            @if (connectedUsers$ && (connectedUsers$ | async)!.length) {
            <p-avatarGroup styleClass="rounded-full p-2 pb-0">
                @for (user of (connectedUsers$ | async)?.slice(0,4); track $index) {
                <p-avatar [image]="user | userAvatar" shape='circle' [pTooltip]="user | fullName"
                    tooltipPosition="bottom"></p-avatar>
                }
                @if ((connectedUsers$ | async)!.length > 4) {
                <p-avatar [label]="'+' + (connectedUsers$ | async)!.length" shape="circle"
                    [style]="{ 'background-color': '#FCFCFD', color: '#1C1C1C' }" />
                }
            </p-avatarGroup>
            }
            @if(this.isOwner || (isSameOrganization && this.permissions.canEdit)){
            <app-share-doc [contract]="contract" />
            }
            @if(this.contract.status != CONTRACT_STATUS.SIGNATURE_COMPLETED){
            <!-- TODO - revert the disable -->
            <p-button *ngIf="isOwner" [ngClass]="labelText === 'Publish' ? 'publish' : 'approval'"
                styleClass="border-2 py-1 px-2" [rounded]="true" [disabled]="actionButton" (onClick)="action()">
                <img [src]="getIconPath()" class="mr-1" alt="Icon" style="width: 20px; height: 20px;" />
                {{labelText}}
            </p-button>
            }
            <p-tieredMenu #optionsMenu styleClass="p-3 border rounded-lg" [style]="{'width':'12rem'}" [model]="options"
                [popup]="true" appendTo="body" />
            <p-button (onClick)="optionsMenu.toggle($event)" class="border rounded-lg py-[6px]" icon="pi pi-ellipsis-v"
                [text]="true" />
        </div>
    </div>

    <div class="flex-1 grid grid-cols-12">
        <div class="overflow-hidden" [ngClass]="isSameOrganization ? 'col-span-9' : 'col-span-12'">
            <app-contract-editor [contractId]="contract._id" class="h-full block overflow-hidden"
                [permissions]="permissions" [isOwner]="isOwner" [approvalConf]="approvalConf"
                (onApprovalChange)="onApprovalChange($event)" [markdownContent]="markdownContent" [comments]="comments"
                #contractEditor></app-contract-editor>
        </div>
        <div *ngIf="isSameOrganization" class="col-span-3 bg-white border-l-2 p-2 overflow-auto comments">
            <p-tabView [(activeIndex)]="commentService.tabActiveIndex" class="inherit"
                styleClass="h-full  overflow-hidden">
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <div class="flex flex-row gap-2 items-center">
                            <span class="pi pi-chart-line"></span>
                            <span class="text-lg font-semibold">Insights</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="h-full">
                            @if (contractId) {
                            <app-contract-insights class="block h-full w-full"
                                [contractId]="contractId"></app-contract-insights>
                            }
                        </div>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <div class="flex flex-row gap-2 justify-between items-center">
                            <span class="pi pi-check-square"></span>
                            <span class="text-lg font-semibold">Approvers</span>
                        </div>
                    </ng-template>
                    <div class="flex flex-col gap-4 px-2 justify-evenly mt-4">
                        <div class="flex flex-row justify-between">
                            <div class="flex items-center">
                                <span class="text-lg font-semibold">Approvers</span>
                                @if(approvalConf && (approvalConf.status == CONTRACT_APPROVAL_STATUS.SKIPPED)){
                                <span
                                    class="text-xs font-semibold text-green-500 ml-2 bg-green-100 rounded-md px-2 py-0.5">Skipped</span>
                                }
                            </div>
                            <p-button *ngIf="permissions.canEdit" styleClass="border-2 py-1 px-2 rounded-xl"
                                label="Reset" icon="pi pi-refresh"
                                [disabled]="!(approvalConf && (approvalConf.status == CONTRACT_APPROVAL_STATUS.WAITING))"
                                (onClick)="resetApprovalFlow()" />
                        </div>

                        <div class=" overflow-auto max-h-[35rem] min-w-80 overflow-x-hidden no-scrollbar">
                            @if(approvalConf && approvalConf.approvers.length > 0){
                            <app-approval-workflow [approvalConf]="approvalConf"
                                [canEdit]="!permissions.canEdit && !isOwner" (deleteApproverEvent)="approvalChange()"
                                [flowType]="flowType.APPROVAL"></app-approval-workflow>
                            }
                            @else {
                            <div class="flex justify-center items-center">
                                <span class="text-gray-400">No approvers added</span>
                            </div>
                            }
                        </div>
                        <div *ngIf="isOwner" class="flex px-2 flex-row justify-between items-center">
                            <p-button label="Add Approver" icon="pi pi-plus"
                                [disabled]="!(contract.status == CONTRACT_STATUS.READY_FOR_APPROVAL || contract.status == CONTRACT_STATUS.DRAFT)"
                                styleClass="w-100 bg-white p-1 px-3 rounded-xl border-2" [severity]="'primary'"
                                [rounded]="true" (onClick)="toggleApproveDialog()"></p-button>
                            <p-button styleClass="w-100 bg-white p-1 px-3 rounded-xl border-2"
                                [label]="approvalConf.status == CONTRACT_APPROVAL_STATUS.SKIPPED ? 'Skipped' : 'Skip Approval'"
                                icon="pi pi-forward" [rounded]="true" (onClick)="skipApprovalFlow()"
                                [disabled]="!(contract.status == CONTRACT_STATUS.READY_FOR_APPROVAL || contract.status == CONTRACT_STATUS.PENDING_APPROVAL || contract.status == CONTRACT_STATUS.DRAFT)"></p-button>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <div class="flex flex-row gap-2 justify-between items-center">
                            <span class="pi pi-check-square"></span>
                            <span class="text-lg font-semibold">Signatories</span>
                        </div>
                    </ng-template>
                    <div class="flex flex-col gap-4 px-2 justify-evenly mt-4">
                        <div class="flex flex-row justify-between">
                            <div class="flex items-center">
                                <span class="text-lg font-semibold">Signatories</span>
                            </div>

                            <div class="flex">
                                @if(signatoryType == CONTRACT_SIGNATORY_TYPE.ADOBE_SIGN){
                                <div class="flex items-center mr-2" [pTooltip]="tooltipContentAdobe"
                                    tooltipPosition="top">
                                    <img src="/images/logos/adobe.png" height="24" width="24" alt="icon">
                                </div>
                                }@else if(signatoryType == CONTRACT_SIGNATORY_TYPE.DOCU_SIGN){
                                <div class="flex items-center mr-2" [pTooltip]="tooltipContentDocu"
                                    tooltipPosition="top">
                                    <img src="/images/logos/docusign.png" height="24" width="24" alt="icon">
                                </div>
                                }
                                @if(this.contract.status != CONTRACT_STATUS.SIGNATURE_COMPLETED){
                                <p-button *ngIf="permissions.canEdit" styleClass="border-2 py-1 px-2 rounded-xl"
                                    label="Reset" icon="pi pi-refresh"
                                    [disabled]="this.contract.status != CONTRACT_STATUS.SIGNATURE_IN_PROGRESS"
                                    (onClick)="resetSignatoryFlow()" />
                                }
                            </div>
                        </div>
                        <div class="mt-2">
                            @if(signatoryConf && signatoryConf.signatories.length > 0){
                            <app-approval-workflow [signatories]="signatories" [flowType]="flowType.SIGNATORY"
                                (deleteSignatoryEvent)="signatoryChange($event)"
                                (downloadSignatoryEvent)="downloadSignedDocument($event)"
                                [contractStatus]="contract.status"></app-approval-workflow>
                            }
                            @else {
                            <div class="flex justify-center items-center">
                                <span class="text-gray-400">No Signatories added</span>
                            </div>
                            }
                        </div>

                        <div *ngIf="permissions.canEdit" class="flex  flex-row justify-center items-center">
                            <p-button label="Add Signatories" icon="pi pi-plus"
                                styleClass="w-100 bg-white p-1 px-3 rounded-xl border-2" [severity]="'primary'"
                                [rounded]="true" [disabled]="disableAddSignatories"
                                (onClick)="signatoriesMenu.toggle($event)"></p-button>
                        </div>
                        <p-overlayPanel #signatoriesMenu styleClass="shadow-lg border border-gray-300 p-2 rounded-xl "
                            appendTo="body" [style]="{'width':'300px' ,'height' : '300px'}">
                            <ng-template pTemplate="start">
                                <div class="flex flex-col gap-2 ">
                                    <span class="font-bold text-lg">Select Signatories</span>
                                    <input type="text" pInputText [(ngModel)]="signatoryEmail"
                                        (ngModelChange)="updateName()"
                                        class="w-full h-10 rounded-lg border p-1 border-gray-300" placeholder="Email" />
                                    <input type="text" pInputText [(ngModel)]="signatoryName"
                                        class="w-full h-10 rounded-lg border p-1 border-gray-300" placeholder="Name" />
                                    <p-button styleClass="bg-arena-orange p-3 rounded-xl w-full text-white"
                                        (onClick)="addSignatory(); signatoriesMenu.hide()" label="Add Signatories"
                                        icon="pi pi-plus" [disabled]="!signatoryEmailValidation"></p-button>
                                </div>
                            </ng-template>
                        </p-overlayPanel>
                    </div>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <div class="flex flex-row gap-2 justify-between items-center">
                            <span class="pi pi-inbox"></span>
                            <span class="text-lg font-semibold">Chat</span>
                        </div>
                    </ng-template>
                    <div class="min-w-[300px] 2xl:min-w-[400px] chat-container border border-gray-200 rounded-lg">
                        <app-chat></app-chat>
                    </div>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <div class="flex flex-row gap-2 justify-between items-center">
                            <span class="pi pi-inbox"></span>
                            <span class="text-lg font-semibold">Comments</span>
                        </div>
                    </ng-template>
                    <div class="min-w-[300px] 2xl:min-w-[400px] comment-container border border-gray-200 rounded-lg">
                        <app-all-comments [contractId]="contractId" [comments]="comments" />
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</section>
}
}
}

<ng-template #tooltipContentAdobe>
    <div class="flex align-items-center">
        <span> Adobe sign </span>
    </div>
</ng-template>

<ng-template #tooltipContentDocu>
    <div class="flex align-items-center">
        <span> Docusign </span>
    </div>
</ng-template>

<p-dialog [visible]="approversDialog" styleClass="shadow-lg border border-gray-300 p-2 rounded-xl " [modal]="true"
    [closeOnEscape]="true" [closable]="false" [style]="{'width':'600px' ,'height' : '500px'}"
    (onHide)="onCloseApproverDialog()">
    <ng-template pTemplate="header">
        <div class="flex-1 flex justify-between">
            <div class="font-bold text-md">Add User to the approval level</div>
            <div class="flex items-center">
                <i class="pi pi-times text-lg cursor-pointer" (click)="toggleApproveDialog()"></i>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="flex flex-col flex-1 h-full">
            <p-iconField iconPosition="left">
                <div class="flex w-full items-center space-x-2">
                    <p-autoComplete [suggestions]="autoCompleteUsers" (completeMethod)="autoCompleteUsersMethod($event)"
                        [(ngModel)]="autoCompleteValue" field="fullName" [dropdown]="false" [showEmptyMessage]="false"
                        placeholder="Add approvers by name or email" [style]="{'width':'100%','height': '100%'}"
                        [inputStyle]="{'width':'100%', 'height': '100%','padding-left' : '0.5rem'}"
                        class="flex-grow rounded-lg border border-gray-300 bg-red-500 outline-orange-400 h-10" size="40"
                        (onSelect)="addInternalApprover($event)">
                        <ng-template let-user pTemplate="item">
                            <div class="custom-item flex items-center p-2">
                                <div class="user-avatar">
                                    <img [src]="user | userAvatar" class="w-8 h-8 rounded-full" />
                                </div>
                                <div class="ml-3">
                                    <span class="block font-semibold">{{ user.fullName }}</span>
                                    <span class="block text-sm text-gray-500">{{ user.email }}</span>
                                </div>
                            </div>
                        </ng-template>
                    </p-autoComplete>
                    <p-button styleClass="bg-arena-orange rounded-lg h-10 text-white px-4"
                        (onClick)="addExternalApprover()" label="Add" [disabled]="validApproverEmail()"></p-button>
                </div>
            </p-iconField>



            <div class="flex flex-col mt-2 flex-1 h-full overflow-y-auto no-scrollbar overflow-hidden">
                @for (item of approvers; track $index) {
                <div pDraggable pDroppable
                    class="flex items-center space-x-2 text-black p-2 rounded-xl transition-all ease-in-out cursor-move hover:border hover:p-4"
                    (onDragStart)="dragStart($index)" (onDrop)="drop($index)">
                    <div class="relative inline-block">
                        <img [src]="item | userAvatar" alt="User Avatar" class="w-10 h-10 rounded-full" />
                        @if(item.type == 'EXTERNAL'){
                        <span class="absolute bottom-0 right-0 w-6 h-6 ">
                            <img [src]="'/images/external-badge.png'" alt="User Avatar" class="rounded-full"
                                [pTooltip]="'External user'" />
                        </span>
                        }
                    </div>
                    <div class="flex flex-col text-sm">
                        <span>{{item.name}}</span>
                        <span class="text-gray-500">{{item.email}}</span>
                    </div>
                    <div class="flex-1 flex justify-end items-center ">
                        <div class="bg-sky-100 border-sky-200 border px-3 rounded-md text-sky-600">Level {{$index+1}}
                        </div>
                        <div class=" h-full">
                            <i class="pi pi-trash border py-3 px-2.5 rounded-xl text-red-600 ml-2"
                                (click)="removeApprover($index)"></i>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="flex justify-end">
            <p-button styleClass="border px-3 py-2 rounded-lg w-full text-black" label="Cancel"
                (onClick)="approversDialog=false"></p-button>
            <p-button styleClass="ml-2 bg-arena-orange px-3 py-2 rounded-lg w-full text-white"
                (onClick)="saveApprovalWorkflow()" label="Save"></p-button>
        </div>
    </ng-template>
</p-dialog>