<p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message>
        <div class="flex flex-row justify-between items-center px-4 py-2">
            <span class="text-lg font-medium text-gray-500 ">Confirmation</span>
            <p-button (onClick)="cd.hide()" styleClass="mr-5"><i class="pi pi-times"></i></p-button>
        </div>
        <div class="flex flex-col align-items-center p-5 surface-overlay border-round w-[30rem]">
            <span class="font-bold text-2xl block mb-2">
                {{ message.header }}
            </span>

            <p class="mb-0">{{ message.message }}</p>

            <div class="flex justify-end items-end float-left gap-2 mt-4">
                <p-button label="No" (onClick)="cd.reject()"
                    styleClass="bg-white rounded-xl border border-red-500 text-red-500 hover:bg-red-100 hover:text-red-500 px-4 py-2 "
                    icon="pi pi-times"></p-button>
                <p-button label="Yes" (onClick)="cd.accept()"
                    styleClass="bg-white rounded-xl border border-green-500 text-green-500 hover:bg-green-100 hover:text-green-500 px-4 py-2 "
                    icon="pi pi-check"></p-button>

            </div>
        </div>
    </ng-template>
</p-confirmDialog>



<div class="h-full flex flex-col contract-editor">

    <div class="custom-menu flex justify-between items-center p-3 gap-3"
        [ngClass]="permissions.canEdit ? '' : 'pointer-events-none'">
        <div class="flex flex-row gap-3">
            <div>
                <p-button styleClass="custom-menu-item" (onClick)="toggleTOC = !toggleTOC" pTooltip="Table of Contents"
                    tooltipPosition="bottom">
                    <span class="m-1 material-icons">list</span>
                </p-button>
            </div>
            <div class="section flex gap-1">
                <p-button styleClass="custom-menu-item" (onClick)="undo()" pTooltip="Undo" tooltipPosition="bottom">
                    <span class="m-1 material-icons">undo</span>
                </p-button>
                <p-button styleClass="custom-menu-item" (onClick)="redo()" pTooltip="Redo" tooltipPosition="bottom">
                    <span class="m-1 material-icons">redo</span>
                </p-button>
            </div>
            <div class="section flex gap-1">
                <p-button (click)="applyStyle(MARKS.BOLD)" pTooltip="Bold" tooltipPosition="bottom"
                    [styleClass]="activeMarks()[MARKS.BOLD] ? 'active-custom-menu-item' : 'custom-menu-item'">
                    <span class="m-1 material-icons">format_bold</span>
                </p-button>
                <p-button (click)="applyStyle(MARKS.ITALIC)" pTooltip="Italic" tooltipPosition="bottom"
                    [styleClass]="activeMarks()[MARKS.ITALIC] ? 'active-custom-menu-item' : 'custom-menu-item'">
                    <span class="m-1 material-icons">format_italic</span>
                </p-button>
                <p-button (click)="applyStyle(MARKS.STRIKETHROUGH)" pTooltip="Strikethrough" tooltipPosition="bottom"
                    [styleClass]="activeMarks()[MARKS.STRIKETHROUGH] ? 'active-custom-menu-item' : 'custom-menu-item'">
                    <span class="m-1 material-icons">strikethrough_s</span>
                </p-button>
                <p-button (click)="applyStyle(MARKS.UNDERLINE)" pTooltip="Underline" tooltipPosition="bottom"
                    [styleClass]="activeMarks()[MARKS.UNDERLINE] ? 'active-custom-menu-item' : 'custom-menu-item'">
                    <img class="center m-1" src="icons/underline.svg" />
                </p-button>
            </div>
            <div class="section flex gap-1">
                <p-dropdown [options]="headingOptions" [(ngModel)]="activeHeading"
                    (onChange)="applyHeading($event.value.code)" optionLabel="name" />
            </div>
            <div class="section flex gap-1">
                <p-button (onClick)="applyStyle('align-left')" pTooltip="Align Left" tooltipPosition="bottom"
                    [styleClass]="activeMarks()['align_left'] ? 'active-custom-menu-item' : 'custom-menu-item'">
                    <span class="m-1 material-icons">format_align_left</span>
                </p-button>
                <p-button (onClick)="applyStyle('align-center')" pTooltip="Align Center" tooltipPosition="bottom"
                    [styleClass]="activeMarks()['align_center'] ? 'active-custom-menu-item' : 'custom-menu-item'">
                    <span class="m-1 material-icons">format_align_center</span>
                </p-button>
                <p-button (onClick)="applyStyle('align-right')" pTooltip="Align Right" tooltipPosition="bottom"
                    [styleClass]="activeMarks()['align_right'] ? 'active-custom-menu-item' : 'custom-menu-item'">
                    <span class="m-1 material-icons">format_align_right</span>
                </p-button>
                <p-button (onClick)="applyStyle('align-justify')" pTooltip="Justify" tooltipPosition="bottom"
                    [styleClass]="activeMarks()['align_justify'] ? 'active-custom-menu-item' : 'custom-menu-item'">
                    <span class="m-1 material-icons">format_align_justify</span>
                </p-button>
            </div>
            <div class="section flex gap-2">
                <p-button styleClass="custom-menu-item" (onClick)="applyStyle('bullet-list')" pTooltip="Bullet list"
                    tooltipPosition="bottom"
                    [styleClass]="activeMarks()['bullet_list'] ? 'active-custom-menu-item' : 'custom-menu-item'">
                    <span class="m-1 material-icons">format_list_bulleted</span>
                </p-button>
                <p-button styleClass="custom-menu-item" (onClick)="applyStyle('ordered-list')" pTooltip="Numbered List"
                    tooltipPosition="bottom"
                    [styleClass]="activeMarks()['ordered_list'] ? 'active-custom-menu-item' : 'custom-menu-item'">
                    <span class="m-1 material-icons">format_list_numbered</span>
                </p-button>
                <p-button styleClass="custom-menu-item" (onClick)="applyStyle('blockquote')" pTooltip="Blockquote"
                    tooltipPosition="bottom"
                    [styleClass]="activeMarks()['blockquote'] ? 'active-custom-menu-item' : 'custom-menu-item'">
                    <span class="m-1 material-icons">format_quote</span>
                </p-button>
                <p-button (click)="applyStyle(MARKS.LINK)" pTooltip="Link" tooltipPosition="bottom"
                    [styleClass]="activeMarks()[MARKS.LINK] ? 'active-custom-menu-item' : 'custom-menu-item'">
                    <span class="m-1 material-icons">link</span>
                </p-button>
            </div>
            <div class="section flex gap-2">
                <p-button styleClass="custom-menu-item" (onClick)="applyStyle('page_break')" pTooltip="Page Break"
                    tooltipPosition="bottom"
                    [styleClass]="activeMarks()['page_break'] ? 'active-custom-menu-item' : 'custom-menu-item'">
                    <span class="m-1 pi pi-minus"></span>
                </p-button>
            </div>
            <div class="section flex gap-1">
                <p-menubar [model]="tableMenu" />
            </div>
        </div>
        <div>
            <p-button styleClass="custom-menu-item" (onClick)="highlightComments()" pTooltip="Highlight Comments"
                tooltipPosition="bottom"
                [styleClass]="commentService.isHighlight ? 'active-custom-menu-item' : 'custom-menu-item'">
                <span class="m-1 material-icons">highlight</span>
            </p-button>
        </div>
    </div>


    <div class="flex-1 flex overflow-hidden">
        <!-- TOC Section -->
        <div class="transition-all duration-200 ease-in-out flex-shrink-0" [ngClass]="{
               'w-1/5 translate-x-0  opacity-100': !toggleTOC,
               'w-0 -translate-x-full opacity-0': toggleTOC
             }">
            <div class="heading-list-container w-full  overflow-y-auto" [ngClass]="{
                'invisible': toggleTOC,
                'visible': !toggleTOC
              }">
                <p class="font-bold text-gray-400 p-4">TABLE OF CONTENTS</p>
                @if (headings.length <= 0) { <div class="text-gray-300 font-sans p-4"> Headings that you add to the
                    document
                    will appear here
            </div>
            }

            <ng-container *ngFor="let heading of headings; let i = index">
                <p *ngIf="heading.level === 1"
                    class="heading text-truncate text-base cursor-pointer hover:bg-gray-100 p-2"
                    (click)="scrollTO(heading)">
                    {{heading.label}}
                </p>
                <p *ngIf="heading.level !== 1"
                    class="subheading text-truncate cursor-pointer text-sm hover:bg-gray-100 p-2 pl-4"
                    (click)="scrollTO(heading)">
                    - {{heading.label}}
                </p>
            </ng-container>
        </div>
    </div>

    <!-- Editor Section -->
    <div class="flex-grow transition-all duration-200 ease-in-out p-3 h-100 flex flex-col justify-center items-center ">
        <!-- Only visible when current user is present in approval workflow and workflow has started  -->
        <div *ngIf="currentApprover && (_approvalConf.status!='PENDING')"
            class="flex flex-row absolute items-end justify-end left-5 bottom-5 z-50">
            <div class="rounded-full bg-green-100 h-fit cursor-pointer " (click)="toggleApprovalStatusDialog()"
                [style]="{'box-shadow': '0px 0px 8px #ACE1AF'} " (clickOutside)="approvalStatusVisible=false">
                <div>
                    <i *ngIf="!approvalStatusVisible"
                        class="pi pi-check-square animate-pop p-4 text-xl text-green-600"></i>
                    <i *ngIf="approvalStatusVisible" class="pi pi-times animate-pop p-4 text-xl text-green-600"></i>
                </div>
            </div>
            <div *ngIf="approvalStatusVisible"
                class=" animate-pop border p-2 border-gray-300 rounded-lg bg-white shadow-lg ml-4"
                [style]="{'box-shadow': '0px 0px 8px #eeee'}">
                @if(currentApprover?.status == APPROVAL_STATUS.PENDING){
                <div class="flex flex-col gap-2">
                    <div class="flex flex-col items-center rounded-md p-2">
                        <div class="flex flex-row w-full mr-4">
                            <div class="text-xl px-2">
                                <span class="font-normal">Waiting for your approval</span>
                            </div>
                        </div>
                        <div class="flex w-full mt-2">
                            <div class="flex">
                                <div class="cursor-pointer relative inline-flex items-center justify-center overflow-hidden font-medium text-green-500 transition duration-300 ease-out rounded-md group w-28"
                                    (click)="approveDoc('approve')" [style]="{'border' : '1px solid #22c55e' }">
                                    <span
                                        class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -left-full bg-green-500 group-hover:left-0 ease">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="bg-white py-1 w-full text-center">Approve</span>
                                </div>
                                <div class="cursor-pointer relative inline-flex ml-4 items-center justify-center overflow-hidden font-medium text-red-500 transition duration-300 ease-out rounded-md group w-28"
                                    (click)="approveDoc('reject')" [style]="{'border' : '1px solid #ef4444' }">
                                    <span
                                        class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -left-full bg-red-500 group-hover:left-0 ease">
                                        <i class="pi pi-times"></i>
                                    </span>
                                    <span class="bg-white py-1 w-full text-center">Reject</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }@else if(currentApprover?.status == APPROVAL_STATUS.APPROVED){
                <div>
                    You have <span class="font-semibold text-green-500">approved</span> this document
                </div>
                }@else if(currentApprover?.status == APPROVAL_STATUS.REJECTED){
                <div>
                    You have <span class="font-semibold text-red-500">rejected</span> this document
                </div>
                }@else {
                <div>
                    Waiting for others to approve
                </div>
                }
            </div>
        </div>

        <div class="flex w-full h-full">
            <div class="editor-container w-3/4 h-full hide-comments mx-auto" #editorContainer
                [ngClass]="{'relative' : commentService.replyDialogVisible}">
                @if(commentService.isHighlight || permissions.canComment || isOwner || permissions.canEdit){
                <div #overlayMenu class="overlay-menu flex gap-4" [style.display]="showOverlay ? 'block' : 'none'"
                    [style.top.px]="overlayTop" [style.left.px]="overlayLeft" (clickOutside)="showOverlay = false">
                    <p-button (click)="showCommentBox()" styleClass="custom-menu-item">
                        <span class="m-1 material-icons-outlined">add_comment</span>
                    </p-button>
                    @if(commentService.isHighlight || isOwner || permissions.canEdit){
                    <p-button (click)="selectionSuggestionMenu.toggle($event)" styleClass="custom-menu-item">
                        <span class="m-1 material-icons-outlined">smart_toy</span>
                    </p-button>
                    <p-button (click)="applyStyle(MARKS.LINK)" styleClass="custom-menu-item">
                        <span class="m-1 material-icons-outlined">link</span>
                    </p-button>
                    }
                </div>
                @if(showComment){
                <app-add-comment #addCommentDialog class="absolute animate-fade-in-up z-1200"
                    [ngClass]="showComment ? 'block' : 'hidden'" [style.top.px]="overlayTop"
                    [style.left.px]="overlayLeft" [contractId]="contractId" (id)="handleCommentId($event)" />
                }
                }
            </div>
            <!-- Reply dialog pushed to right -->
            @if(commentService.replyDialogVisible){
            <div #replyDialog class="w-1/4 h-full relative reply-dialog">
                <app-reply class="overlay-menu animate-fade-in-up w-full" [comments]="_comments">
                </app-reply>
            </div>
            }
        </div>
    </div>

</div>
</div>


<p-overlayPanel #selectionSuggestionMenu>
    <div class="flex flex-col">
        @for(option of suggestionMenuOptions; track option.key; let last = $last){
        <div class="cursor-pointer" (click)="selectionSuggestionMenu.hide(); onSuggestionMenuOptionClick(option.key)">
            {{option.label}}
        </div>
        @if(!last){
        <hr>
        }
        }
    </div>
</p-overlayPanel>

@if(aiAssistant.open){
<p-dialog [(visible)]="aiAssistant.open" [header]="'Header'" [style]="{width: '40vw'}" [modal]="true"
    (onHide)="resetAiAssistant()" (clickOutside)="resetAiAssistant()">
    <ng-template pTemplate="header">
        <div class="flex flex-row items-center gap-1 theme-text">
            <span class="mr-2 material-icons-outlined">smart_toy</span>
            <div class="text-lg font-bold">AI Assistant</div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="flex flex-col align-items-center gap-4 mb-3">

            @if(aiAssistant.response || aiAssistant.loading){
            <div class="flex flex-row items-center gap-1">
                <input pInputText id="name" class="flex-auto rounded-md input" autocomplete="off" disabled="true"
                    [placeholder]="aiAssistant.loading ? 'AI Assistant is thinking...' : 'Ask AI Assistant to generate text'"
                    [(ngModel)]="aiAssistant.response" #name="ngModel" />
                @if(aiAssistant.response){
                <i class="pi pi-copy cursor-pointer" (click)="copyToClipboard()"></i>
                }
            </div>
            <div class="flex flex-row gap-2">
                <p-button label="Insert" severity="secondary" (onClick)="insertResponse(RESPONSE_POSITION['BESIDE'])"
                    [disabled]="aiAssistant.loading"
                    styleClass="w-100 p-1 px-3 border rounded-md text-sm assistant-option-button" />
                <p-button label="Insert Below" severity="primary" (onClick)="insertResponse(RESPONSE_POSITION['BELOW'])"
                    [disabled]="aiAssistant.loading"
                    styleClass="w-100 p-1 px-3 border rounded-md  text-sm assistant-option-button" />
                <p-button label="Try Again" severity="primary" [disabled]="aiAssistant.loading"
                    styleClass="w-100 p-1 px-3 border rounded-md text-sm assistant-option-button"
                    (onClick)="callAIAssistant()" />
            </div>
            }

            <div class="flex flex-row items-center gap-1">
                <input pInputText id="name" class="flex-auto rounded-md input" autocomplete="off"
                    [disabled]="aiAssistant.response.length >0" (keydown.enter)="callAIAssistant()"
                    placeholder="Ask AI Assistant to generate text" [(ngModel)]="aiAssistant.content" #name="ngModel" />
                <p-button label="" severity="primary" icon="pi pi-reply" class="ai-assistant-button"
                    (onClick)="callAIAssistant()"
                    [disabled]="aiAssistant.content.length<=0 || aiAssistant.response.length>0"
                    styleClass="w-100 bg-arena-orange p-1 px-3 rounded-md text-white text-sm" [outlined]="true" />
            </div>
        </div>
    </ng-template>
</p-dialog>
}

<!-- Drag handle for selected content -->
<div #dragHand draggable=" true" data-drag-handle="" class="drag-handle"
    [style.display]=" dragHand && showOverlay  ? 'block' : 'none'" [style.top.px]="dragHandTop"
    [style.left.px]="dragHandLeft">
    <div class="drag-handle-container">
        <div class="drag-handle-dots">
            <span class="drag-handle-dot"></span>
            <span class="drag-handle-dot"></span>
            <span class="drag-handle-dot"></span>
            <span class="drag-handle-dot"></span>
            <span class="drag-handle-dot"></span>
            <span class="drag-handle-dot"></span>
        </div>
    </div>
</div>