import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../../@core/services/base.service";
import { environment } from "../../../environments/environment.dev";
import { CONTRACT_CHECKLIST_STATUS } from "../../@core/models/enums";
import { IContractChecklistItem } from "../../@core/models/model";

export interface IGetContractChecklist {
    _id: string,
    name: string,
    status?: CONTRACT_CHECKLIST_STATUS
}

@Injectable({ providedIn: 'root' })
export class ContractChecklistService extends BaseService {

    constructor(protected override http: HttpClient) {
        super(http);
    }

    apiUrl = environment.apiUrl + '/contract-checklist';

    getContractChecklistMappings(contractId: string) {
        return this.get<IGetContractChecklist[]>(this.apiUrl + `/${contractId}`)
    }

    getSaveForLaterChecklistsCount(contractId: string) {
        return this.get<number>(this.apiUrl + `/save-for-later/count/${contractId}`)
    }

    saveContractChecklistMapping(data: Partial<IContractChecklistItem>) {
        return this.post(this.apiUrl, data)
    }

    deleteContractChecklistMapping(data: { contract: string, checklistItem: string }) {
        return this.delete(this.apiUrl, data)
    }

}